// Copyright 2021 @po-polochkam authors & contributors
/* eslint-disable sort-keys */

import { useCallback, useState } from 'react';
import { DB_ADD, DB_PLANOGRAMS, DB_UPDATE } from 'database/constants';
import useFirestore from 'hooks/useFirestore';
import { Planogram } from 'database/entities/planogram';
import { CollectionReference } from '@firebase/firestore-types';

export interface LinkedPlanogramDataInterface {
  addPlanogram: (planogram: Planogram) => Promise<any>,
  deletePlanogram: (planogram: Planogram) => Promise<any>,
  getAllPlanogram: (parent: string) => CollectionReference | undefined,
  updatePlanogram: (planogram: Planogram) => Promise<any>,
}

export default function useLinkedPlanogramData (): LinkedPlanogramDataInterface {
  const { firestore } = useFirestore();
  const [db] = useState<CollectionReference | undefined>(firestore?.collection(DB_PLANOGRAMS));

  const getAllPlanogram = useCallback((parent: string): CollectionReference | undefined => {
    return db?.doc(parent).collection(DB_PLANOGRAMS);
  }, [db]);

  const deletePlanogram = useCallback(async (planogram: Planogram) => {
    if (planogram.parent !== null) {
      return getAllPlanogram(planogram.parent)?.doc(planogram.id).delete();
    }
  }, [getAllPlanogram]);

  const save = useCallback(async (command: number, planogram: Planogram) => {
    if ((!planogram) || (planogram.parent === null)) {
      return;
    }

    const db = getAllPlanogram(planogram.parent);

    if (!db) {
      return;
    }

    switch (command) {
      case DB_ADD: {
        return db.doc(planogram.id).set(planogram);
      }

      case DB_UPDATE: {
        return db.doc(planogram.id).update(planogram);
      }

      default: { console.log(''); }
    }
  }, [getAllPlanogram]);

  const addPlanogram = useCallback(async (planogram: Planogram) => {
    return save(DB_ADD, planogram);
  }, [save]);

  const updatePlanogram = useCallback(async (planogram: Planogram) => {
    return save(DB_UPDATE, planogram);
  }, [save]);

  return {
    addPlanogram,
    deletePlanogram,
    getAllPlanogram,
    updatePlanogram
  };
}
