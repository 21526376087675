// Copyright 2021 @po-polochkam authors & contributors

import React from 'react';
import { Tooltip } from 'antd';
import { CopyOutlined, CopyTwoTone, DeleteOutlined, DeleteTwoTone, EditOutlined, EditTwoTone, PlusSquareOutlined, PlusSquareTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ActionProps } from 'hooks/useEdit';
import { DELETE_TWO_TONE_COLOR } from 'common/constants';

export const EditAction: React.FC<ActionProps> = (props) => {
  const { t } = useTranslation();
  const { action, disabled } = props;

  return (
    <div>
      <Tooltip title={t('edit')}>
        { (disabled) &&
          <EditOutlined />
        }
        { (!disabled) &&
          <EditTwoTone
            onClick={action}
          />
        }
      </Tooltip>
    </div>
  );
};

export const CopyAction: React.FC<ActionProps> = (props) => {
  const { t } = useTranslation();
  const { action, disabled } = props;

  return (
    <div>
      <Tooltip title={t('copy')}>
        { (disabled) &&
          <CopyOutlined />
        }
        { (!disabled) &&
          <CopyTwoTone
            onClick={action}
          />
        }
      </Tooltip>
    </div>
  );
};

export const DeleteAction: React.FC<ActionProps> = (props) => {
  const { t } = useTranslation();
  const { action, disabled } = props;

  return (
    <div>
      <Tooltip title={t('delete')}>
        { (disabled) &&
          <DeleteOutlined />
        }
        { (!disabled) &&
        <DeleteTwoTone
          onClick={action}
          twoToneColor={ DELETE_TWO_TONE_COLOR} />
        }
      </Tooltip>
    </div>
  );
};

export const AddAction: React.FC<ActionProps> = (props) => {
  const { action, disabled, title } = props;

  return (
    <div>
      <Tooltip title={title}>
        { (disabled) &&
          <PlusSquareOutlined />
        }
        { (!disabled) &&
          <PlusSquareTwoTone
            onClick={action}
          />
        }
      </Tooltip>
    </div>
  );
};
