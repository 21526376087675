// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, useCallback, useState } from 'react';
import { Space, Button, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import SkuCategoryFilter from 'components/filters/SkuCategoryFilter';
import { PlanogramFilter } from 'common/intefaces';
import PlanogramStateFilter from 'components/filters/PlanogramStateFilter';
import DateFilter from 'components/filters/DateFilter';
import CommentFilter from 'components/filters/CommentFilter';

export interface PlanogramsTitleProps {
  add: () => void;
  form: FormInstance;
  getFilter: () => PlanogramFilter;
  removeFilter: () => void;
  removeFilterSkuCategory: () => void;
  saveFilterComment: (comment: string | undefined) => void;
  saveFilterDates: (dates: [string, string]) => void;
  saveFilterSkuCategory: (skuCategoryId: string | undefined) => void;
  removeFilterState: () => void;
  saveFilterState: (state: number | undefined) => void;
}

const PlanogramsTitle: React.FC<PlanogramsTitleProps> = (props) => {
  const { t } = useTranslation();
  const { add,
    form,
    getFilter,
    removeFilter,
    removeFilterSkuCategory,
    removeFilterState,
    saveFilterComment,
    saveFilterDates,
    saveFilterSkuCategory,
    saveFilterState } = props;
  const { Title } = Typography;

  const [filter, setFilter] = useState<PlanogramFilter>(getFilter());

  const clearFilter = useCallback(() => {
    removeFilter();

    setFilter(getFilter());

    form.resetFields(['comment', 'dates', 'skuCategoryId', 'state']);
  }, [form, getFilter, removeFilter]);

  return (
    <div style={{ marginBottom: -20 }}>
      <Title level={3}>{t('planograms')}</Title>
      <Space size={'small'}
        style={{ marginBottom: 10, marginTop: 0 }}>
        <Button onClick={add}
          type='default'>
          {t('addPlanogram')}
        </Button>
        <Button onClick={clearFilter}>
          {t('clearFilters')}
        </Button>
      </Space>
      <DateFilter
        allowClear={true}
        dates={filter.dates}
        form={form}
        saveDates={saveFilterDates}/>
      <div style={{ marginTop: -10 }}>
        <SkuCategoryFilter
          allowClear={true}
          form={form}
          removeSkuCategory={removeFilterSkuCategory}
          saveSkuCategory={saveFilterSkuCategory}
          skuCategory={filter.skuCategoryId} />
      </div>
      <div style={{ marginTop: -10 }}>
        <PlanogramStateFilter
          allowClear={true}
          form={form}
          removeState={removeFilterState}
          saveState={saveFilterState}
          state={filter.state} />
      </div>
      <div style={{ marginTop: -10 }}>
        <CommentFilter
          allowClear={true}
          comment={filter.comment}
          form={form}
          saveComment={saveFilterComment} />
      </div>
    </div>
  );
};

export default memo(PlanogramsTitle);
