// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, ReactElement, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Form, Image, Input, Modal, Space, Typography } from 'antd';
import ErrorMessage from 'components/ErrorMessage';
import { LoginRequest } from 'common/intefaces';
import { CURRENT_PATH, DEFAULT_PATH, USER_ID, VERSION } from 'common/constants';
import { getLocalString, setUser } from 'common/localVars';
import useUser from 'hooks/useUser';
import useUserData from 'database/hooks/useUserData';

const LoginForm = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const [error, setError] = useState('');
  const { clear } = useUser();
  const { loginUser } = useUserData();

  const errorMessages = new Map<string, string>([
    ['401', t('The user credentials were incorrect')],
    ['404', t('The user credentials were incorrect')],
    ['429', t('Too Many Requests')]
  ]);

  const onFinish = useCallback(async (request: LoginRequest) => {
    const response = await loginUser(request);

    if (response.code !== '200') {
      setError(response.code);
    } else {
      const userId = getLocalString(USER_ID, '');

      if (!userId || userId !== response.id) {
        clear();
      }

      setUser(response);
      history.push(getLocalString(CURRENT_PATH, DEFAULT_PATH));
    }
  }, [clear, history, loginUser]);

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 16
    }
  };
  const tailLayout = {
    wrapperCol: {
      offset: 6,
      span: 16
    }
  };

  const { Title } = Typography;

  const title = (): ReactNode => {
    return (
      <Space style={{ marginTop: 2 }}>
        <Image preview={false}
          src='images/ic_launcher.png'
          style={{ marginTop: 2 }}
          width={50} />

        <Space direction='vertical'
          style={{ marginTop: 0 }}>
          <Title level={3}>{t('master')} </Title>
          <div style={{ fontSize: 13, marginTop: -22 }}>{`${t('version')} ${VERSION}`}</div>
        </Space>
      </Space>
    );
  };

  return (
    <Modal
      cancelText={t('cancel')}
      centered={true}
      closable={false}
      footer={null}
      title={title()}
      visible={true}
    >
      <Form
        {...layout}
        initialValues={{
          remember: true
        }}
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={t('login')}
          name='username'
          rules={[
            {
              message: t('Please input your username!'),
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('password')}
          name='password'
          rules={[
            {
              message: t('Please input your password!'),
              required: true
            }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Space style={{ marginLeft: 116 }}>
          <ErrorMessage code={error}
            messages={errorMessages}
          />
        </Space>
        <Form.Item {...tailLayout}
          name='submit'>
          <Button htmlType='submit'
            type='primary'>
            {t('enter')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(LoginForm);
