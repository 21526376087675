// Copyright 2021 @po-polochkam authors & contributors

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SkuType } from 'types/skuType';

export interface SkusMapInterface {
  [filter: string] : { [page: string]: Array<SkuType> };
}

export interface CountInterface {
  [key: string]: number;
}

export interface SkusInitialStateInterface {
  count: CountInterface;
  isScrolling: boolean;
  isFetching: boolean;
  lastPage: CountInterface;
  skus: SkusMapInterface;
}

const initialState: SkusInitialStateInterface = {
  count: {},
  isFetching: false,
  isScrolling: false,
  lastPage: {},
  skus: {}
};

const skusSlice = createSlice({
  initialState,
  name: 'skus',
  reducers: {
    receiveSkus (state: SkusInitialStateInterface, action: PayloadAction<{ count: number, items: Array<SkuType>, page: number, filter: string }>) {
      const { count, filter, items, page } = action.payload;

      state.count[filter] = count;

      const lastPage = state.lastPage[filter];

      if ((!lastPage) || (page > lastPage)) {
        state.lastPage[filter] = page;
      }

      if (!state.skus[filter]) {
        state.skus[filter] = {};
      }

      state.skus[filter][page] = items;
      state.isFetching = false;
    },
    reset: () => ({
      count: {},
      isFetching: false,
      isScrolling: false,
      lastPage: {},
      skus: {}
    }),
    setFetching (state, action: PayloadAction<{ fetching: boolean }>) {
      const { fetching } = action.payload;

      state.isFetching = fetching;
    },
    setScrolling (state, action: PayloadAction<{ scrolling: boolean }>) {
      const { scrolling } = action.payload;

      state.isScrolling = scrolling;
    }
  }
});

export const {
  receiveSkus,
  reset,
  setFetching,
  setScrolling
} = skusSlice.actions;

export default skusSlice.reducer;
