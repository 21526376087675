// Copyright 2021 @po-polochkam authors & contributors

import { PLANOGRAM_COMMENT_BACKGROUND_COLOR, PLANOGRAM_COMMENT_COLOR } from 'common/constants';
import { Planogram } from 'database/entities/planogram';
import React from 'react';

export interface PlanogramCommentProps {
  planogram: Planogram;
  width: number;
}

const PlanogramComment: React.FC<PlanogramCommentProps> = (props) => {
  const { planogram, width } = props;

  const fontStyle = (): any => {
    return planogram.commentBold ? 'bold' : 'normal';
  };

  return (
    <div style={{ WebkitBoxOrient: 'vertical',
      backgroundColor: PLANOGRAM_COMMENT_BACKGROUND_COLOR,
      color: PLANOGRAM_COMMENT_COLOR,
      display: '-webkit-box',
      fontSize: `${planogram.commentFontSize}px`,
      fontWeight: fontStyle(),
      height: `${planogram.commentHeight}px`,
      overflow: '`auto',
      padding: `${planogram.commentPadding}px`,
      whiteSpace: 'pre-line',
      width: width }}>

      {planogram.workComment}
    </div>
  );
};

export default React.memo(PlanogramComment);
