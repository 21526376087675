// Copyright 2021 @po-polochkam authors & contributors

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ChangeEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Layouts } from 'react-grid-layout';
import Form, { FormInstance } from 'antd/lib/form';
import { FRAME_LINE_HOR, FRAME_LINE_VERT, MAX_SKU_HEIGHT, OTHER_SKU_ID,
  PATH_PLANOGRAMS,
  PLANOGRAM_COMMENT_NONE,
  RACK_DEFAULT_HEIGHT,
  RACK_DEFAULT_NUM_OF_SHELVES,
  RACK_DEFAULT_SHELF_HEIGHT,
  RACK_DEFAULT_SHELF_THICKNESS_PX,
  RACK_DEFAULT_SHELVES_ARRAY,
  RACK_DEFAULT_WIDTH,
  RACK_MAX_HEIGHT,
  RACK_MAX_WIDTH,
  RACK_MAX_WIDTH_PX,
  RACK_MIN_SHELF_THICKNESS,
  RACK_MIN_WIDTH,
  RACK_MODE_FILL_RACK,
  RACK_SHELF_MIN_INDEX } from 'common/constants';
import { TLayout } from 'types/planogram';
import usePlanograms from './usePlanograms';
import useWindowDimensions from './useWindowDimensions';
import { Line, Planogram, Shelf, ShelfSku } from 'database/entities/planogram';
import { round, roundM } from 'common/utils';
import { DB_PLANOGRAM_STATE_IN_WORK, DB_PLANOGRAM_STATE_NEW } from 'database/constants';
import { Sku } from 'database/entities/sku';
import { ContentSku } from 'types/contentSku';
import { useTranslation } from 'react-i18next';
import usePlanogramData from 'database/hooks/usePlanogramData';
import useLinkedPlanogramData from 'database/hooks/useLinkedPlanogramData';
import { SkuExt } from 'components/PlanogramBuilder/PlanogramSkus';
import { FrameLine } from 'types/frameLine';

export interface UsePlanogramBuilderInterface {
  addLine: (line: Line) => void;
  changeOtherSku: (sku: ContentSku) => void;
  changed: boolean;
  contentSkus: ContentSku[];
  doRestorePlanogram: (currentPlanogram: Planogram) => void;
  doSave: (mode: number) => Promise<void>;
  dragStop: (layout: Layout[], oldItem: Layout, newItem: Layout) => number | null;
  dragStopDown: (layout: Layout[], oldItem: Layout, newItem: Layout) => number | null;
  dropSku: (layout: Layout[], item: Layout, sku: SkuExt | null, refresh: (p: Planogram) => void) => number | null;
  getCurrentPlanogram: () => Planogram | null;
  isRotated: (rotation: number) => boolean;
  lines: FrameLine[];
  maxShelfHeight: (layout: Layout[], index: number) => number;
  minShelfHeight: (p: Planogram, index: number) => number;
  onDeleteItem: (item: Layout) => void;
  onSetRackHeight: (e: ChangeEvent<HTMLInputElement>, minHeight: number, refresh: (p: Planogram) => void) => void;
  onSetRackWidth: (e: ChangeEvent<HTMLInputElement>, refresh: (p: Planogram) => void) => void;
  onSetShelfThickness: (e: ChangeEvent<HTMLInputElement>, maxThickness: number, refresh: (p: Planogram) => void) => void;
  placeOnShelf: (sku: Sku, rotation: number, skusInWidth: number, skusInHeight: number, shelf: number, refresh: (p: Planogram) => void) => number;
  rack: Layouts;
  rackForm: FormInstance;
  rackHeight: number;
  rackHeightPX: number;
  rackMaxWidth: number;
  rackUpdated: boolean;
  rackWidth: number;
  rackWidthPX: number;
  removeLine: (line: FrameLine) => void;
  removeSku: (sku: ContentSku) => void;
  resizeStop: (layout: Layout[], oldItem: Layout, newItem: Layout) => void;
  restorePlanogram: () => void;
  rotate: (shelfSku: ShelfSku) => ShelfSku;
  saveSkus: () => Promise<void>;
  scale: number;
  setChanged: (changed: boolean) => void;
  setPrevRack: (layout: Layout, newValue: number) => void;
  setRack: (rack: TLayout) => void;
  setRackHeight: (rackHeight: number) => void;
  setRackWidth: (rackWidth: number) => void;
  setShelves: (shelves: Array<Shelf>) => void;
  shelfCount: number;
  shelfHeight: (layout: Layout[], index: number) => number;
  shelfHeightBlur: (index: number, h: number, refresh: ((p: Planogram) => void)) => void;
  shelfSkuToContentSku: (i: number, shelfSku: ShelfSku) => ContentSku;
  shelves: Array<Shelf>;
  setShelvesValue: (p: Planogram) => void;
  updateLine: (line: FrameLine) => void;
}

interface ShelfPlace {
  i: number;
  left: number;
}

interface SkuRect {
  h: number;
  w: number;
  x: number;
  y: number;
}

type OneShelf = {
  height: number;
  places: ShelfPlace[];
}

export function usePlanogramBuilder (): UsePlanogramBuilderInterface {
  const { t } = useTranslation();
  const history = useHistory();
  const { getCurrentPlanogram, saveCurrentPlanogram } = usePlanograms();
  const [rackWidth, setRackWidth] = useState<number>(RACK_DEFAULT_WIDTH);
  const { height: winHeightPX } = useWindowDimensions();
  const [rackWidthPX, setRackWidthPX] = useState<number>(0);
  const [rackHeightPX, setRackHeightPX] = useState<number>(0);
  const [rackHeight, setRackHeight] = useState<number>(RACK_DEFAULT_HEIGHT);
  const [rackMaxWidth] = useState<number>(RACK_DEFAULT_WIDTH);
  const [rackForm] = Form.useForm();
  const [shelves, setShelves] = useState<Array<Shelf>>([]);
  const [contentSkus, setContentSkus] = useState<ContentSku[]>([]);
  const [lines, setLines] = useState<FrameLine[]>([]);
  const [changed, setChanged] = useState<boolean>(false);
  const { updatePlanogram } = usePlanogramData();
  const { updatePlanogram: updateLinkedPlanogram } = useLinkedPlanogramData();

  const initialRack: TLayout =
    { lg: new Array(RACK_DEFAULT_NUM_OF_SHELVES)
      .fill(RACK_DEFAULT_SHELVES_ARRAY)
      .map((item, index) => ({
        h: RACK_DEFAULT_SHELF_THICKNESS_PX,
        i: (RACK_SHELF_MIN_INDEX + index).toString(),
        isBounded: true,
        isDraggable: true,
        isResizable: false,
        static: index === RACK_DEFAULT_NUM_OF_SHELVES - 1,
        w: rackMaxWidth,
        x: 0,
        y: (index + 1) * RACK_DEFAULT_SHELF_HEIGHT
      })) };
  const [rack, setRack] = useState<TLayout>(initialRack);
  const [shelfCount, setShelfCount] = useState<number>(RACK_DEFAULT_NUM_OF_SHELVES);
  const [rackUpdated] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(1);

  const onDeleteItem = useCallback((item: Layout) => {
    setRack((prevLayouts: TLayout) => (
      {
        lg: prevLayouts.lg.filter((elem: Layout) => item.i !== elem.i)
      }));
    setShelfCount((prevShelfCount: number) => prevShelfCount - 1);
  }, [setRack]);

  const setPrevRack = useCallback((layout: Layout, newValue: number) => {
    setRack((prevLayouts: TLayout) => ({
      lg: prevLayouts.lg.map((item) => item.i === layout.i ? { ...layout, y: rackHeight - newValue } : item)
    }));
  }, [rackHeight]);

  const shelfHeight = useCallback((layout: Layout[], index: number): number => {
    console.log('shelfHeight', index, layout);

    if (layout[index].y) {
      let rackItemHeight = layout[index].y;

      if (layout[index - 1]) {
        rackItemHeight = rackItemHeight - layout[index - 1].y - layout[index - 1].h;
      }

      return round(rackItemHeight * scale);
    } else {
      return 0;
    }
  }, [scale]);

  const maxShelfHeight = useCallback((layout: Layout[], index: number): number => {
    const p = getCurrentPlanogram();
    const r: TLayout = rack;
    let maxHeight: number = shelfHeight(r.lg, index);

    if (p) {
      if (p.state === DB_PLANOGRAM_STATE_NEW) {
        if (index < r.lg.length - 1) {
          maxHeight += shelfHeight(r.lg, index + 1);
        } else {
          if (index > 0) {
            maxHeight += shelfHeight(r.lg, index - 1);
          }
        }

        return maxHeight;
      } else {
        let height = RACK_MAX_HEIGHT - p.height;

        if (index < p.shelves.length) {
          height += p.shelves[index].height;
        }

        return height;
      }
    } else {
      return 0;
    }
  }, [getCurrentPlanogram, rack, shelfHeight]);

  const minShelfHeight = useCallback((p: Planogram, index: number): number => {
    let minTop = p.height;
    let maxBottom = 0;

    if (p.skus) {
      for (let i = 0; i < p.skus.length; i++) {
        const shelfSku = p.skus[i];

        if (shelfSku.shelf === index) {
          const bottom = shelfSku.top + shelfSku.sku.height;

          if (shelfSku.top < minTop) {
            minTop = shelfSku.top;
          }

          if (bottom > maxBottom) {
            maxBottom = bottom;
          }
        }
      }
    }

    return maxBottom > 0 ? maxBottom - minTop + 10 : 0;
  }, []);

  const shelfSkuToContentSku = useCallback((i: number, shelfSku: ShelfSku): ContentSku => {
    const sku = shelfSku.sku;
    const isOther = sku.id === OTHER_SKU_ID;

    const contentSku = { code: (isOther ? t('otherSku') : sku.code),
      height: sku.height,
      id: sku.id,
      name: sku.name,
      orderNumber: i + 1,
      photo: sku.photo,
      place: shelfSku.place + 1,
      rotation: shelfSku.rotation,
      shelf: shelfSku.shelf + 1,
      width: sku.width };

    return contentSku;
  }, [t]);

  const shelfBottom = useCallback((p: Planogram, shelf: number): number => {
    let result = 0;

    for (let i = 0; i <= shelf; i++) {
      result += p.shelves[i].height;

      if (i < shelf) { result += p.shelves[i].thiсkness; }
    }

    return result;
  }, []);

  const shelfNumber = useCallback((p: Planogram, top: number): number | null => {
    let totalHeight = 0;

    for (let i = 0; i < p.numOfShelves; i++) {
      totalHeight += p.shelves[i].height;

      if (top < totalHeight) {
        return i;
      }

      totalHeight += p.shelves[i].thiсkness;
    }

    return null;
  }, []);

  const recalcPlaces = useCallback((p: Planogram) => {
    const shelves: OneShelf[] = [];

    let totalHeight = 0;

    for (let i = 0; i < p.shelves.length; i++) {
      totalHeight += p.shelves[i].height;
      shelves.push({ height: totalHeight, places: [] });
      totalHeight += p.shelves[i].thiсkness;
    }

    const newContentSkus: ContentSku[] = [];

    if (p.skus) {
      for (let i = 0; i < p.skus.length; i++) {
        let shelf = -1;

        for (let j = 0; j < shelves.length; j++) {
          if (p.skus[i].top <= shelves[j].height) {
            shelf = j;
            break;
          }
        }

        if (p.skus[i].shelf === -1) {
          shelf = shelves.length - 1;
        }

        const l = shelves[shelf].places.length;
        const skus: ShelfPlace[] = [];
        let needAdd = true;

        for (let k = 0; k < l; k++) {
          if ((needAdd) && (p.skus[i].left < shelves[shelf].places[k].left)) {
            skus.push({ i: i, left: p.skus[i].left });
            needAdd = false;
          }

          skus.push(shelves[shelf].places[k]);
        }

        if (needAdd) {
          skus.push({ i: i, left: p.skus[i].left });
        }

        shelves[shelf].places = skus;
      }

      let n: number;

      for (let i = 0; i < shelves.length; i++) {
        for (let j = 0; j < shelves[i].places.length; j++) {
          n = shelves[i].places[j].i;

          p.skus[n].shelf = i;
          p.skus[n].place = j;
        }
      }

      for (let i = 0; i < p.skus.length; i++) {
        newContentSkus.push(shelfSkuToContentSku(i, p.skus[i]));
      }
    }

    setContentSkus(newContentSkus);
  }, [shelfSkuToContentSku]);

  const isRotated = useCallback((rotation: number): boolean => {
    return (rotation === 90) || (rotation === 270);
  }, []);

  const doRestorePlanogram = useCallback((p: Planogram) => {
    recalcPlaces(p);

    const lines: FrameLine[] = [];

    if (p.lines) {
      const newLines: Line[] = [];

      for (let n = 0; n < p.lines.length; n++) {
        const line = p.lines[n];
        let bSkip = true;

        switch (line.orientation) {
          case FRAME_LINE_VERT: {
            bSkip = ((line.shift < 1) || (line.shift >= p.width));

            break;
          }

          case FRAME_LINE_HOR: {
            bSkip = ((line.shift < 1) || (line.shift >= p.height));

            break;
          }
        }

        if (!bSkip) {
          newLines.push(line);
          lines.push({ orderNumber: n + 1, orientation: line.orientation, shift: line.shift });
        }
      }

      p.lines = [];

      for (let n = 0; n < newLines.length; n++) {
        p.lines.push(newLines[n]);
      }
    }

    setLines(lines);
    saveCurrentPlanogram(p);

    console.log('winHeightPX', winHeightPX);

    let newHeightPX = winHeightPX - 110 - (p.commentPlace !== PLANOGRAM_COMMENT_NONE ? p.commentHeight : 0);
    let newWidthPX = roundM(newHeightPX * p.width / p.height);

    if (newWidthPX > RACK_MAX_WIDTH_PX) {
      newWidthPX = RACK_MAX_WIDTH_PX;
      newHeightPX = roundM(newWidthPX * p.height / p.width);
    }

    setRackHeightPX(newHeightPX);
    setRackWidthPX(newWidthPX);

    const scale = p.width / newWidthPX;

    setScale(scale);
    setShelfCount(p.numOfShelves);

    const lg: Layout[] = [];
    let shelfLevel = 0;

    for (let n = 0; n < p.shelves.length; n++) {
      shelfLevel += p.shelves[n].height;
      const r: SkuRect = {
        h: round(p.shelves[n].thiсkness / scale),
        w: round(p.width / scale),
        x: 0,
        y: round(shelfLevel / scale)
      };

      lg.push({
        h: r.h,
        i: (RACK_SHELF_MIN_INDEX + n).toString(),
        isBounded: true,
        isDraggable: (n < p.numOfShelves - 1) && (p.state === DB_PLANOGRAM_STATE_NEW),
        isResizable: false,
        static: (n === p.numOfShelves - 1) || (p.state !== DB_PLANOGRAM_STATE_NEW),
        w: r.w,
        x: r.x,
        y: r.y
      });
      shelfLevel += p.shelves[n].thiсkness;
    }

    if (p.skus) {
      for (let n = 0; n < p.skus.length; n++) {
        let skuWidth: number;
        let skuHeight: number;

        if (isRotated(p.skus[n].rotation)) {
          skuWidth = p.skus[n].sku.height;
          skuHeight = p.skus[n].sku.width;
        } else {
          skuWidth = p.skus[n].sku.width;
          skuHeight = p.skus[n].sku.height;
        }

        const r: SkuRect = {
          h: round(skuHeight / scale) - 1,
          w: round(skuWidth / scale) - 1,
          x: round(p.skus[n].left / scale),
          y: round(p.skus[n].top / scale)
        };

        lg.push({
          h: r.h,
          i: n.toString(),
          isBounded: true,
          isDraggable: p.state === DB_PLANOGRAM_STATE_IN_WORK,
          isResizable: (p.skus[n].sku.id === OTHER_SKU_ID) && (p.state === DB_PLANOGRAM_STATE_IN_WORK),
          static: true,
          w: r.w,
          x: r.x,
          y: r.y
        });
      }
    }

    setRack({ lg: lg });
  }, [isRotated, recalcPlaces, saveCurrentPlanogram, winHeightPX]);

  const setShelvesValue = useCallback((p: Planogram) => {
    rackForm.setFieldsValue({ height: p.height });

    for (let n = 0; n < p.shelves.length; n++) {
      rackForm.setFieldsValue({ [`shelf${n}`]: p.shelves[n].height });
    }
  }, [rackForm]);

  const refresh = useCallback((p: Planogram) => {
    doRestorePlanogram(p);
    setShelvesValue(p);
  }, [doRestorePlanogram, setShelvesValue]);

  const shelfSku = useCallback((layout: Layout[], item: Layout, sku: Sku): ShelfSku => {
    const shelfSku: ShelfSku = { left: round(item.x * scale),
      place: 0,
      rotation: 0,
      shelf: 0,
      sku: sku,
      top: round(item.y * scale) };

    return shelfSku;
  }, [scale]);

  const dragStop = useCallback((layout: Layout[], oldItem: Layout, newItem: Layout): number | null => {
    const i = Number(oldItem.i);
    const p = getCurrentPlanogram() as Planogram;
    const sku = ((p.skus) && (i >= 0) && (i < p.skus.length)) ? p.skus[i].sku : null;

    if (sku) {
      const newShelfSku: ShelfSku = shelfSku(layout, newItem, sku);
      const shelf = shelfNumber(p, newShelfSku.top);
      const newBottom = newShelfSku.top + newShelfSku.sku.height - 1;
      const shelfNew = shelfNumber(p, newBottom);

      if ((shelf === null) || (shelf !== shelfNew)) {
        newItem = oldItem;

        return null;
      }

      const dragBottom = shelfBottom(p, shelf);

      if (newBottom >= dragBottom) {
        newItem = oldItem;

        return null;
      }

      p.skus[i] = newShelfSku;
      refresh(p);
      setChanged(true);

      return i;
    } else {
      try {
        const current = layout.findIndex((item: Layout) => item.i === oldItem.i);
        const next = layout[current + 1] ? current + 1 : current;
        const prev = layout[current - 1] ? current - 1 : current;

        if (
          ((current > 0) && (layout[current].y < layout[prev].y + layout[prev].h)) ||
          (layout[current].y + layout[current].h > layout[next].y) ||
          (layout[current].y + layout[current].h > rackHeightPX)
        ) {
          newItem.y = oldItem.y;
        } else {
          const p = getCurrentPlanogram() as Planogram;
          const h = p.shelves[current].height + p.shelves[next].height;

          p.shelves[current].height = shelfHeight(layout, current);
          p.shelves[next].height = h - p.shelves[current].height;

          refresh(p);
        }
      } catch (e) {
        console.log('dragStop error:', e);
      }

      setChanged(true);

      return null;
    }
  }, [getCurrentPlanogram, rackHeightPX, refresh, shelfBottom, shelfHeight, shelfNumber, shelfSku]);

  const restorePlanogram = useCallback(() => {
    const p = getCurrentPlanogram();

    if (p !== null) {
      doRestorePlanogram(p);
    }
  }, [doRestorePlanogram, getCurrentPlanogram]);

  const shelfHeightBlur = useCallback((index: number, newHeight: number, refresh: (p: Planogram) => void) => {
    const p = getCurrentPlanogram();

    if (p) {
      if (p.state === DB_PLANOGRAM_STATE_NEW) {
        const r: TLayout = rack;
        const maxHeight = maxShelfHeight(r.lg, index);

        if (!newHeight || newHeight < 0 || newHeight > maxHeight) {
          return;
        }

        const d = p.shelves[index].height - newHeight;

        p.shelves[index].height = newHeight;

        if (index < p.shelves.length - 1) {
          p.shelves[index + 1].height += d;
        } else {
          p.shelves[index - 1].height += d;
        }
      } else {
        const oldHeight = p.shelves[index].height;
        const delta = newHeight - oldHeight;
        const newRackHeight = p.height + delta;

        if (newRackHeight > RACK_MAX_HEIGHT) {
          return;
        }

        if (minShelfHeight(p, index) > newHeight) {
          return;
        }

        p.height = newRackHeight;
        p.shelves[index].height = newHeight;

        if (p.skus) {
          for (let i = 0; i < p.skus.length; i++) {
            if (p.skus[i].shelf >= index) {
              p.skus[i].top += delta;
            }
          }
        }
      }

      refresh(p);
      setChanged(true);
    }
  }, [getCurrentPlanogram, maxShelfHeight, minShelfHeight, rack]);

  const doSave = useCallback(async (mode: number) => {
    const p = getCurrentPlanogram();

    if (p) {
      try {
        if (mode === RACK_MODE_FILL_RACK) {
          p.state = DB_PLANOGRAM_STATE_IN_WORK;
        }

        if (p.parent !== null) {
          await updateLinkedPlanogram(p);
        } else {
          await updatePlanogram(p);
        }

        if (mode === RACK_MODE_FILL_RACK) {
          saveCurrentPlanogram(p);
          window.location.reload();
        } else {
          history.push(PATH_PLANOGRAMS);
        }
      } catch (e) {
        console.log('doSave error:', e);
      }
    }
  }, [getCurrentPlanogram, history, saveCurrentPlanogram, updateLinkedPlanogram, updatePlanogram]);

  const saveSkus = useCallback(async () => {
    const p = getCurrentPlanogram();

    if (p) {
      try {
        if ((!p.parent) || (p.parent === null)) {
          await updatePlanogram(p);
        } else {
          await updateLinkedPlanogram(p);
        }
      } catch (e) {
        console.log('save error:', e);
      }
    }
  }, [getCurrentPlanogram, updateLinkedPlanogram, updatePlanogram]);

  const onSetRackWidth = useCallback((e: ChangeEvent<HTMLInputElement>, refresh: (p: Planogram) => void) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const newRackWidth = +e.target.value;

    if (!newRackWidth || newRackWidth > RACK_MAX_WIDTH || newRackWidth < RACK_MIN_WIDTH) {
      return;
    }

    setRackWidth(newRackWidth);

    const p = getCurrentPlanogram() as Planogram;

    p.width = newRackWidth;
    refresh(p);
    setChanged(true);
  }, [getCurrentPlanogram, setRackWidth]);

  const onSetShelfThickness = useCallback((e: ChangeEvent<HTMLInputElement>, maxThickness: number, refresh: (p: Planogram) => void) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const newShelfThickness = +e.target.value;

    if (!newShelfThickness || newShelfThickness > maxThickness || newShelfThickness < RACK_MIN_SHELF_THICKNESS) {
      return;
    }

    const p = getCurrentPlanogram() as Planogram;

    if (p) {
      const delta = p.shelfThickness - newShelfThickness;

      if (delta === 0) {
        return;
      }

      for (let i = 0; i < p.numOfShelves; i++) {
        p.shelves[i].height += delta;
        p.shelves[i].thiсkness = newShelfThickness;
      }
    }

    p.shelfThickness = newShelfThickness;
    refresh(p);
    setChanged(true);
  }, [getCurrentPlanogram]);

  const onSetRackHeight = useCallback((e: ChangeEvent<HTMLInputElement>, minHeight: number, refresh: (p: Planogram) => void) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const newRackHeight = +e.target.value;

    if (!newRackHeight || newRackHeight > RACK_MAX_HEIGHT || newRackHeight < minHeight) {
      return;
    }

    setRackHeight(newRackHeight);
    const p = getCurrentPlanogram();

    if (p) {
      let oldHeight = 0;
      let newHeight = newRackHeight;

      for (let i = 0; i < p.numOfShelves; i++) {
        oldHeight += p.shelves[i].height;
        newHeight -= p.shelves[i].thiсkness;
      }

      const scale = newHeight / oldHeight;

      let total = 0;

      for (let i = 1; i < p.numOfShelves; i++) {
        p.shelves[i].height = round(p.shelves[i].height * scale);
        total += p.shelves[i].height;
      }

      p.shelves[0].height = newHeight - total;
      p.height = newRackHeight;
      refresh(p);
    }
  }, [setRackHeight, getCurrentPlanogram]);

  const rotate = useCallback((shelfSku: ShelfSku): ShelfSku => {
    if (isRotated(shelfSku.rotation)) {
      const sku = { ...shelfSku.sku };

      sku.width = shelfSku.sku.height;
      sku.height = shelfSku.sku.width;

      const result = {
        left: shelfSku.left,
        place: shelfSku.place,
        rotation: shelfSku.rotation,
        shelf: shelfSku.shelf,
        sku: sku,
        top: shelfSku.top
      };

      return result;
    } else {
      return shelfSku;
    }
  }, [isRotated]);

  const doDropSku = useCallback((p: Planogram, layout: Layout[], item: Layout, sku: Sku | null, rotation: number, shiftLeft: boolean, iSku: number | null): ShelfSku | null => {
    if (!sku) {
      return null;
    }

    const newShelfSku: ShelfSku = shelfSku(layout, item, sku);

    newShelfSku.rotation = rotation;

    let skuWidth: number;
    let skuHeight: number;

    if (isRotated(rotation)) {
      skuWidth = sku.height;
      skuHeight = sku.width;
    } else {
      skuWidth = sku.width;
      skuHeight = sku.height;
    }

    if ((newShelfSku.top + skuHeight > p.height - p.shelves[p.shelves.length - 1].thiсkness + 1) || (newShelfSku.left + skuWidth > p.width - 1)) {
      return null;
    }

    const shelf: number | null = shelfNumber(p, newShelfSku.top);

    if (shelf === null) {
      return null;
    }

    const shelfHeight = p.shelves[shelf].height;
    const sourceDropBottom = shelfBottom(p, shelf);
    let dropBottom = sourceDropBottom;

    if (skuHeight > shelfHeight) {
      if (sku.id !== OTHER_SKU_ID) {
        return null;
      }

      newShelfSku.top = dropBottom - shelfHeight;
      sku.height = shelfHeight;
      skuHeight = shelfHeight;
      newShelfSku.sku = sku;
    }

    if (p.skus) {
      let newRight = newShelfSku.left + skuWidth - 1;

      for (let i = 0; i < p.skus.length; i++) {
        if (iSku !== i) {
          const shelfSku = rotate(p.skus[i]);
          const bottom = shelfSku.top + shelfSku.sku.height - 1;

          if ((shelfSku.shelf === shelf) && (bottom > newShelfSku.top)) {
            const right = shelfSku.left + shelfSku.sku.width - 1;

            if ((shelfSku.left <= newRight) && (right >= newShelfSku.left) && (shelfSku.top < dropBottom)) {
              dropBottom = shelfSku.top;
            }
          }
        }
      }

      newShelfSku.top = dropBottom - skuHeight;

      if (sourceDropBottom - newShelfSku.top > shelfHeight) {
        return null;
      }

      const newBottom = newShelfSku.top + skuHeight - 1;

      if ((shiftLeft) && (dropBottom === sourceDropBottom)) {
        let dropLeft = 0;

        for (let i = 0; i < p.skus.length; i++) {
          const shelfSku = rotate(p.skus[i]);

          if (shelfSku.shelf === shelf) {
            const right = shelfSku.left + shelfSku.sku.width - 1;

            if (right < newShelfSku.left) {
              const bottom = shelfSku.top + shelfSku.sku.height - 1;

              if ((shelfSku.top <= newBottom) && (bottom >= newShelfSku.top) && (right > dropLeft)) {
                dropLeft = right;
              }
            }
          }
        }

        newShelfSku.left = dropLeft + 1;
        newRight = newShelfSku.left + skuWidth - 1;
      }

      for (let i = 0; i < p.skus.length; i++) {
        if (iSku !== i) {
          const shelfSku = rotate(p.skus[i]);
          const bottom = shelfSku.top + shelfSku.sku.height - 1;

          if ((shelfSku.shelf === shelf) && (bottom > newShelfSku.top)) {
            const right = shelfSku.left + shelfSku.sku.width - 1;
            const bottom = shelfSku.top + shelfSku.sku.height - 1;

            if ((newShelfSku.top >= shelfSku.top) && (newShelfSku.top <= bottom) &&
            (newShelfSku.left >= shelfSku.left) && (newShelfSku.left <= right)) {
              return null;
            }

            if ((newBottom >= shelfSku.top) && (newBottom <= bottom) &&
            (newRight >= shelfSku.left) && (newRight <= right)) {
              return null;
            }

            if ((newShelfSku.top <= bottom) && (newBottom >= shelfSku.top) &&
            (newShelfSku.left <= right) && (newRight >= shelfSku.left)) {
              return null;
            }
          }
        }
      }
    } else {
      newShelfSku.top = dropBottom - skuHeight;
      newShelfSku.left = 0;
    }

    return newShelfSku;
  }, [isRotated, rotate, shelfBottom, shelfNumber, shelfSku]);

  const dropSku = useCallback((layout: Layout[], item: Layout, sku: SkuExt | null, refresh: (p: Planogram) => void): number | null => {
    const p = getCurrentPlanogram() as Planogram;

    if ((!p) || (!sku)) {
      return null;
    }

    const newShelfSku = doDropSku(p, layout, item, sku, sku.rotation, true, null);

    if (!newShelfSku) {
      return null;
    }

    if (!p.skus) {
      p.skus = [];
    }

    p.skus.push(newShelfSku);
    refresh(p);
    setChanged(true);

    return p.skus.length - 1;
  }, [doDropSku, getCurrentPlanogram]);

  const dragStopDown = useCallback((layout: Layout[], oldItem: Layout, newItem: Layout): number | null => {
    const p = getCurrentPlanogram() as Planogram;

    if (!p) {
      return null;
    }

    const i = Number(oldItem.i);
    const sku = ((p.skus) && (i >= 0) && (i < p.skus.length)) ? p.skus[i].sku : null;

    if (sku === null) {
      try {
        const current = layout.findIndex((item: Layout) => item.i === oldItem.i);
        const next = layout[current + 1] ? current + 1 : current;
        const prev = layout[current - 1] ? current - 1 : current;

        if (
          ((current > 0) && (layout[current].y < layout[prev].y + layout[prev].h)) ||
          (layout[current].y + layout[current].h > layout[next].y) ||
          (layout[current].y + layout[current].h > rackHeightPX)
        ) {
          newItem.y = oldItem.y;
        } else {
          const p = getCurrentPlanogram() as Planogram;
          const h = p.shelves[current].height + p.shelves[next].height;

          p.shelves[current].height = shelfHeight(layout, current);
          p.shelves[next].height = h - p.shelves[current].height;

          refresh(p);
          setChanged(true);
        }
      } catch (e) {
        console.log('dragStop error:', e);
      }

      return null;
    } else {
      const newShelfSku = doDropSku(p, layout, newItem, sku, p.skus[i].rotation, false, i);

      if (!newShelfSku) {
        return null;
      }

      p.skus[i] = newShelfSku;
      refresh(p);
      setChanged(true);

      return i;
    }
  }, [doDropSku, getCurrentPlanogram, rackHeightPX, refresh, shelfHeight]);

  // Размещаем блок (skusInWidth x skusInHeight) товара sku на полке shelf
  const placeOnShelf = useCallback((sku: Sku, rotation: number, skusInWidth: number, skusInHeight: number, shelf: number, refresh: (p: Planogram) => void) => {
    const result = 0;

    // Проверяем наличие текущей планограммы
    const p = getCurrentPlanogram() as Planogram;

    if (!p) {
      return result;
    }

    let skuWidth: number;
    let skuHeight: number;

    if (isRotated(rotation)) {
      skuWidth = sku.height;
      skuHeight = sku.width;
    } else {
      skuWidth = sku.width;
      skuHeight = sku.height;
    }

    // Проверяем влезает ли блок по высоте
    if (skuHeight * skusInHeight > p.shelves[shelf].height) {
      return 1;
    }

    // Вычисляем левый край возможного размещения блока на полке
    let newLeft = 0;

    if (p.skus) {
      for (let i = 0; i < p.skus.length; i++) {
        const shelfSku = p.skus[i];

        let skuWidth: number;

        if (isRotated(shelfSku.rotation)) {
          skuWidth = shelfSku.sku.height;
        } else {
          skuWidth = shelfSku.sku.width;
        }

        if (shelfSku.shelf === shelf) {
          const right = shelfSku.left + skuWidth;

          if (right > newLeft) {
            newLeft = right;
          }
        }
      }

      newLeft += 1;
    }

    // Проверяем влезает ли блок по ширине
    if (skuWidth * skusInWidth > p.width - newLeft + 1) {
      return 2;
    }

    // Заполнеям полку блоком товара sku
    if (!p.skus) {
      p.skus = [];
    }

    const bottom = shelfBottom(p, shelf) - 1;

    // Полка заполняется столбцами товара (каждый столбец снизу вверх, столбцы слева направо)
    for (let i = 0; i < skusInWidth; i++) {
      let newTop = bottom - skuHeight - 1;

      for (let j = 0; j < skusInHeight; j++) {
        p.skus.push({ left: newLeft,
          place: 0,
          rotation: rotation,
          shelf: shelf,
          sku: sku,
          top: newTop });
        newTop -= skuHeight;
      }

      newLeft += skuWidth + 1;
    }

    // Обновляем планограмму
    refresh(p);
    // Отмечаем, что планограмма была изменена
    setChanged(true);

    return result;
  }, [getCurrentPlanogram, isRotated, shelfBottom]);

  const changeOtherSku = useCallback((sku: ContentSku) => {
    let newRight = 0;
    let newBottom = 0;

    const p = getCurrentPlanogram() as Planogram;
    const newShelfSku = p.skus[sku.orderNumber - 1];

    newBottom = newShelfSku.top + newShelfSku.sku.height - 1;

    if (newShelfSku.sku.width < sku.width) {
      newShelfSku.sku.width = Math.min(sku.width, p.width - newShelfSku.left + 1);
      newRight = newShelfSku.left + newShelfSku.sku.width - 1;

      for (let i = 0; i < p.skus.length; i++) {
        if (i !== sku.orderNumber - 1) {
          const shelfSku = rotate(p.skus[i]);

          if (newShelfSku.shelf === shelfSku.shelf) {
            const right = shelfSku.left + shelfSku.sku.width - 1;
            const bottom = shelfSku.top + shelfSku.sku.height - 1;

            if ((newShelfSku.top < bottom) && (newBottom > shelfSku.top) &&
              (newShelfSku.left < right) && (newRight > shelfSku.left)) {
              newShelfSku.sku.width = newRight - shelfSku.left;
              newRight = newShelfSku.left + newShelfSku.sku.width - 1;
            }
          }
        }
      }
    } else {
      newShelfSku.sku.width = sku.width;
    }

    newBottom = newShelfSku.top + newShelfSku.sku.height - 1;

    if (newShelfSku.sku.height < sku.height) {
      newShelfSku.sku.height = Math.min(sku.height, p.shelves[newShelfSku.shelf].height - (shelfBottom(p, newShelfSku.shelf) - newShelfSku.top - newShelfSku.sku.height));
      newShelfSku.top = newBottom - newShelfSku.sku.height + 1;

      for (let i = 0; i < p.skus.length; i++) {
        if (i !== sku.orderNumber - 1) {
          const shelfSku = rotate(p.skus[i]);

          if (newShelfSku.shelf === shelfSku.shelf) {
            const right = shelfSku.left + shelfSku.sku.width - 1;
            const bottom = shelfSku.top + shelfSku.sku.height - 1;

            if ((newShelfSku.top < bottom) && (newBottom > shelfSku.top) &&
              (newShelfSku.left < right) && (newRight > shelfSku.left)) {
              newShelfSku.sku.height = newBottom - bottom;
              newShelfSku.top = newBottom - newShelfSku.sku.height + 1;
            }
          }
        }
      }
    } else {
      newShelfSku.sku.height = sku.height;
      newShelfSku.top = newBottom - newShelfSku.sku.height + 1;
    }

    newShelfSku.sku.name = sku.name;
    p.skus[sku.orderNumber - 1] = newShelfSku;
    refresh(p);
    setChanged(true);
  }, [getCurrentPlanogram, refresh, rotate, shelfBottom]);

  const resizeStop = useCallback((layout: Layout[], oldItem: Layout, newItem: Layout) => {
    const i = Number(oldItem.i);
    const p = getCurrentPlanogram() as Planogram;
    const sku = ((p.skus) && (i >= 0) && (i < p.skus.length)) ? p.skus[i].sku : null;

    if ((sku) && (sku.id === OTHER_SKU_ID)) {
      const contentSku = contentSkus[i];

      contentSku.width = round(Math.min(newItem.w * scale, p.width));
      contentSku.height = round(Math.min(newItem.h * scale, MAX_SKU_HEIGHT));
      contentSku.orderNumber = i + 1;

      changeOtherSku(contentSku);
    }
  }, [changeOtherSku, contentSkus, getCurrentPlanogram, scale]);

  const removeSku = useCallback((sku: ContentSku) => {
    const p = getCurrentPlanogram() as Planogram;

    if ((sku.orderNumber < 1) || (sku.orderNumber > p.skus.length)) {
      return;
    }

    const skus: ShelfSku[] = [];

    for (let i = 0; i < p.skus.length; i++) {
      if (i !== sku.orderNumber - 1) {
        skus.push(p.skus[i]);
      }
    }

    p.skus = skus;
    refresh(p);
    setChanged(true);
  }, [getCurrentPlanogram, refresh]);

  const removeLine = useCallback((line: FrameLine) => {
    const p = getCurrentPlanogram() as Planogram;

    if ((line.orderNumber < 1) || (line.orderNumber > p.lines.length)) {
      return;
    }

    const lines: Line[] = [];

    for (let i = 0; i < p.lines.length; i++) {
      if (i !== line.orderNumber - 1) {
        lines.push(p.lines[i]);
      }
    }

    p.lines = lines;
    refresh(p);
    setChanged(true);
  }, [getCurrentPlanogram, refresh]);

  const updateLine = useCallback((line: FrameLine) => {
    const p = getCurrentPlanogram() as Planogram;

    if ((line.orderNumber < 1) || (line.orderNumber > p.lines.length)) {
      return;
    }

    const lines: Line[] = [];

    for (let i = 0; i < p.lines.length; i++) {
      lines.push(p.lines[i]);
    }

    lines[line.orderNumber - 1] = line;
    p.lines = lines;
    refresh(p);
    setChanged(true);
  }, [getCurrentPlanogram, refresh]);

  const addLine = useCallback((line: Line) => {
    const p = getCurrentPlanogram() as Planogram;

    switch (line.orientation) {
      case FRAME_LINE_VERT: {
        if ((line.shift < 1) || (line.shift >= p.width)) {
          return;
        }

        break;
      }

      case FRAME_LINE_HOR: {
        if ((line.shift < 1) || (line.shift >= p.height)) {
          return;
        }

        break;
      }

      default: {
        return;
      }
    }

    if (!p.lines) {
      p.lines = [];
    }

    p.lines.push(line);
    refresh(p);
    setChanged(true);
  }, [getCurrentPlanogram, refresh]);

  return {
    addLine,
    changeOtherSku,
    changed,
    contentSkus,
    doRestorePlanogram,
    doSave,
    dragStop,
    dragStopDown,
    dropSku,
    getCurrentPlanogram,
    isRotated,
    lines,
    maxShelfHeight,
    minShelfHeight,
    onDeleteItem,
    onSetRackHeight,
    onSetRackWidth,
    onSetShelfThickness,
    placeOnShelf,
    rack,
    rackForm,
    rackHeight,
    rackHeightPX,
    rackMaxWidth,
    rackUpdated,
    rackWidth,
    rackWidthPX,
    removeLine,
    removeSku,
    resizeStop,
    restorePlanogram,
    rotate,
    saveSkus,
    scale,
    setChanged,
    setPrevRack,
    setRack,
    setRackHeight,
    setRackWidth,
    setShelves,
    setShelvesValue,
    shelfCount,
    shelfHeight,
    shelfHeightBlur,
    shelfSkuToContentSku,
    shelves,
    updateLine
  };
}

export default usePlanogramBuilder;
