// Copyright 2021 @po-polochkam authors & contributors

import { useCallback, useState } from 'react';
import { Planogram } from 'database/entities/planogram';
import useList from './useList';
import useLinkedPlanogramData from 'database/hooks/useLinkedPlanogramData';
import { QueryDocumentSnapshot } from '@firebase/firestore-types';
import { useEditPlanogram } from './useEditPlanogram';
import { FormInstance } from 'antd/lib/form';

export interface UseLinkedPlanogramsProps {
  parent: string;
}

interface UseLinkedPlanogramsInterface {
  add: () => void;
  copy: (planogram: Planogram) => void;
  copyForm: FormInstance;
  doCopy: () => Promise<string>;
  doSave: () => Promise<string>;
  editForm: FormInstance;
  listMode: number
  planograms: Planogram[];
  refreshList: () => void;
  removePlanogram: (planogram: Planogram) => void;
  saveCurrentPlanogram: (planogram: Planogram) => void;
  setListMode: (listMode: number) => void;
  update: (listMode: number, record: Planogram) => void;
}

export function useLinkedPlanograms (props: UseLinkedPlanogramsProps): UseLinkedPlanogramsInterface {
  const { parent } = props;
  const { editForm, listMode, refreshData, setListMode, setRefreshData, setSaving } = useList();
  const [planograms, setPlanograms] = useState<Planogram[]>([]);
  const { addPlanogram, deletePlanogram, getAllPlanogram, updatePlanogram } = useLinkedPlanogramData();

  const { add, copy, copyForm, doCopy, doSave, removePlanogram, saveCurrentPlanogram, update } = useEditPlanogram(
    { addPlanogram,
      deletePlanogram,
      editForm,
      listMode,
      parent,
      setListMode,
      setRefreshData,
      setSaving,
      updatePlanogram });

  const refresh = useCallback(async () => {
    const converter = {
      fromFirestore: (snap: QueryDocumentSnapshot) => {
        const p = snap.data() as Planogram;

        return p;
      },

      toFirestore: (data: Planogram) => data
    };

    const p = getAllPlanogram(parent)?.withConverter(converter)
      .orderBy('date', 'desc');

    const planograms: Planogram[] = [];
    const snapshot = await p?.get();

    if (snapshot) {
      snapshot.forEach((doc) => {
        const planogram = doc.data();

        planograms.push(planogram);
      });
    }

    setPlanograms(planograms);
  }, [getAllPlanogram, parent]);

  const refreshList = useCallback(() => {
    if (refreshData) {
      setRefreshData(false);
      void refresh();
    }
  }, [refresh, refreshData, setRefreshData]);

  return {
    add,
    copy,
    copyForm,
    doCopy,
    doSave,
    editForm,
    listMode,
    planograms,
    refreshList,
    removePlanogram,
    saveCurrentPlanogram,
    setListMode,
    update
  };
}
