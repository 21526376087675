// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import SkuCategoriesTitle from './SkuCategoriesTitle';
import { SkuCategory } from 'database/entities/sku-category';
import { MAX_PAGE_SIZE } from 'common/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import useSkuCategories from 'hooks/useSkuCategories';
import './styles.scss';

function SkuCategories (): ReactElement {
  const { t } = useTranslation();
  const { skuCategories } = useSkuCategories();
  const { height } = useWindowDimensions();

  const columns: ColumnsType<SkuCategory> = [
    {
      dataIndex: 'id',
      key: 'id',
      sorter: (a: { id: string; }, b: { id: string; }) => { return a.id.localeCompare(b.id); },
      title: t('id'),
      width: '200px'
    },
    {
      dataIndex: 'name',
      key: 'name',
      sorter: (a: { name: string; }, b: { name: string; }) => { return a.name.localeCompare(b.name); },
      title: t('name'),
      width: '300px'
    }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={skuCategories}
        pagination={{ hideOnSinglePage: true, pageSize: MAX_PAGE_SIZE }}
        scroll={{ y: height - 250 }}
        size='small'
        style={{ maxWidth: '800px' }}
        tableLayout='fixed'
        title={() => <SkuCategoriesTitle /> }
      />
    </div>
  );
}

export default memo(SkuCategories);
