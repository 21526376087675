// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import './styles.scss';

interface ErrorMessageProps {
  code: string,
  messages: Map<string, string>
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ code, messages }) => {
  const { t } = useTranslation();

  function getErrorMessage () {
    if (messages.has(code)) {
      return messages.get(code);
    } else {
      return t('unknownError') + code + '.';
    }
  }

  return code ? <p className='error-message'>{getErrorMessage()}</p> : null;
};

export function showError (props: ErrorMessageProps): boolean {
  const { error } = Modal;

  error({
    content: <ErrorMessage code={props.code}
      messages={props.messages} />,
    okText: 'Закрыть',
    onOk () {
      console.log('OK');
    },
    title: 'Ошибка'
  });

  return true;
}

export default memo(ErrorMessage);
