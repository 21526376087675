// Copyright 2021 @po-polochkam authors & contributors
/* eslint-disable camelcase */
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

declare global {
  interface Window {
    processEnv: {
      REACT_APP_apiKey: string;
      REACT_APP_appId: string;
      REACT_APP_authDomain: string;
      REACT_APP_databaseURL: string;
      REACT_APP_messagingSenderId: string;
      REACT_APP_projectId: string;
      REACT_APP_storageBucket: string;
    }
  }
}

/* eslint-disable sort-keys */
export const firebaseConfig = (process.env.NODE_ENV !== 'pre') ? {
  apiKey: window.processEnv.REACT_APP_apiKey,
  authDomain: window.processEnv.REACT_APP_authDomain,
  databaseURL: window.processEnv.REACT_APP_databaseURL,
  projectId: window.processEnv.REACT_APP_projectId,
  storageBucket: window.processEnv.REACT_APP_storageBucket,
  messagingSenderId: window.processEnv.REACT_APP_messagingSenderId,
  appId: window.processEnv.REACT_APP_appId
} : {
  backend_url: process.env.REACT_APP_backend_url,
  client_secret: process.env.REACT_APP_client_secret,
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_messagingSenderId
};

/*
export const firebaseConfig = {
  backend_url: process.env.REACT_APP_backend_url,
  client_secret: process.env.REACT_APP_client_secret,
  apiKey: 'AIzaSyDag15VGI6WiBqCRyz8EgeFEqe9UepFLRs',
  authDomain: 'popolochkam-c2179.firebaseapp.com',
  databaseURL: 'https://popolochkam-c2179.firebaseio.com',
  projectId: 'popolochkam-c2179',
  storageBucket: 'popolochkam-c2179.appspot.com',
  messagingSenderId: '442089766101',
  appId: '1:442089766101:web:e5d77261adad7a5869765c'
};
*/

console.log('NODE_ENV', process.env.NODE_ENV);
console.log('firebaseConfig', firebaseConfig);

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebase.firestore();
