// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, useEffect } from 'react';
import { usePlanogramList } from 'hooks/usePlanogramList';
import { Button, Table } from 'antd';
import { FIRST_PLANOGRAM_ENTER, LIST_MODE_ADD, LIST_MODE_COPY, LIST_MODE_UPDATE, LIST_MODE_VIEW, MAX_PAGE_SIZE, PATH_PLANOGRAM_VIEW } from 'common/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { useLinkedPlanograms } from 'hooks/useLinkedPlanograms';
import EditPlanogramForm from 'components/EditPlanogram';
import { useHistory } from 'react-router-dom';
import { setLocalNumber } from 'common/localVars';
import CopyPlanogramForm from 'components/CopyPlanogram';

export interface LinkedPlanogramsProps {
  enabled: boolean;
  parent: string;
}

const LinkedPlanograms: React.FC<LinkedPlanogramsProps> = (props) => {
  const { t } = useTranslation();
  const { enabled, parent } = props;
  const { height } = useWindowDimensions();
  const history = useHistory();
  const { add,
    copy,
    copyForm,
    doCopy,
    doSave,
    editForm,
    listMode,
    planograms,
    refreshList,
    removePlanogram,
    saveCurrentPlanogram,
    setListMode,
    update } = useLinkedPlanograms({ parent });

  const { columns } = usePlanogramList({ copy, enabled, parent, removePlanogram, update });

  useEffect(() => {
    refreshList();
  });

  return (
    <div>
      <Table
        columns={columns()}
        dataSource={planograms}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              saveCurrentPlanogram(record);
              setLocalNumber(FIRST_PLANOGRAM_ENTER, 1);
              history.push(PATH_PLANOGRAM_VIEW);
              window.location.reload();
            }
          };
        }}
        pagination={{ hideOnSinglePage: true, pageSize: MAX_PAGE_SIZE }}
        scroll={{ y: height - 410 }}
        size='small'
        style={{ marginTop: -10, maxWidth: '800px' }}
        tableLayout='auto'
        title={() =>
          <Button disabled={!enabled}
            onClick={add}
            style={{ marginBottom: 0, marginTop: -10 }}
            type='default'>
            {t('addPlanogram')}
          </Button>
        }
      />
      { ((listMode === LIST_MODE_ADD) || (listMode === LIST_MODE_UPDATE)) &&
        <EditPlanogramForm
          doSave={doSave}
          editMode={listMode}
          editTitle={t('editPlangram')}
          form={editForm}
          newTitle={t('newPlangram')}
          onCancel={() => { setListMode(LIST_MODE_VIEW); }}
          visible={true}
        />
      }
      { (listMode === LIST_MODE_COPY) &&
        <CopyPlanogramForm
          doSave={doCopy}
          editMode={LIST_MODE_UPDATE}
          editTitle={t('copyPlanogram')}
          form={copyForm}
          newTitle={t('copyPlanogram')}
          onCancel={() => { setListMode(LIST_MODE_VIEW); }}
          visible={true}
        />
      }
    </div>
  );
};

export default memo(LinkedPlanograms);
