// Copyright 2021 @po-polochkam authors & contributors

export const DB_ADD = 0;
export const DB_UPDATE = 1;
export const DB_DELETE = 2;
export const DB_EXISTS = 3;

export const DB_ERROR_DUPLICATE = 'DB_ERROR_DUPLICATE';

export const DB_SKU_CATEGORIES = '/sku-categories';
export const DB_SKUS = '/skus';
export const DB_PLANOGRAMS = '/planograms';
export const DB_USERS = '/users';
export const DB_SKU_PLANOGRAMS = '/sku-planograms';

export const DB_PLANOGRAM_STATE_NEW = 0;
export const DB_PLANOGRAM_STATE_READY = 1;
export const DB_PLANOGRAM_STATE_IN_WORK = 2;
