// Copyright 2021 @po-polochkam authors & contributors

import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export interface TextFiltersProps {
  allowClear: boolean;
  defaultValue: string | undefined;
  onSearch: (value: string) => void;
  placeholder: string;
}

const TextFilter: React.FC<TextFiltersProps> = (props) => {
  const { allowClear, defaultValue, onSearch, placeholder } = props;
  const { Search } = Input;
  const [value, setValue] = useState<string | undefined>(defaultValue);

  const visibility = useCallback(() => {
    return (allowClear && (value) && (value > '')) ? 'visible' : 'hidden';
  }, [allowClear, value]);

  return (
    <div>
      <Search allowClear={false}
        defaultValue={defaultValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
        }}
        onSearch={ (value: string) => {
          onSearch(value);
        }}
        placeholder={placeholder}
        style={{ width: 200 }}
        suffix={<CloseOutlined onClick={() => {
          setValue(undefined);
          onSearch('');
        }}
        style={{ visibility: visibility() }}/>}
        value={value} />
    </div>
  );
};

export default memo(TextFilter);
