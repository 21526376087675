// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Sku } from 'database/entities/sku';
import { MAX_PAGE_SIZE } from 'common/constants';
import { useSkus } from 'hooks/useSkus';
import SkusTitle from './SkusTitle';
import useWindowDimensions from 'hooks/useWindowDimensions';

function Skus (): ReactElement {
  const { t } = useTranslation();
  const { filterForm, imageDimensions, refreshCategorySkus, saveCurrentSkuCategoryId, skus } = useSkus();
  const { height } = useWindowDimensions();

  const columns: ColumnsType<Sku> = [
    {
      align: 'left',
      dataIndex: 'photo',
      fixed: true,
      // eslint-disable-next-line react/display-name
      render: (photo: string | undefined, sku) => {
        const d = imageDimensions(sku.width, sku.height);

        return <Image
          height={d.height}
          preview={{ mask: () => { console.log(''); } }}
          src={photo}
          width={d.width} />;
      },
      title: t('photo'),
      width: '60px'
    },
    {
      align: 'left',
      dataIndex: 'code',
      fixed: true,
      sorter: (a: { code: string; }, b: { code: string; }) => a.code.localeCompare(b.code),
      title: t('code'),
      width: '100px'
    },
    {
      align: 'left',
      dataIndex: 'name',
      fixed: true,
      sorter: (a: { name: string; }, b: { name: string; }) => { return a.name.localeCompare(b.name); },
      title: t('name'),
      width: '250px'
    },
    {
      align: 'left',
      dataIndex: 'brand',
      fixed: true,
      sorter: (a: { brand: string; }, b: { brand: string; }) => { return a.brand.localeCompare(b.brand); },
      title: t('brand'),
      width: '150px'
    },
    {
      align: 'right',
      dataIndex: 'width',
      fixed: true,
      sorter: (a: { width: number; }, b: { width: number; }) => a.width - b.width,
      title: t('wMM'),
      width: '50px'
    },
    {
      align: 'right',
      dataIndex: 'height',
      sorter: (a: { height: number; }, b: { height: number; }) => a.height - b.height,
      title: t('hMM'),
      width: '50px'
    }
  ];

  useEffect(() => {
    refreshCategorySkus();
  });

  return (
    <div>
      <Table
        columns={columns}
        dataSource={skus}
        pagination={{ hideOnSinglePage: true, pageSize: MAX_PAGE_SIZE }}
        scroll={{ y: height - 250 }}
        size='small'
        style={{ maxWidth: '1200px' }}
        tableLayout='fixed'
        title={() => <SkusTitle
          form={filterForm}
          saveCurrentSkuCategoryKey={saveCurrentSkuCategoryId}/> }
      />
    </div>
  );
}

export default memo(Skus);
