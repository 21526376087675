// Copyright 2021 @po-polochkam authors & contributors

import React, { ChangeEvent, memo, useState } from 'react';
import { Form, FormInstance, InputNumber, Modal, Space, Table } from 'antd';
import Text from 'antd/lib/typography/Title';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Line, Planogram } from 'database/entities/planogram';
import { FrameLine } from 'types/frameLine';
import { AddAction, DeleteAction } from 'components/actions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FRAME_LINE_HOR, FRAME_LINE_VERT, MAX_PAGE_SIZE } from 'common/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';

export interface PlanogramFramesProps {
  addLine: (line: Line) => void;
  enabled: boolean;
  form: FormInstance;
  lines: FrameLine[];
  planogram: Planogram;
  removeLine: (line: FrameLine) => void;
  selectedLine: number | null;
  setSelectedLine: (id: number | null) => void;
}

const PlanogramFrames: React.FC<PlanogramFramesProps> = (props) => {
  const { addLine, enabled, form, lines, planogram, removeLine, selectedLine, setSelectedLine } = props;
  const { t } = useTranslation();
  const { height } = useWindowDimensions();

  const deleteLine = (line: FrameLine) => {
    const { confirm } = Modal;

    setSelectedLine(line.orderNumber - 1);
    confirm({
      cancelText: t('no'),
      icon: <ExclamationCircleOutlined />,
      okText: t('yes'),
      okType: 'danger',
      onOk () {
        void removeLine(line);
      },
      title: t('deleteSelectedLine')
    });
  };

  const lineOrientation = (orientation: number): string => {
    if (orientation === FRAME_LINE_VERT) {
      return t('lineVert');
    } else {
      return t('lineHor');
    }
  };

  const columns: ColumnsType<FrameLine> = [
    {
      align: 'right',
      dataIndex: 'orderNumber',
      sorter: { compare: (a: { orderNumber: number; }, b: { orderNumber: number; }) => a.orderNumber - b.orderNumber },
      title: t('orderNumber'),
      width: 40
    },
    {
      align: 'left',
      dataIndex: 'orientation',
      render: (_text, line) => (
        <Space style={{ marginTop: 6 }}>
          <Text style={{ fontSize: '14px' }}>{lineOrientation(line.orientation)} </Text>
        </Space>
      ),
      title: t('name'),
      width: 160
    },
    {
      align: 'right',
      dataIndex: 'shift',
      sorter: { compare: (a: { shift: number; }, b: { shift: number; }) => a.shift - b.shift },
      title: t('shift'),
      width: 40
    },
    {
      dataIndex: 'action',
      // eslint-disable-next-line react/display-name
      render: (_text, line) => (
        <Space
          size='large'
          style={{ marginLeft: 10 }}>
          <DeleteAction action={deleteLine.bind(null, line)}
            disabled={!enabled}
          />
        </Space>
      ),
      title: t('actions'),
      width: '60px'
    }
  ];

  const shiftLeftHelp = enabled ? `${t('shiftLeftMustBe')} 1 ${t('to')} ${planogram.width - 1}` : '';
  const shiftTopHelp = enabled ? `${t('shiftTopMustBe')} 1 ${t('to')} ${planogram.height - 1}` : '';
  const [vertShift, setVertShift] = useState(planogram.width / 2);
  const [horShift, setHorShift] = useState(planogram.height / 2);

  return (
    <div>
      <Form
        form={form}
        // labelCol={{ offset: 0, span: 9 }}
        layout='horizontal'
        name='planogram_sku_form'
      >
        <Space direction={'vertical'}>
          <Space direction={'horizontal'}
            style={{ height: 38, marginLeft: 20 }}
          >
            <Form.Item
              key='lineVert'
              label={t('addLineVert')}
              name='lineVert'
              rules={[
                {
                  max: planogram.width - 1,
                  message: shiftLeftHelp,
                  min: 1,
                  required: enabled,
                  type: 'integer'
                }
              ]}
              style={{ marginTop: 0 }}
              tooltip={shiftLeftHelp}
            >
              <InputNumber defaultValue={Math.round(planogram.width / 2)}
                disabled={!enabled}
                onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                  const shift = +e.target.value;

                  if (shift) {
                    setVertShift(shift);
                  }
                }}
                size={'small'}
                value={planogram.width / 2}
              />
            </Form.Item>
            <Space
              size='large'
              style={{ position: 'relative', top: -10 }}
            >
              <AddAction action={() => {
                addLine({ orientation: FRAME_LINE_VERT, shift: vertShift });
              }}
              disabled={!enabled}
              title={t('addLineVertHelp')}
              />
            </Space>
          </Space>
          <Space direction={'horizontal'}>
            <Form.Item
              key='lineHor'
              label={t('addLineHor')}
              name='lineHor'
              rules={[
                {
                  max: planogram.height - 1,
                  message: shiftTopHelp,
                  min: 1,
                  required: enabled,
                  type: 'integer'
                }
              ]}
              style={{ marginTop: -8 }}
              tooltip={shiftTopHelp}
            >
              <InputNumber defaultValue={Math.round(planogram.height / 2)}
                disabled={!enabled}
                onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                  const shift = +e.target.value;

                  if (shift) {
                    setHorShift(shift);
                  }
                }}
                size={'small'}
              />
            </Form.Item>
            <Space
              size='large'
              style={{ position: 'relative', top: -15 }}>
              <AddAction action={() => {
                addLine({ orientation: FRAME_LINE_HOR, shift: horShift });
              }}
              disabled={!enabled}
              title={t('addLineHorHelp')}
              />
            </Space>
          </Space>
        </Space>
      </Form>
      <Table
        columns={columns}
        dataSource={lines}
        onRow={(line) => {
          return {
            onClick: () => {
              setSelectedLine(line.orderNumber - 1);
            }
          };
        }}
        pagination={{ hideOnSinglePage: true, pageSize: MAX_PAGE_SIZE }}
        rowClassName={(line: FrameLine) => line.orderNumber - 1 === selectedLine ? 'selected' : ''}
        scroll={{ y: height - 410 }}
        size='small'
        style={{ maxWidth: '600px' }}
        tableLayout='auto'
      />
    </div>
  );
};

export default memo(PlanogramFrames);
