// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, useEffect, useState } from 'react';
import { Modal, Form, Input, Select, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import useEdit, { EditProps } from 'hooks/useEdit';
import useSkuCategories from 'hooks/useSkuCategories';
import { DB_PLANOGRAM_STATE_IN_WORK, DB_PLANOGRAM_STATE_NEW, DB_PLANOGRAM_STATE_READY } from 'database/constants';
import { LIST_MODE_UPDATE, RACK_MAX_NUM_OF_SHELVES, RACK_MIN_NUM_OF_SHELVES, PATH_PLANOGRAMS, RACK_MIN_SHELF_THICKNESS, RACK_MAX_SHELF_THICKNESS, LIST_MODE_ADD } from 'common/constants';
import usePlanograms from 'hooks/usePlanograms';
import { useHistory } from 'react-router-dom';
import { Planogram } from 'database/entities/planogram';

const EditPlanogramForm: React.FC<EditProps> = (props: EditProps) => {
  const { t } = useTranslation();
  const { editMode, form, onCancel, visible } = props;
  const { onSave, title } = useEdit(props);
  const { skuCategories } = useSkuCategories();
  const { getCurrentPlanogram, getFilter } = usePlanograms();
  const history = useHistory();
  const [planogramState, setPlanogramState] = useState<number>(DB_PLANOGRAM_STATE_NEW);
  const [parent, setParent] = useState<string | null>(null);

  const layout = {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 16
    }
  };

  useEffect(() => {
    const p = getCurrentPlanogram() as Planogram;

    if (p === null) {
      history.push(PATH_PLANOGRAMS);
    } else {
      setPlanogramState(p.state);
      setParent(p.parent);

      if (p.state === DB_PLANOGRAM_STATE_NEW) {
        const filter = getFilter();

        if ((filter) && (filter.skuCategoryId)) {
          p.skuCategoryId = filter.skuCategoryId;
        }
      }

      form.setFieldsValue(p);
    }
  }, [form, getCurrentPlanogram, getFilter, history]);

  return (
    <Modal
      cancelText={t('cancel')}
      okText={t('save')}
      onCancel={onCancel}
      onOk={onSave}
      title={title}
      visible={visible}
    >
      <Form {...layout }
        form={form}
        layout='horizontal'
        name='edit_sku_planogram_form'
      >
        <Form.Item
          label={t('category')}
          name='skuCategoryId'
          rules={[
            {
              message: t('needSkuCategoryName'),
              required: true
            }
          ]}
        >
          <Select
            disabled={(planogramState !== DB_PLANOGRAM_STATE_NEW) || (parent !== null) }
          >
            {skuCategories.map((sc) => // {
              <Select.Option
                key={sc.id}
                value={sc.id}>
                {sc.name}
              </Select.Option>)};
          </Select>
        </Form.Item>
        <Form.Item
          label={t('numOfShelves')}
          name='numOfShelves'
          rules={[
            {
              max: RACK_MAX_NUM_OF_SHELVES,
              message: `${t('numOfShelvesMustBe')} ${RACK_MIN_NUM_OF_SHELVES} ${t('to')} ${RACK_MAX_NUM_OF_SHELVES}`,
              min: RACK_MIN_NUM_OF_SHELVES,
              required: true,
              type: 'integer'
            }
          ]}
        >
          <InputNumber disabled={planogramState !== DB_PLANOGRAM_STATE_NEW} />
        </Form.Item>
        <Form.Item
          label={t('shelfThickness')}
          name='shelfThickness'
          rules={[
            {
              max: RACK_MAX_SHELF_THICKNESS,
              message: `${t('shelfThicknessMustBe')} ${RACK_MIN_SHELF_THICKNESS} ${t('to')} ${RACK_MAX_SHELF_THICKNESS}`,
              min: RACK_MIN_SHELF_THICKNESS,
              required: true,
              type: 'integer'
            }
          ]
          }
        >
          <InputNumber disabled={(planogramState !== DB_PLANOGRAM_STATE_NEW) || (editMode !== LIST_MODE_ADD)} />
        </Form.Item>
        <Form.Item
          label={t('comment')}
          name='comment'
        >
          <Input allowClear={true} />
        </Form.Item>
        { (editMode === LIST_MODE_UPDATE) &&
          <Form.Item
            label={t('state')}
            name='state'
          >
            <Select disabled={planogramState === DB_PLANOGRAM_STATE_READY}
              style={{ width: 120 }}>
              { (planogramState === DB_PLANOGRAM_STATE_NEW) &&
                <><Select.Option value={DB_PLANOGRAM_STATE_NEW}>{t('new')}</Select.Option>
                  <Select.Option value={DB_PLANOGRAM_STATE_READY}>{t('ready')}</Select.Option></>
              }
              { (planogramState === DB_PLANOGRAM_STATE_IN_WORK) &&
                <><Select.Option value={DB_PLANOGRAM_STATE_IN_WORK}>{t('inWork')}</Select.Option>
                  <Select.Option value={DB_PLANOGRAM_STATE_READY}>{t('ready')}</Select.Option></>
              }
              { (planogramState === DB_PLANOGRAM_STATE_READY) &&
                <Select.Option value={DB_PLANOGRAM_STATE_READY}>{t('ready')}</Select.Option>
              }
            </Select>
          </Form.Item>
        }
      </Form>
    </Modal>
  );
};

export default memo(EditPlanogramForm);
