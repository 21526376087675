// Copyright 2022 @po-polochkam authors & contributors

import React, { memo, useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContentSku } from 'types/contentSku';
import { OTHER_SKU_ID } from 'common/constants';

export interface ContentCodesProps {
  contentSkus: ContentSku[];
}

const ContentCodes: React.FC<ContentCodesProps> = (props) => {
  const { t } = useTranslation();
  const { contentSkus } = props;
  const [form] = Form.useForm();

  const makeCodeList = useCallback(() => {
    let codeList = '';

    contentSkus.forEach((sku) => {
      if ((sku.id !== OTHER_SKU_ID) && (!codeList.includes(sku.code))) {
        codeList += ',' + sku.code;
      }
    });

    if (codeList.length > 0) {
      codeList = codeList.slice(1);
    }

    form.setFieldsValue({ codeList: codeList });
  }, [contentSkus, form]);

  return (
    <Form
      form={form}
      layout='inline'
      name='content_codes_form'
      size={'small'} >
      <Button
        onClick={makeCodeList}
      >
        {t('codeList')}
      </Button>
      <Form.Item
        name='codeList'
        style={{ marginLeft: 2, marginTop: 5, width: 620 }}
      >
        <Input allowClear={true} />
      </Form.Item>
    </Form>
  );
};

export default memo(ContentCodes);
