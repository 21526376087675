// Copyright 2021 @po-polochkam authors & contributors

import { useCallback, useEffect, useState } from 'react';
import { LIST_MODE_ADD } from 'common/constants';
import { FormInstance } from 'antd/lib/form';

export interface AddProps {
  add: () => void;
}

export interface EditProps {
  enabled?: boolean;
  form: FormInstance;
  visible: boolean;
  editMode: number;
  doSave: () => Promise<string>;
  onCancel: () => void;
  newTitle: string;
  editTitle: string;
}

export interface ActionProps {
  action: () => void;
  disabled?: boolean;
  title?: string;
}

interface UseEditInterface {
  error: string;
  onSave: () => void;
  setError: (error: string) => void;
  setTitle: (title: string) => void;
  title: string;
}

export function useEdit (props: EditProps): UseEditInterface {
  const [error, setError] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const { doSave, editMode, editTitle, newTitle } = props;

  const onSave = useCallback(async () => {
    await doSave()
      .then((reason) => {
        if (reason !== '') {
          console.log('Error: ', reason);
          setError(reason);
        }
      });
  }, [doSave]);

  useEffect(() => {
    if (editMode === LIST_MODE_ADD) {
      setTitle(newTitle);
    } else {
      setTitle(editTitle);
    }
  }, [editMode, editTitle, newTitle]);

  return {
    error,
    onSave,
    setError,
    setTitle,
    title
  };
}

export default useEdit;
