// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Image, Layout, Menu, Space, Typography } from 'antd';
import { ExportOutlined, LeftOutlined, RightOutlined, ShopOutlined, ShoppingOutlined, TableOutlined } from '@ant-design/icons';
import { PATH_PLANOGRAMS, PATH_SKUS, PATH_SKU_CATEGORIES, VERSION } from 'common/constants';
import './styles.less';
import { isLocalString, logout, setLocalString } from 'common/localVars';

const { Sider } = Layout;
const { Text, Title } = Typography;

interface SidebarMenuProps {
  defaultSelectedKeys: string;
}

const SidebarMenu: React.FC<SidebarMenuProps> = (props: SidebarMenuProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(isLocalString('collapsed', 'collapsed'));
  const { defaultSelectedKeys } = props;
  const { t } = useTranslation();

  const toggle = () => {
    if (collapsed) {
      setLocalString('collapsed', '');
    } else {
      setLocalString('collapsed', 'collapsed');
    }

    setCollapsed(!collapsed);
  };

  const onLogout = useCallback(() => {
    logout();
  }, []);

  return (
    <Sider
      className='top-sidebar'
      collapsed={collapsed}
      collapsible
      trigger={null}
      width={221}
    >
      <div>
        {!collapsed && (
          <><Space style={{ marginLeft: 16, marginTop: 18 }}>
            <Image preview={false}
              src='images/ic_launcher.png'
              width={32} />

            <Space style={{ marginLeft: 6, marginTop: 8 }}>
              <Title level={5}
                style={{ color: 'white' }}
              >
                {t('popolochkam')}
              </Title>
            </Space>
          </Space>
          <div>
            <Space style={{ marginLeft: 80, marginTop: 0 }}>
              <Text style={{ color: 'white', fontSize: 12 }}
              >
                {t('version') + VERSION}
              </Text>
            </Space>
          </div>
          </>
        )}
      </div>
      <div className='top-sidebar-menu'>
        <Menu
          defaultSelectedKeys={[defaultSelectedKeys]}
          mode='inline'
          theme='dark'>
          <Menu.Item
            icon={<TableOutlined />}
            key={PATH_PLANOGRAMS}>
            <Link to={PATH_PLANOGRAMS}> {t('planograms')} </Link>
          </Menu.Item>
          <Menu.Item
            icon={<ShoppingOutlined />}
            key={PATH_SKUS}>
            <Link to={PATH_SKUS}> {t('skus')} </Link>
          </Menu.Item>
          <Menu.Item
            icon={<ShopOutlined />}
            key={PATH_SKU_CATEGORIES}>
            <Link to={PATH_SKU_CATEGORIES}> {t('skuCategories')} </Link>
          </Menu.Item>
        </Menu>
      </div>
      <div className='bottom-sidebar-menu'>
        <Menu theme='dark'>
          <Menu.Item
            data-testid='logout'
            icon={<ExportOutlined />}
            key='finish_session'
          >
            <a href={'finish'}
              onClick={onLogout}>{(t('finish session') || '').toString()}</a>
          </Menu.Item>
        </Menu>
        <div>
          <a href={'trigger'}
            style={{ alignContent: 'center', alignItems: 'center', color: 'darkgray', marginBottom: 100 }}>
            {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
              className: 'trigger',
              onClick: toggle
            })}
          </a>
        </div>
      </div>
    </Sider>
  );
};

export default memo(SidebarMenu);
