// Copyright 2021 @po-polochkam authors & contributors

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './translations/ru.json';

const resources = {
  ru: {
    translation: ru
  }
};

void i18n
  .use(initReactI18next)
  .init({
    debug: true,
    defaultNS: 'translation',
    fallbackLng: 'ru',
    lng: 'ru',
    resources
  });

export default i18n;
