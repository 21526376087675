// Copyright 2021 @po-polochkam authors & contributors

import { useCallback, useState } from 'react';
import useFirestore from 'hooks/useFirestore';
import { CollectionReference } from '@firebase/firestore-types';
import { DB_USERS } from 'database/constants';
import { LoginRequest, LoginResponse } from 'common/intefaces';

export interface UserDataInterface {
  loginUser: (values: LoginRequest) => Promise<LoginResponse>,
  userExists: (id: string) => Promise<boolean>
}

export default function useUserData (): UserDataInterface {
  const { firestore } = useFirestore();
  const [db] = useState<CollectionReference | undefined>(firestore?.collection(DB_USERS));

  const loginUser = useCallback(async (values: LoginRequest): Promise<LoginResponse> => {
    const querySnapshot = await db?.where('username', '==', values.username).where('password', '==', values.password).limit(1).get();

    const result: LoginResponse = { code: '401', id: '' };

    if (querySnapshot) {
      const users = querySnapshot.docs;

      if (users.length === 1) {
        result.code = '200';
        result.id = users[0].id;
      }
    }

    return result;
  }, [db]);

  const userExists = useCallback(async (id: string): Promise<boolean> => {
    const user = await db?.doc(id).get();

    return !!(user);
  }, [db]);

  return {
    loginUser,
    userExists
  };
}
