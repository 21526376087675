// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Space, Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useSkuCategories from 'hooks/useSkuCategories';

export interface SkuCategoryFiltersProps {
  allowClear: boolean;
  form: FormInstance;
  removeSkuCategory: () => void;
  saveSkuCategory: (skuCategory: any) => void;
  skuCategory: string | undefined;
}

const SkusCategoryFilter: React.FC<SkuCategoryFiltersProps> = (props) => {
  const { t } = useTranslation();
  const { allowClear, form, removeSkuCategory, saveSkuCategory, skuCategory } = props;
  const { skuCategories } = useSkuCategories();

  const layout = {
    labelCol: {
      span: 100
    },
    wrapperCol: {
      span: 100
    }
  };

  return (
    <div>
      <Space style={{ marginBottom: 10, marginTop: 10 }}>
        <Form {...layout}
          form={form}
          layout='inline'
          name='sku_category_filter_form'
          size={'small'} >
          <Form.Item
            label={t('category')}
            name='skuCategoryId'
            style={{ marginTop: 0 }}
          >
            <Select
              allowClear={allowClear}
              clearIcon= {<CloseOutlined />}
              defaultValue={skuCategory}
              onClear={removeSkuCategory}
              onSelect={saveSkuCategory}
              placeholder={t('selectCategory')}
              showArrow={false}
              style={{ left: 17, width: 400 }}>
              {skuCategories.map((sc) =>
                <Select.Option
                  key={sc.id}
                  value={sc.id}>
                  {sc.name}
                </Select.Option>)};
            </Select>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default memo(SkusCategoryFilter);
