// Copyright 2021 @po-polochkam authors & contributors

import React from 'react';
import { Button, Form, Input, InputNumber, Select, Switch } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { MAX_PLANOGRAM_COMMENT_FONT_SIZE,
  MAX_PLANOGRAM_COMMENT_HEIGHT,
  MAX_PLANOGRAM_COMMENT_PADDING,
  MIN_PLANOGRAM_COMMENT_FONT_SIZE,
  MIN_PLANOGRAM_COMMENT_PADDING,
  PLANOGRAM_COMMENT_BOTTOM,
  PLANOGRAM_COMMENT_NONE } from 'common/constants';

export interface EditPlanogramCommentProps {
  apply: () => void;
  enabled?: boolean;
  form: FormInstance;
}

const EditPlanogramCommentForm: React.FC<EditPlanogramCommentProps> = (props) => {
  const { t } = useTranslation();
  const { apply, enabled, form } = props;

  return (
    <Form
      form={form}
      labelCol={{
        span: 5
      }}
      layout='horizontal'
      name='edit_planogram_comment_form'
      wrapperCol={{
        span: 16
      }}
    >
      <Form.Item
        label={t('comment')}
        name='workComment'
      >
        <Input.TextArea allowClear={true}
          autoSize={true}
          disabled={!enabled}
        />
      </Form.Item>
      <Form.Item
        label={t('commentPlace')}
        name='commentPlace'
      >
        <Select defaultValue={PLANOGRAM_COMMENT_NONE}
          disabled={!enabled}
          style={{ width: 150 }} >
          <Select.Option value={PLANOGRAM_COMMENT_NONE}>{t('commentNone')}</Select.Option>
          <Select.Option value={PLANOGRAM_COMMENT_BOTTOM}>{t('commentBottom')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={t('height')}
        name='commentHeight'
        rules={[
          {
            max: MAX_PLANOGRAM_COMMENT_HEIGHT,
            message: `${t('commentHeightMustBe')} 0 ${t('to')} ${MAX_PLANOGRAM_COMMENT_HEIGHT}`,
            min: 0,
            type: 'integer'
          }
        ]}
      >
        <InputNumber disabled={!enabled} />
      </Form.Item>
      <Form.Item label={t('fontSize')}
        name='commentFontSize'
        rules={[
          {
            max: MAX_PLANOGRAM_COMMENT_FONT_SIZE,
            message: `${t('commentFontSizeMustBe')} ${MIN_PLANOGRAM_COMMENT_FONT_SIZE} ${t('to')} ${MAX_PLANOGRAM_COMMENT_FONT_SIZE}`,
            min: MIN_PLANOGRAM_COMMENT_FONT_SIZE,
            type: 'integer'
          }
        ]}
      >
        <InputNumber disabled={!enabled} />
      </Form.Item>
      <Form.Item label={t('commentPadding')}
        name='commentPadding'
        rules={[
          {
            max: MAX_PLANOGRAM_COMMENT_PADDING,
            message: `${t('commentPaddingMustBe')} ${MIN_PLANOGRAM_COMMENT_PADDING} ${t('to')} ${MAX_PLANOGRAM_COMMENT_PADDING}`,
            min: MIN_PLANOGRAM_COMMENT_PADDING,
            type: 'integer'
          }
        ]}
      >
        <InputNumber disabled={!enabled} />
      </Form.Item>
      <Form.Item label={t('boldFont')}
        name='commentBold'
        valuePropName='checked'>
        <Switch />
      </Form.Item>
      <Form.Item name='apply'>
        <Button onClick={apply}>
          {t('apply')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default React.memo(EditPlanogramCommentForm);
