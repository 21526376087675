// Copyright 2021 @po-polochkam authors & contributors

import React, { useCallback } from 'react';
import { Space, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Planogram } from 'database/entities/planogram';
import { stringDateToView } from 'common/datetimes';
import useSkuCategories from './useSkuCategories';
import { LIST_MODE_UPDATE } from 'common/constants';
import { DB_PLANOGRAM_STATE_IN_WORK, DB_PLANOGRAM_STATE_NEW, DB_PLANOGRAM_STATE_READY } from 'database/constants';
import { CopyAction, DeleteAction, EditAction } from 'components/actions';
import { useTranslation } from 'react-i18next';

interface UsePlanogramListInterface {
  columns: () => ColumnsType<Planogram>;
}

interface UsePlanogramProps {
  copy: (planogram: Planogram) => void;
  enabled: boolean;
  parent: string | null;
  removePlanogram: (planogram: Planogram) => void;
  update: (listMode: number, record: Planogram) => void;
}

export function usePlanogramList (props: UsePlanogramProps): UsePlanogramListInterface {
  const { t } = useTranslation();
  const { copy, enabled, parent, removePlanogram, update } = props;
  const { Text } = Typography;
  const { skuCategoryName } = useSkuCategories();

  const state = useCallback((planogram: Planogram): string => {
    switch (planogram.state) {
      case DB_PLANOGRAM_STATE_NEW: {
        return t('new');
      }

      case DB_PLANOGRAM_STATE_IN_WORK: {
        return t('inWork');
      }

      default: {
        return t('ready');
      }
    }
  }, [t]);

  const columns = useCallback((): ColumnsType<Planogram> => {
    const result: ColumnsType<Planogram> = [];

    result.push(
      {
        dataIndex: 'id',
        fixed: true,
        title: t('id'),
        width: '200px'
      },
      {
        dataIndex: 'date',
        fixed: true,
        // eslint-disable-next-line react/display-name
        render: (_text, planogram) => (
          <Space>
            <Text>{stringDateToView(planogram.date)}</Text>
          </Space>
        ),
        title: t('date'),
        width: '100px'
      }
    );

    if (parent === null) {
      result.push(
        {
          dataIndex: 'categoryKey',
          // eslint-disable-next-line react/display-name
          render: (_text, planogram) => {
            return (
              <Space>
                <Text>{skuCategoryName(planogram.skuCategoryId)}</Text>
              </Space>
            );
          },
          title: t('category'),
          width: '200px'
        }
      );
    }

    result.push(
      {
        align: 'right',
        dataIndex: 'numOfShelves',
        fixed: true,
        title: t('numOfShelves'),
        width: '60px'
      },
      {
        dataIndex: 'comment',
        title: t('comment'),
        width: '200px'
      },
      {
        dataIndex: 'state',
        fixed: true,
        // eslint-disable-next-line react/display-name
        render: (_text, planogram) => (
          <Space>
            <Text>{state(planogram)}</Text>
          </Space>
        ),
        title: t('state'),
        width: '50px'
      },
      {
        dataIndex: 'action',
        // eslint-disable-next-line react/display-name
        render: (_text, planogram) => (
          <Space
            size='large'
            style={{ marginLeft: 10 }}>
            <EditAction action={update.bind(null, LIST_MODE_UPDATE, planogram)}
              disabled={!enabled || (planogram.state === DB_PLANOGRAM_STATE_READY)}
            />
            <CopyAction action={copy.bind(null, planogram)}
              disabled={!enabled}
            />
            <DeleteAction action={removePlanogram.bind(null, planogram)}
              disabled={!enabled || (planogram.state === DB_PLANOGRAM_STATE_READY)}
            />
          </Space>
        ),
        title: t('actions'),
        width: '90px'
      }
    );

    return result;
  }, [Text, copy, enabled, parent, removePlanogram, skuCategoryName, state, t, update]);

  return {
    columns
  };
}
