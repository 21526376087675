// Copyright 2022 @po-polochkam authors & contributors
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Form, FormInstance, Image, InputNumber, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterOutlined, FilterTwoTone } from '@ant-design/icons';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import useSkus, { SkuBlock } from 'hooks/useSkus';
import { LIST_SKU_IMAGE_HEIGHT,
  LIST_SKU_IMAGE_WIDTH,
  MAX_PAGE_SIZE,
  MAX_SKUS_IN_HEIGHT,
  MAX_SKUS_IN_WIDTH,
  MAX_SKU_HEIGHT,
  MIN_SKU_HEIGHT,
  MIN_SKU_WIDTH,
  OTHER_SKU_ID,
  OTHER_SKU_INITIAL_HEIGHT,
  OTHER_SKU_INITIAL_WIDTH,
  PLANOGRAM_TABS_WIDTH } from 'common/constants';
import Text from 'antd/lib/typography/Title';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { AddAction } from 'components/actions';
import TextFilter from 'components/filters/TextFilter';
import './styles.scss';
import BrandFilter from 'components/filters/BrandFilter';
import { clearCodeFilter, saveCodeFilterMode, saveCodeFilterValues, saveSkuCategoryBrand } from 'common/localVars';
import CodeFilter from 'components/filters/CodeFilter';

export interface SkuExt {
  skuCategoryId: string,
  id: string,
  photo: string,
  code: string,
  name: string,
  brand: string,
  width: number,
  height: number,
  rotation: number,
  shelfNumber: number,
  skusInHeight: number,
  skusInWidth: number,
  index: number,
}

export interface PlanogramSkusProps {
  enabled: boolean;
  form: FormInstance;
  maxOtherSkuWidth: number;
  numOfShelves: number;
  placeOnShelf: (sku: SkuExt, skuBlock: SkuBlock) => void;
  setCurrentSku: (sku: SkuExt | null) => void;
  skuCategoryId: string;
}

const PlanogramSkus: React.FC<PlanogramSkusProps> = (props) => {
  const { t } = useTranslation();
  const { enabled, form, maxOtherSkuWidth, numOfShelves, placeOnShelf, setCurrentSku, skuCategoryId } = props;
  const { brands, currentBrand, imageDimensions, refreshBrandSkus, setFilterName, setRefreshData, setSkuRotation, skusFiltered } = useSkus();
  const { height } = useWindowDimensions();
  const [otherColor, setOtherColor] = useState('lightgray');
  const [nameFilterVisible, setNameFilterVisible] = useState(false);
  const [nameFilterText] = useState('');
  const { Option } = Select;

  const [otherSkuExt] = useState<SkuExt>({
    brand: '',
    code: '',
    height: OTHER_SKU_INITIAL_HEIGHT,
    id: OTHER_SKU_ID,
    index: 0,
    name: `${t('otherSkusBlock')}`,
    photo: '',
    rotation: 0,
    shelfNumber: 1,
    skuCategoryId: '',
    skusInHeight: 1,
    skusInWidth: 1,
    width: OTHER_SKU_INITIAL_WIDTH
  });

  const otherStyle = {
    backgroundColor: otherColor,
    height: LIST_SKU_IMAGE_HEIGHT,
    width: LIST_SKU_IMAGE_WIDTH
  };

  const otherSku: SkuExt = {
    brand: '',
    code: '',
    height: OTHER_SKU_INITIAL_HEIGHT,
    id: OTHER_SKU_ID,
    index: 0,
    name: `${t('otherSkusBlock')}`,
    photo: '',
    rotation: 0,
    shelfNumber: 1,
    skuCategoryId: '',
    skusInHeight: 1,
    skusInWidth: 1,
    width: OTHER_SKU_INITIAL_WIDTH
  };

  useEffect(() => {
    form.setFieldsValue({ brand: currentBrand });
  }, [currentBrand, form]);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    refreshBrandSkus();
  });

  const onDragStart = useCallback((e: React.DragEvent<HTMLDivElement>, sku: SkuExt) => {
    setCurrentSku(sku);
    e.dataTransfer.setData('text/plain', '');
  }, [setCurrentSku]);

  const filterIcon = (text: string): React.ReactNode => {
    if (text === '') {
      return <FilterOutlined/>;
    } else {
      return <FilterTwoTone/>;
    }
  };

  const searchName = useCallback((value: string) => {
    setFilterName(value.toUpperCase());
    setRefreshData(true);
  }, [setFilterName, setRefreshData]);

  const saveRotation = useCallback((index: number, rotation: number) => {
    skusFiltered[index].rotation = rotation;
    setSkuRotation(skusFiltered[index].code, rotation);
  }, [setSkuRotation, skusFiltered]);

  const changeBrand = useCallback((newBrand: number) => {
    saveSkuCategoryBrand(skuCategoryId, brands[newBrand].name);
    setRefreshData(true);
  }, [brands, setRefreshData, skuCategoryId]);

  const applyCodeFilter = useCallback(() => {
    const codeFilterMode: string | undefined = form.getFieldValue('codeMode');

    saveCodeFilterMode(codeFilterMode);

    const codeFilterValues: string | undefined = form.getFieldValue('codeValues');

    saveCodeFilterValues(codeFilterValues);

    setRefreshData(true);
  }, [form, setRefreshData]);

  const resetCodeFilter = useCallback(() => {
    clearCodeFilter();

    setRefreshData(true);
  }, [setRefreshData]);

  const columns: ColumnsType<SkuExt> = [
    {
      align: 'center',
      dataIndex: 'photo',
      fixed: true,
      // eslint-disable-next-line react/display-name
      render: (photo: string | undefined, sku) => {
        const d = imageDimensions(sku.width, sku.height);

        return <Image
          draggable={enabled}
          height={d.height}
          key={`photo${sku.code}`}
          onDragStart={(e) => { onDragStart(e, sku); } }
          src={photo}
          style={{ transform: `rotate(${skusFiltered[sku.index].rotation}deg)` }}
          unselectable={'on'}
          width={d.width}
        />;
      },
      title: t('photo'),
      width: 60
    },
    {
      align: 'right',
      dataIndex: 'width',
      fixed: true,
      sorter: (a: { width: number; }, b: { width: number; }) => a.width - b.width,
      title: t('wMM'),
      width: 50
    },
    {
      align: 'right',
      dataIndex: 'height',
      fixed: true,
      sorter: (a: { height: number; }, b: { height: number; }) => a.height - b.height,
      title: t('hMM'),
      width: 50
    },
    {
      align: 'left',
      dataIndex: 'code',
      fixed: true,
      sorter: (a: { code: string; }, b: { code: string; }) => a.code.localeCompare(b.code),
      title: t('code'),
      width: 110
    },
    {
      align: 'left',
      dataIndex: 'name',
      filterDropdown: <TextFilter allowClear={true}
        defaultValue={nameFilterText}
        onSearch={searchName}
        placeholder={t('name')}/>,
      filterDropdownVisible: nameFilterVisible,
      filterIcon: filterIcon(nameFilterText),
      fixed: true,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        setNameFilterVisible(visible);
      },
      sorter: (a: { name: string; }, b: { name: string; }) => { return a.name.localeCompare(b.name); },
      title: t('name'),
      width: 200
    },
    {
      align: 'center',
      dataIndex: 'rotation',
      // eslint-disable-next-line react/display-name
      render: (_, sku) => (
        <Form.Item
          name={`rotation${sku.index}`}>
          <Select
            defaultValue={skusFiltered[sku.index].rotation}
            disabled={!enabled}
            onSelect={(rotation: number) => {
              saveRotation(sku.index, rotation);
            }}
            showArrow={false}
            style={{ left: 0, width: 75 }}>
            <Option value={0}>{t('no')}</Option>
            <Option value={90}>{t('toRight')}</Option>
            <Option value={270}>{t('toLeft')}</Option>;
          </Select>
        </Form.Item>
      ),
      title: t('rotation'),
      width: 75
    },
    {
      align: 'left',
      dataIndex: 'skusInWidth',
      render: (_, sku) => (
        <Form.Item
          name={`skusInWidth${sku.index}`}>
          <InputNumber defaultValue={skusFiltered[sku.index].skusInWidth}
            disabled={!enabled}
            max={MAX_SKUS_IN_WIDTH}
            min={1}
            parser={(value) => {
              let k = 1;

              if (value) {
                const n = Number(value);

                if (n >= 1 && n <= MAX_SKUS_IN_WIDTH) {
                  k = n;
                } else {
                  if (n < 1) {
                    k = 1;
                  } else {
                    k = MAX_SKUS_IN_WIDTH;
                  }
                }
              }

              skusFiltered[sku.index].skusInWidth = k;

              return k;
            }}
            style={{ width: 60 }}
          />
        </Form.Item>
      ),
      title: t('skusInWidth'),
      width: 70
    },
    {
      align: 'left',
      dataIndex: 'skusInHeight',
      // eslint-disable-next-line react/display-name
      render: (_, sku) => (
        <Form.Item
          name={`skusInHeight${sku.index}`}>
          <InputNumber defaultValue={skusFiltered[sku.index].skusInHeight}
            disabled={!enabled}
            max={MAX_SKUS_IN_HEIGHT}
            min={1}
            parser={(value) => {
              let k = 1;

              if (value) {
                const n = Number(value);

                if (n >= 1 && n <= MAX_SKUS_IN_HEIGHT) {
                  k = n;
                } else {
                  if (n < 1) {
                    k = 1;
                  } else {
                    sku.skusInHeight = MAX_SKUS_IN_HEIGHT;
                  }
                }
              } else {
                k = 1;
              }

              skusFiltered[sku.index].skusInHeight = k;

              return k;
            }}
            style={{ width: 60 }}
            value={skusFiltered[sku.index].skusInHeight}
          />
        </Form.Item>
      ),
      title: t('skusInHeight'),
      width: 70
    },
    {
      align: 'left',
      dataIndex: 'shelfNumber',
      // eslint-disable-next-line react/display-name
      render: (_, sku) => (
        <Form.Item
          name={`shelfNumber${sku.index}`}>
          <InputNumber defaultValue={skusFiltered[sku.index].shelfNumber}
            disabled={!enabled}
            max={numOfShelves}
            min={1}
            parser={(value) => {
              let k = 1;

              if (value) {
                const n = Number(value);

                if (n >= 1 && n <= numOfShelves) {
                  k = n;
                } else {
                  if (n < 1) {
                    k = 1;
                  } else {
                    k = numOfShelves;
                  }
                }
              } else {
                k = 1;
              }

              skusFiltered[sku.index].shelfNumber = k;

              return k;
            }}
            style={{ width: 60 }}
          />
        </Form.Item>
      ),
      title: t('shelfNumber'),
      width: 70
    },
    {
      dataIndex: 'action',
      // eslint-disable-next-line react/display-name
      render: (_text, sku) => (
        <Space
          size='large'
          style={{ marginLeft: 10 }}>
          <AddAction action={ placeOnShelf.bind(null, sku, skusFiltered[sku.index]) }
            disabled={!enabled}
            title={t('placeOnShelf')}
          />
        </Space>
      ),
      title: t('actions'),
      width: '80px'
    }
  ];

  return (
    <div>
      <Form
        form={form}
        layout='inline'
        name='planogram_sku_form'
      >
        <Space direction='vertical'>
          <Space
            style={{ marginLeft: 12,
              paddingRight: 20 }}>
            <div className='element-block other'>
              <div
                className='droppable-element other'
                draggable={true}
                onDragStart={(e) => { onDragStart(e, otherSku); } }
                onMouseEnter={() => { setOtherColor('grey'); }}
                onMouseLeave={() => { setOtherColor('lightgrey'); }}
                style={otherStyle}
                unselectable='on'
              >
              </div>
            </div>
            <Space style={{ marginTop: 8 }}>
              <Text style={{ fontSize: '14px' }}>{t('otherSkusBlock')} </Text>
              <Text style={{ fontSize: '14px', marginLeft: 10 }}>{`${t('widthMM')}:`} </Text>
            </Space>
            <Form.Item
              name='otherSkuWidth'>
              <InputNumber defaultValue={otherSkuExt.width}
                disabled={!enabled}
                max={maxOtherSkuWidth}
                min={MIN_SKU_WIDTH}
                parser={(value) => {
                  if (value) {
                    const n = Number(value);

                    if (n >= MIN_SKU_WIDTH && n <= maxOtherSkuWidth) {
                      otherSkuExt.width = n;
                    } else {
                      if (n < MIN_SKU_WIDTH) {
                        otherSkuExt.width = MIN_SKU_WIDTH;
                      } else {
                        otherSkuExt.width = maxOtherSkuWidth;
                      }
                    }
                  } else {
                    otherSkuExt.width = MIN_SKU_WIDTH;
                  }

                  return otherSkuExt.width;
                }}
                style={{ width: 70 }}/>
            </Form.Item>
            <Space style={{ marginTop: 8 }}>
              <Text style={{ fontSize: '14px' }}>{`${t('heightMM')}:`} </Text>
            </Space>
            <Form.Item
              name='otherSkuHeight'>
              <InputNumber defaultValue={otherSkuExt.height}
                disabled={!enabled}
                max={MAX_SKU_HEIGHT}
                min={MIN_SKU_HEIGHT}
                parser={(value) => {
                  if (value) {
                    const n = Number(value);

                    if (n >= MIN_SKU_WIDTH && n <= MAX_SKU_HEIGHT) {
                      otherSkuExt.height = n;
                    } else {
                      if (n < MIN_SKU_WIDTH) {
                        otherSkuExt.height = MIN_SKU_HEIGHT;
                      } else {
                        otherSkuExt.height = MAX_SKU_HEIGHT;
                      }
                    }
                  } else {
                    otherSkuExt.height = MIN_SKU_HEIGHT;
                  }

                  return otherSkuExt.height;
                }}
                style={{ width: 70 }}/>
            </Form.Item>
            <Space style={{ marginTop: 8 }}>
              <Text style={{ fontSize: '14px' }}>{`${t('shelfNumber')}:`} </Text>
            </Space>
            <InputNumber defaultValue={otherSkuExt.shelfNumber}
              disabled={!enabled}
              max={numOfShelves}
              min={1}
              name={'oherSkuShelfNumber'}
              parser={(value) => {
                if (value) {
                  const n = Number(value);

                  if (n >= 1 && n <= numOfShelves) {
                    otherSkuExt.shelfNumber = n;
                  } else {
                    if (n < 1) {
                      otherSkuExt.shelfNumber = 1;
                    } else {
                      otherSkuExt.height = numOfShelves;
                    }
                  }
                } else {
                  otherSkuExt.shelfNumber = 1;
                }

                return otherSkuExt.shelfNumber;
              }}
              style={{ width: 70 }}/>
            <Space
              size='large'
              style={{ marginLeft: 10 }}>
              <AddAction action={() => {
                const w = form.getFieldValue('otherSkuWidth');

                if (w) {
                  otherSkuExt.width = w;
                }

                const h = form.getFieldValue('otherSkuHeight');

                if (h) {
                  otherSkuExt.height = h;
                }

                placeOnShelf(otherSkuExt, { rotation: otherSkuExt.rotation, shelfNumber: otherSkuExt.shelfNumber, skusInHeight: otherSkuExt.skusInHeight, skusInWidth: otherSku.skusInWidth });
              }}
              disabled={!enabled}
              title={t('placeOnShelf')}
              />
            </Space>
          </Space>
          <Space style={{ marginLeft: 27 }}>
            <BrandFilter
              allowClear={false}
              brands={brands}
              change={changeBrand}
              form={form}
            />
          </Space>
          <Space style={{ marginBottom: 10 }}>
            <CodeFilter
              apply={applyCodeFilter}
              form={form}
              reset={resetCodeFilter}
            />
          </Space>
        </Space>
        <Table
          columns={columns}
          dataSource={skusFiltered}
          pagination={{ hideOnSinglePage: true, pageSize: MAX_PAGE_SIZE }}
          scroll={{ y: height - 350 }}
          size='small'
          style={{ maxWidth: PLANOGRAM_TABS_WIDTH, width: 1140 }}
          tableLayout='fixed'
        />
      </Form>
    </div>
  );
};

export default memo(PlanogramSkus);
