// Copyright 2021 @po-polochkam authors & contributors

import { todayToString } from 'common/datetimes';
import { LoginResponse, LoginData, PlanogramFilter } from 'common/intefaces';
import { CODE_FILTER_MODE, CODE_FILTER_VALUES, CURRENT_SKU_CATEGORY_ID, PLANOGRAM_FILTER, SKU_CATEGORY_BRAND, USER, USER_ID } from 'common/constants';

export const getLocalString = (name: string, value: string): string => {
  let result = localStorage.getItem(name);

  if (result === 'undefined') {
    result = null;
  }

  return result || value;
};

export const setLocalString = (name: string, value: string): void => {
  localStorage.setItem(name, value);
};

export const isLocalString = (name: string, value: string): boolean => {
  const result = localStorage.getItem(name);

  return result === value;
};

export const getLocalNumber = (name: string, value: number): number => {
  const result = localStorage.getItem(name);

  return result ? Number(result) : value;
};

export const setLocalNumber = (name: string, value: number): void => {
  localStorage.setItem(name, String(value));
};

export const removeLocalNumber = (name: string): void => {
  localStorage.removeItem(name);
};

export const getCurrentSkuCategoryId = (): string => {
  return getLocalString(CURRENT_SKU_CATEGORY_ID, '');
};

export const saveCurrentSkuCategoryId = (skuCategoryId: string): void => {
  localStorage.setItem(CURRENT_SKU_CATEGORY_ID, skuCategoryId);
};

export const getCodeFilterMode = (): string | undefined => {
  let result: string | undefined = getLocalString(CODE_FILTER_MODE, '');

  if (result === '') {
    result = undefined;
  }

  return result;
};

export const saveCodeFilterMode = (mode: string | undefined): void => {
  let m = mode;

  if (!m) {
    m = '';
  }

  localStorage.setItem(CODE_FILTER_MODE, m);
};

export const getCodeFilterValues = (): string | undefined => {
  let result: string | undefined = getLocalString(CODE_FILTER_VALUES, '');

  if (result === '') {
    result = undefined;
  }

  return result;
};

export const saveCodeFilterValues = (values: string | undefined): void => {
  let v = values;

  if (!v) {
    v = '';
  }

  localStorage.setItem(CODE_FILTER_VALUES, v);
};

export const clearCodeFilter = (): void => {
  saveCodeFilterMode(undefined);
  saveCodeFilterValues(undefined);
};

export const saveSkuCategoryBrand = (skuCategoryId: string, brand: string): void => {
  localStorage.setItem(SKU_CATEGORY_BRAND as string + skuCategoryId, brand);
};

export const getSkuCategoryBrand = (skuCategoryId: string): string => {
  return getLocalString(SKU_CATEGORY_BRAND as string + skuCategoryId, '');
};

export const getUser = (): LoginData | null => {
  const storageUser = localStorage.getItem(USER);
  const user = storageUser ? JSON.parse(storageUser) as LoginData : null;

  if (!user) {
    return null;
  } else {
    return user;
  }
};

export const setUser = (response: LoginResponse): void => {
  const user: LoginData = { date: todayToString(), id: response.id };

  localStorage.setItem(USER, JSON.stringify(user));
};

export const logout = (): void => {
  const user = getUser();

  if (user) {
    localStorage.setItem(USER_ID, user.id);
  }

  localStorage.removeItem(USER);
  window.location.replace('/#/login');
};

export const setPlanogramFilter = (filter: PlanogramFilter): void => {
  localStorage.setItem(PLANOGRAM_FILTER, JSON.stringify(filter));
};

export const getPlanogramFilter = (): PlanogramFilter | null => {
  const storageFilter = localStorage.getItem(PLANOGRAM_FILTER);
  const filter = storageFilter ? JSON.parse(storageFilter) as PlanogramFilter : null;

  if (!filter) {
    return null;
  } else {
    return filter;
  }
};

export const removePlanogramFilter = (): void => {
  localStorage.removeItem(PLANOGRAM_FILTER);
};
