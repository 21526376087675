// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import SkuCategoryFilter from 'components/filters/SkuCategoryFilter';
import { getCurrentSkuCategoryId } from 'common/localVars';

export interface AddSkuFormProps {
  form: FormInstance;
  saveCurrentSkuCategoryKey: (skuCategory: string) => void;
}

const SkusTitle: React.FC<AddSkuFormProps> = (props) => {
  const { t } = useTranslation();
  const { form, saveCurrentSkuCategoryKey } = props;
  const { Title } = Typography;

  return (
    <div>
      <Title level={3}>{t('skus')}</Title>
      <SkuCategoryFilter
        allowClear={false}
        form={form}
        removeSkuCategory={() => { console.log(''); }}
        saveSkuCategory={saveCurrentSkuCategoryKey}
        skuCategory={getCurrentSkuCategoryId()} />
    </div>
  );
};

export default memo(SkusTitle);
