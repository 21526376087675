// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import TextFilter from '../TextFilter';

export interface CommentFiltersProps {
  allowClear: boolean;
  comment: string | undefined;
  form: FormInstance;
  saveComment: (comment: string) => void;
}

const CommentFilter: React.FC<CommentFiltersProps> = (props) => {
  const { t } = useTranslation();
  const { allowClear, comment, form, saveComment } = props;

  return (
    <div style={{ marginBottom: 30, marginTop: 10 }}>
      <Form
        form={form}
        layout='inline'
        name='comment_filter_form'
        size={'small'}
      >
        <Form.Item
          label={t('comment')}
          name='comment'
        >
          <TextFilter allowClear={allowClear}
            defaultValue={comment}
            onSearch={saveComment}
            placeholder={t('comment')}/>
        </Form.Item>
      </Form>
    </div>
  );
};

export default memo(CommentFilter);
