// Copyright 2022 @po-polochkam authors & contributors

export const VERSION = '100.1';

export const USER = 'plangram-user';
export const USER_ID = 'plangram-user-id';
export const CURRENT_PATH = 'currentPath';
export const CURRENT_PLANOGRAM = 'currentPlanogram';
export const CURRENT_PLANOGRAM_PAGE = 'currentPlanogramPage';
export const CURRENT_SKU_CATEGORY_ID = 'currentSkuCategoryId';
export const SKU_CATEGORY_BRAND = 'skuCategoryBrand';
export const FIRST_PLANOGRAM_ENTER = 'firstPlanogramEnter';
export const SKU_ROTATION = 'skuRotation';

export const PLANOGRAM_FILTER = 'plangram-filter';

export const CODE_FILTER_MODE = 'codeFilterMode';
export const CODE_FILTER_FULL = 'codeFilterFull';
export const CODE_FILTER_START = 'codeFilterStart';
export const CODE_FILTER_END = 'codeFilterEnd';
export const CODE_FILTER_VALUES = 'codeFilterValues';

export const LIST_MODE_VIEW = 0;
export const LIST_MODE_ADD = 1;
export const LIST_MODE_UPDATE = 2;
export const LIST_MODE_COPY = 3;

export const MAX_PAGE_SIZE = 1000;
export const PLANOGRAM_TABS_WIDTH = '1200px';
export const PLANOGRAM_TABS_NONE = 'none';
export const PLANOGRAM_TABS_RACK = 'rack';
export const PLANOGRAM_TABS_SKUS = 'skus';
export const PLANOGRAM_TABS_CONTENT = 'content';
export const PLANOGRAM_TABS_COMMENT = 'comment';
export const PLANOGRAM_TABS_FRAMES = 'frames';
export const PLANOGRAM_TABS_PHOTO = 'photo';
export const PLANOGRAM_TABS_LINKED = 'linked';

export const PLANOGRAM_COMMENT_BACKGROUND_COLOR = 'lemonchiffon';
export const PLANOGRAM_COMMENT_COLOR = 'black';
export const PLANOGRAM_COMMENT_NONE = 0;
export const PLANOGRAM_COMMENT_TOP = 1;
export const PLANOGRAM_COMMENT_BOTTOM = 2;
export const MAX_PLANOGRAM_COMMENT_HEIGHT = 400;
export const DEFAULT_PLANOGRAM_COMMENT_HEIGHT = 100;
export const DEFAULT_PLANOGRAM_COMMENT_BOLD = false;
export const DEFAULT_PLANOGRAM_COMMENT_FONT_SIZE = 12;
export const DEFAULT_PLANOGRAM_COMMENT_PADDING = 2;
export const MAX_PLANOGRAM_COMMENT_FONT_SIZE = 20;
export const MIN_PLANOGRAM_COMMENT_FONT_SIZE = 10;
export const MAX_PLANOGRAM_COMMENT_PADDING = 20;
export const MIN_PLANOGRAM_COMMENT_PADDING = 0;
export const MAX_PLANOGRAM_PHOTO_HEIGHT = 10000;
export const MAX_PLANOGRAM_PHOTO_WIDTH = 10000;
export const MIN_PLANOGRAM_PHOTO_HEIGHT = 1000;
export const MIN_PLANOGRAM_PHOTO_WIDTH = 1000;
export const DEFAULT_PLANOGRAM_PHOTO_HEIGHT = 2000;
export const DEFAULT_PLANOGRAM_PHOTO_WIDTH = 1000;
export const DEFAULT_PLANOGRAM_PHOTO_QUALITY = 80;
export const LINE_HEIGHT_COEFF = 1.45;

export const PLANOGRAM_COPY_ALL = 0;
export const PLANOGRAM_COPY_RACK_ONLY = 1;

export const PATH_PLANOGRAMS = '/planograms';
export const PATH_PLANOGRAM_VIEW = '/planogram';
export const PATH_SKUS = '/skus';
export const PATH_SKU_CATEGORIES = '/sku-categories';
export const PATH_LOGIN = '/login';
export const PATH_HOME = '/home';
export const DEFAULT_PATH = PATH_PLANOGRAMS;

export const TABLE_TITLE_COLOR = '#1c1b21';
export const DELETE_TWO_TONE_COLOR = '#eb2f96';

export const MAX_SKU_HEIGHT = 1000;
export const MIN_SKU_HEIGHT = 15;
export const MAX_SKU_WIDTH = 1000;
export const MIN_SKU_WIDTH = 15;

export const MAX_SKUS_IN_HEIGHT = 10;
export const MIN_SKUS_IN_HEIGHT = 1;
export const MAX_SKUS_IN_WIDTH = 10;
export const MIN_SKUS_IN_WIDTH = 1;

export const LIST_SKU_IMAGE_HEIGHT = 40;
export const LIST_SKU_IMAGE_WIDTH = 40;

export const OTHER_SKU_INITIAL_HEIGHT = 200;
export const OTHER_SKU_INITIAL_WIDTH = 200;
export const OTHER_SKU_ID = 'other';
export const OTHER_SKU_BACKGROUND_COLOR = 'lightgray';
export const OTHER_SKU_COLOR = 'black';
export const OTHER_SKU_FONT_SIZE = 12;
export const OTHER_SKU_PADDING = 2;

export const RACK_MAX_WIDTH_PX = 800;
export const RACK_DEFAULT_HEIGHT = 2200;
export const RACK_DEFAULT_SHELF_HEIGHT = 240;
export const RACK_DEFAULT_WIDTH = 1000;
export const RACK_MAX_WIDTH = 10000;
export const RACK_MIN_WIDTH = 100;
export const RACK_MIN_HEIGHT = 200;
export const RACK_MAX_HEIGHT = 5000;
export const RACK_SHELF_MIN_HEIGHT = 20;
export const RACK_DEFAULT_NUM_OF_SHELVES = 5;
export const RACK_DEFAULT_SHELF_THICKNESS = 20;
export const RACK_MIN_SHELF_THICKNESS = 5;
export const RACK_MAX_SHELF_THICKNESS = 100;
export const RACK_DEFAULT_SHELF_THICKNESS_PX = 10;
export const RACK_MAX_NUM_OF_SHELVES = 20;
export const RACK_MIN_NUM_OF_SHELVES = 1;
export const RACK_DEFAULT_SHELVES_ARRAY = [1, 2, 3, 4, 5];
export const RACK_SHELF_MIN_INDEX = 10000;
export const RACK_ROW_HEIGHT = 1;
export const RACK_SHELF_HEIGHT_PX = 10;
export const RACK_SHELF_BACKGROUND_COLOR = 'gray';
export const RACK_SHELF_NUMBER_COLOR = 'black';
export const RACK_SHELF_NUMBER_BACKGROUND_COLOR = 'white';
export const RACK_SHELF_NUMBER_BORDER_COLOR = 'red';
export const RACK_SHELF_NUMBER_BORDER_RADIUS = 18;
export const RACK_SHELF_NUMBER_FONT = 'bold 30px arial';

export const RACK_MODE_SAVE = 0;
export const RACK_MODE_FILL_RACK = 1;

export const FRAME_LINE_VERT = 0;
export const FRAME_LINE_HOR = 1;

export const RACK_FRAME_LINE_WIDTH = 3;
export const RACK_FRAME_LINE_COLOR = 'lightgreen';
export const RACK_FRAME_LINE_SELECTED_COLOR = 'orchid';
