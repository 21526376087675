// Copyright 2021 @po-polochkam authors & contributors

import { useCallback, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Sku } from 'database/entities/sku';
import useList from './useList';
import { CODE_FILTER_END, CODE_FILTER_FULL, CODE_FILTER_START, CURRENT_SKU_CATEGORY_ID, LIST_SKU_IMAGE_HEIGHT, LIST_SKU_IMAGE_WIDTH, SKU_ROTATION } from 'common/constants';
import { getCodeFilterMode, getCodeFilterValues, getCurrentSkuCategoryId, getLocalNumber, getSkuCategoryBrand, removeLocalNumber, saveSkuCategoryBrand, setLocalNumber } from 'common/localVars';
import { DimensionsInteface } from 'common/intefaces';
import { getBrandSkus, getCategoryBrands, getCategorySkus } from 'api/dictionary';
import { ServerSku } from 'api/abstractions/skus';
import get from 'lodash/get';
import { Brand } from 'database/entities/brand';
import { SkuExt } from 'components/PlanogramBuilder/PlanogramSkus';

export interface SkusProps {
  skuCategoryId: string;
}

export interface SkuBlock {
  rotation: number,
  shelfNumber: number,
  skusInHeight: number,
  skusInWidth: number,
}

interface UseSkusInterface {
  brands: Brand[];
  currentBrand: string;
  editForm: FormInstance;
  filterForm: FormInstance;
  getSkuRotation: (code: string) => number;
  imageDimensions: (width: number, height: number) => DimensionsInteface;
  isFetching: boolean;
  listMode: number;
  refresh: () => void;
  refreshBrandSkus: () => void;
  refreshCategorySkus: () => void;
  refreshData: boolean;
  saveCurrentSkuCategoryId: (skuCategory: string) => void;
  saving: boolean;
  setCurrentBrand: (brand: string) => void;
  setFilterName: (name: string) => void;
  setListMode: (listMode: number) => void;
  setRefreshData: (refreshData: boolean) => void;
  setSaving: (saving: boolean) => void;
  setSkuRotation: (code: string, rotation: number) => void;
  skus: Sku[];
  skusFiltered: SkuExt[];
}

export function useSkus (): UseSkusInterface {
  const { editForm, filterForm, isFetching, listMode, refreshData, saving, setListMode, setRefreshData, setSaving } = useList();
  const [skus, setSkus] = useState<Sku[]>([]);
  const [categoryBrands, setCategoryBrands] = useState<Brand[]>([]);
  const [currentBrand, setCurrentBrand] = useState('');
  const [currentBrandCategoryId, setCurrentBrandCategoryId] = useState('');
  const [skusAll, setSkusAll] = useState<SkuExt[]>([]);
  const [skusFiltered, setSkusFiltered] = useState<SkuExt[]>([]);
  const [filterName, setFilterName] = useState('');

  const getSkuRotation = useCallback((code: string): number => {
    return getLocalNumber(SKU_ROTATION + code, 0);
  }, []);

  const setSkuRotation = useCallback((code: string, rotation: number) => {
    if (rotation === 0) {
      removeLocalNumber(SKU_ROTATION + code);
    } else {
      setLocalNumber(SKU_ROTATION + code, rotation);
    }
  }, []);

  // Category Skus

  const refresh = useCallback(async () => {
    const currentSkuCategoryId = getCurrentSkuCategoryId();
    const skus: Sku[] = [];

    let page = 1;
    let finish = false;

    if (currentSkuCategoryId) {
      do {
        const data: { error?: any } = await getCategorySkus(currentSkuCategoryId, page);

        if (data.error) {
          finish = true;
        } else {
          const count = get(data, ['count'], 0);
          const items = get(data, ['items'], []) as Array<ServerSku>;

          items.forEach((ss: ServerSku) => {
            skus.push({ brand: ss.brand,
              code: ss.ean,
              height: ss.size.height,
              id: ss.id,
              name: ss.name,
              photo: ss.imageUrl,
              skuCategoryId: currentSkuCategoryId,
              width: ss.size.width });
          });

          finish = skus.length >= count;

          if (!finish) { page += 1; }
        }
      } while (!finish);

      skus.sort((a: Sku, b: Sku) => (a.name > b.name) ? 1 : -1);
    }

    setSkus(skus);
  }, []);

  const refreshCategorySkus = useCallback(() => {
    if (refreshData) {
      setRefreshData(false);
      void refresh();
    }
  }, [refresh, refreshData, setRefreshData]);

  const saveCurrentSkuCategoryId = useCallback((skuCategoryId: string) => {
    localStorage.setItem(CURRENT_SKU_CATEGORY_ID, skuCategoryId);
    setRefreshData(true);
  }, [setRefreshData]);

  // Brand Skus

  const doRefreshBrandSkus = useCallback(async () => {
    const skuCategoryId = getCurrentSkuCategoryId();

    console.log('skuCategoryId', skuCategoryId);
    console.log('currentBrandCategoryId', currentBrandCategoryId);

    const brands: Brand[] = [];

    if (currentBrandCategoryId !== skuCategoryId) {
      let page = 1;
      let finish = false;

      do {
        const data: { error?: any } = await getCategoryBrands(skuCategoryId, page);

        if (data.error) {
          finish = true;
        } else {
          const count = get(data, ['count'], 0);
          const items = get(data, ['items'], []) as string[];

          items.forEach((b: string) => {
            brands.push({ id: '0', name: b });
          });

          finish = brands.length >= count;

          if (!finish) { page += 1; }
        }
      } while (!finish);

      brands.sort((a: Brand, b: Brand) => (a.name > b.name) ? 1 : -1);
      let i = 0;

      brands.forEach(() => {
        brands[i].id = i.toString();
        i += 1;
      });

      setCurrentBrandCategoryId(skuCategoryId);
      setCategoryBrands(brands);
    } else {
      categoryBrands.forEach((b) => { brands.push(b); });
    }

    console.log('brands', brands);

    let brand = getSkuCategoryBrand(skuCategoryId);

    console.log('saved brand', brand);

    if ((brand === '') || (!brands.find((b) => b.name === brand))) {
      brand = (brands.length > 0) ? brands[0].name : '';
    }

    console.log('new brand', brand);
    console.log('currentbrand', currentBrand);

    const allSkus: SkuExt[] = [];

    if (currentBrand !== brand) {
      let page = 1;
      let finish = false;

      let i = 0;

      do {
        const data: { error?: any } = await getBrandSkus(skuCategoryId, brand, page);

        if (data.error) {
          finish = true;
        } else {
          const count = get(data, ['count'], 0);
          const items = get(data, ['items'], []) as Array<ServerSku>;

          items.forEach((ss: ServerSku) => {
            allSkus.push({ brand: ss.brand,
              code: ss.ean,
              height: ss.size.height,
              id: ss.id,
              index: i,
              name: ss.name,
              photo: ss.imageUrl,
              rotation: getSkuRotation(ss.ean),
              shelfNumber: 1,
              skuCategoryId: skuCategoryId,
              skusInHeight: 1,
              skusInWidth: 1,
              width: ss.size.width });

            i += 1;
          });

          finish = allSkus.length >= count;

          if (!finish) { page += 1; }
        }
      } while (!finish);

      allSkus.sort((a: Sku, b: Sku) => (a.name > b.name) ? 1 : -1);

      setCurrentBrand(brand);
      setSkusAll(allSkus);

      console.log('skus', allSkus);
      console.log('skusAll', skusAll);
    } else {
      skusAll.forEach((sku) => {
        allSkus.push(sku);
      });
    }

    const filterCodeMode = getCodeFilterMode();
    const filterCodeValues = getCodeFilterValues();

    const codes: string[] = [];

    if (filterCodeMode && filterCodeValues) {
      filterCodeValues.split(',').forEach((s) => {
        codes.push(s.trim());
      });
    }

    const filteredSkus: SkuExt[] = [];

    allSkus.forEach((sku) => {
      const skuFiltered = sku;

      let needAdd = true;

      if (codes.length > 0) {
        for (let i = 0; i < codes.length; i++) {
          switch (filterCodeMode) {
            case CODE_FILTER_FULL: {
              needAdd = codes[i] === skuFiltered.code;
              break;
            }

            case CODE_FILTER_START: {
              needAdd = skuFiltered.code.startsWith(codes[i]);
              break;
            }

            case CODE_FILTER_END: {
              needAdd = skuFiltered.code.endsWith(codes[i]);
              break;
            }
          }

          if (needAdd) {
            break;
          }
        }
      }

      if ((needAdd) && (filterName !== '')) {
        needAdd = skuFiltered.name.toUpperCase().startsWith(filterName);
      }

      if (needAdd) {
        skuFiltered.index = filteredSkus.length;
        filteredSkus.push(skuFiltered);
      }
    });

    setSkusFiltered(filteredSkus);
    console.log('save brand 2', brand);
    saveSkuCategoryBrand(skuCategoryId, brand);
    setCurrentBrand(brand);

    console.log('skus filtered', filteredSkus);
  }, [categoryBrands, currentBrand, currentBrandCategoryId, filterName, getSkuRotation, skusAll]);

  const refreshBrandSkus = useCallback(() => {
    if (refreshData) {
      setRefreshData(false);
      void doRefreshBrandSkus();
      console.log('refreshBrandSkus');
    }
  }, [doRefreshBrandSkus, refreshData, setRefreshData]);

  const imageDimensions = useCallback((width: number, height: number): DimensionsInteface => {
    if ((height > 0) && (width > 0)) {
      const kH = LIST_SKU_IMAGE_HEIGHT / height;
      const kW = LIST_SKU_IMAGE_WIDTH / width;

      if (kH < kW) {
        return { height: LIST_SKU_IMAGE_HEIGHT, width: (LIST_SKU_IMAGE_HEIGHT / height) * width };
      } else {
        return { height: (LIST_SKU_IMAGE_WIDTH / width) * height, width: LIST_SKU_IMAGE_WIDTH };
      }
    } else {
      return { height: LIST_SKU_IMAGE_HEIGHT, width: LIST_SKU_IMAGE_WIDTH };
    }
  }, []);

  return {
    brands: categoryBrands,
    currentBrand,
    editForm,
    filterForm,
    getSkuRotation,
    imageDimensions,
    isFetching,
    listMode,
    refresh,
    refreshBrandSkus,
    refreshCategorySkus,
    refreshData,
    saveCurrentSkuCategoryId,
    saving,
    setCurrentBrand,
    setFilterName,
    setListMode,
    setRefreshData,
    setSaving,
    setSkuRotation,
    skus,
    skusFiltered
  };
}

export default useSkus;
