// Copyright 2021 @po-polochkam authors & contributors

import request from './request';

export const getCategories = async (page?: number) => {
  const data = await request({
    url: '/planogram/v1/product-categories',
    method: 'GET',
    params: {
      page
    }
  });

  return data.data;
};

export const getCategorySkus = async (category?: string, page?: number) => {
  const data = await request({
    url: '/planogram/v1/skus',
    method: 'GET',
    params: {
      page,
      'filters[category]': category,
    }
  });

  return data.data;
};

export const getCategoryBrands = async (category?: string, page?: number) => {
  const data = await request({
    url: '/planogram/v1/brands',
    method: 'GET',
    params: {
      page,
      'filters[category]': category,
    }
  });

  return data.data;
};

export const getBrandSkus = async (category?: string, brand?: string, page?: number) => {
  const data = await request({
    url: '/planogram/v1/skus',
    method: 'GET',
    params: {
      page,
      'filters[category]': category,
      'filters[brand]': brand,
    }
  });

  return data.data;
};
