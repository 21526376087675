// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import useEdit from 'hooks/useEdit';
import { FormInstance } from 'antd/lib/form';
import { MAX_SKU_HEIGHT, MIN_SKU_HEIGHT, MIN_SKU_WIDTH } from 'common/constants';

export interface EditOtherSkuCommentProps {
  enabled?: boolean;
  form: FormInstance;
  visible: boolean;
  editMode: number;
  doSave: () => Promise<string>;
  onCancel: () => void;
  newTitle: string;
  editTitle: string;
  maxWidth: number;
}

const EditOtherSkuCommentForm: React.FC<EditOtherSkuCommentProps> = (props: EditOtherSkuCommentProps) => {
  const { t } = useTranslation();
  const { form, maxWidth, onCancel, visible } = props;
  const { onSave, title } = useEdit(props);

  return (
    <Modal
      cancelText={t('cancel')}
      okText={t('save')}
      onCancel={onCancel}
      onOk={onSave}
      title={title}
      visible={visible}
    >
      <Form
        form={form}
        labelCol={{
          span: 6
        }}
        layout='horizontal'
        name='edit_other_sku_comment_form'
        wrapperCol={{
          span: 18
        }}
      >
        <Form.Item
          label={t('comment')}
          name='name'
        >
          <Input.TextArea allowClear={true}
            autoSize={true}
          />
        </Form.Item>
        <Form.Item
          label={t('widthMM')}
          name='width'
          rules={[
            {
              max: maxWidth,
              message: `${t('widthMustBe')} ${MIN_SKU_WIDTH} ${t('to')} ${maxWidth}`,
              min: MIN_SKU_WIDTH,
              required: true,
              type: 'integer'
            }
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label={t('heightMM')}
          name='height'
          rules={[
            {
              max: MAX_SKU_HEIGHT,
              message: `${t('heightMustBe')} ${MIN_SKU_HEIGHT} ${t('to')} ${MAX_SKU_HEIGHT}`,
              min: MIN_SKU_HEIGHT,
              required: true,
              type: 'integer'
            }
          ]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(EditOtherSkuCommentForm);
