// Copyright 2021 @po-polochkam authors & contributors

import { useCallback, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Planogram } from 'database/entities/planogram';
import useList from './useList';
import { CURRENT_PLANOGRAM_PAGE, FIRST_PLANOGRAM_ENTER } from 'common/constants';
import { getLocalNumber, getPlanogramFilter, removeLocalNumber, removePlanogramFilter, setLocalNumber, setPlanogramFilter } from 'common/localVars';
import { DDdMMdYYToYYYYMMDD } from 'common/datetimes';
import usePlanogramData from 'database/hooks/usePlanogramData';
import { PlanogramFilter } from 'common/intefaces';
import { QueryDocumentSnapshot } from '@firebase/firestore-types';
import { useEditPlanogram } from './useEditPlanogram';

interface UsePlanogramsInterface {
  add: () => void;
  copy: (planogram: Planogram) => void;
  doCopy: () => Promise<string>;
  doSave: () => Promise<string>;
  copyForm: FormInstance;
  editForm: FormInstance;
  filterForm: FormInstance;
  getCurrentPage: () => number;
  getCurrentPlanogram: () => Planogram | null;
  getFilter: () => PlanogramFilter;
  getFirstEnter: () => boolean;
  isFetching: boolean;
  listMode: number
  readPlanogram: (id: string) => Promise<Planogram | undefined>,
  refreshList: () => void;
  removeFilter: () => void;
  removeFilterSkuCategory: () => void;
  removeFilterState: () => void;
  removePlanogram: (planogram: Planogram) => void;
  saveCurrentPage: (page: number) => void;
  saveCurrentPlanogram: (planogram: Planogram) => void;
  saveFilterComment: (comment: string | undefined) => void;
  saveFilterDates: (dates: [string, string]) => void;
  saveFilterSkuCategory: (skuCategoryId: string | undefined) => void;
  saveFilterState: (state: number | undefined) => void;
  saveFirstEnter: () => void;
  setListMode: (listMode: number) => void;
  setRefreshData: (refreshData: boolean) => void;
  planograms: Planogram[];
  update: (listMode: number, record: Planogram) => void;
}

export function usePlanograms (): UsePlanogramsInterface {
  const { editForm, filterForm, isFetching, listMode, refreshData, setListMode, setRefreshData, setSaving } = useList();
  const [planograms, setPlanograms] = useState<Planogram[]>([]);
  const { addPlanogram, deletePlanogram, getAllPlanogram, getPlanogram, updatePlanogram } = usePlanogramData();
  const parent: string | null = null;

  const readPlanogram = useCallback(async (id: string): Promise<Planogram | undefined> => {
    return getPlanogram(id);
  }, [getPlanogram]);

  const { add, copy, copyForm, doCopy, doSave, getCurrentPlanogram, removePlanogram, saveCurrentPlanogram, update } = useEditPlanogram(
    { addPlanogram,
      deletePlanogram,
      editForm,
      listMode,
      parent,
      setListMode,
      setRefreshData,
      setSaving,
      updatePlanogram });

  const saveCurrentPage = useCallback((page: number) => {
    setLocalNumber(CURRENT_PLANOGRAM_PAGE, page);
  }, []);

  const getCurrentPage = useCallback((): number => {
    return getLocalNumber(CURRENT_PLANOGRAM_PAGE, 1);
  }, []);

  const saveFirstEnter = useCallback(() => {
    setLocalNumber(FIRST_PLANOGRAM_ENTER, 1);
  }, []);

  const getFirstEnter = useCallback((): boolean => {
    const result = getLocalNumber(FIRST_PLANOGRAM_ENTER, 0) === 1;

    removeLocalNumber(FIRST_PLANOGRAM_ENTER);

    return result;
  }, []);

  const savePlanogramFilter = useCallback((filter: PlanogramFilter) => {
    setPlanogramFilter(filter);
    setRefreshData(true);
  }, [setRefreshData]);

  const removeFilter = useCallback(() => {
    removePlanogramFilter();
    setRefreshData(true);
  }, [setRefreshData]);

  const getFilter = useCallback((): PlanogramFilter => {
    let filter = getPlanogramFilter();

    if (!filter) {
      filter = { comment: '', dates: ['', ''], skuCategoryId: undefined, state: undefined };
    }

    return filter;
  }, []);

  const saveFilterComment = useCallback((comment: string | undefined) => {
    const filter = getFilter();

    filter.comment = comment;
    savePlanogramFilter(filter);
  }, [getFilter, savePlanogramFilter]);

  const saveFilterDates = useCallback((dates: [string, string]) => {
    const filter = getFilter();

    filter.dates = dates;
    savePlanogramFilter(filter);
  }, [getFilter, savePlanogramFilter]);

  const saveFilterSkuCategory = useCallback((skuCategoryId: string | undefined) => {
    const filter = getFilter();

    filter.skuCategoryId = skuCategoryId;
    savePlanogramFilter(filter);
  }, [getFilter, savePlanogramFilter]);

  const removeFilterSkuCategory = useCallback(() => {
    saveFilterSkuCategory(undefined);
  }, [saveFilterSkuCategory]);

  const saveFilterState = useCallback((state: number | undefined) => {
    const filter = getFilter();

    filter.state = state;
    savePlanogramFilter(filter);
  }, [getFilter, savePlanogramFilter]);

  const removeFilterState = useCallback(() => {
    saveFilterState(undefined);
  }, [saveFilterState]);

  const refresh = useCallback(async () => {
    let comment: string | undefined;

    const converter = {
      fromFirestore: (snap: QueryDocumentSnapshot) => {
        const p = snap.data() as Planogram;

        return p;
      },

      toFirestore: (data: Planogram) => data
    };

    let p = getAllPlanogram()?.withConverter(converter)
      .orderBy('date', 'desc');
    const filter = getPlanogramFilter();

    if (filter) {
      if ((filter.dates) && (filter.dates.length === 2)) {
        if (filter.dates[0] !== '') {
          const dateFrom = DDdMMdYYToYYYYMMDD(filter.dates[0]);

          p = p?.where('date', '>=', dateFrom);
        }

        if (filter.dates[1] !== '') {
          const dateTo = DDdMMdYYToYYYYMMDD(filter.dates[1]) + 'T24';

          p = p?.where('date', '<=', dateTo);
        }
      }

      if (filter.skuCategoryId) {
        p = p?.where('skuCategoryId', '==', filter.skuCategoryId);
      }

      if (filter.state !== undefined) {
        p = p?.where('state', '==', filter.state);
      }

      if (filter.comment !== undefined) {
        comment = filter.comment.trim().toUpperCase();
      }
    }

    const planograms: Planogram[] = [];
    const snapshot = await p?.get();

    if (snapshot) {
      snapshot.forEach((doc) => {
        const planogram = doc.data();

        let needAdd = true;

        if ((comment) && (comment !== '')) {
          needAdd = planogram.comment.toUpperCase().includes(comment);
        }

        if (needAdd) {
          planograms.push(planogram);
        }
      });
    }

    setPlanograms(planograms);
  }, [getAllPlanogram]);

  const refreshList = useCallback(() => {
    if (refreshData) {
      setRefreshData(false);
      void refresh();
    }
  }, [refresh, refreshData, setRefreshData]);

  return {
    add,
    copy,
    copyForm,
    doCopy,
    doSave,
    editForm,
    filterForm,
    getCurrentPage,
    getCurrentPlanogram,
    getFilter,
    getFirstEnter,
    isFetching,
    listMode,
    planograms,
    readPlanogram,
    refreshList,
    removeFilter,
    removeFilterSkuCategory,
    removeFilterState,
    removePlanogram,
    saveCurrentPage,
    saveCurrentPlanogram,
    saveFilterComment,
    saveFilterDates,
    saveFilterSkuCategory,
    saveFilterState,
    saveFirstEnter,
    setListMode,
    setRefreshData,
    update
  };
}

export default usePlanograms;
