// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Modal, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useEdit, { EditProps } from 'hooks/useEdit';
import { PLANOGRAM_COPY_ALL, PLANOGRAM_COPY_RACK_ONLY } from 'common/constants';

export type CopyPlanogram = {
  variant: number;
}

const CopyPlanogramForm: React.FC<EditProps> = (props: EditProps) => {
  const { t } = useTranslation();
  const { form, onCancel, visible } = props;
  const { onSave, title } = useEdit(props);

  return (
    <Modal
      cancelText={t('cancel')}
      okText={t('save')}
      onCancel={onCancel}
      onOk={onSave}
      title={title}
      visible={visible}
    >
      <Form
        form={form}
        labelCol={{
          span: 6
        }}
        layout='horizontal'
        name='copy_planogram_form'
        wrapperCol={{
          span: 18
        }}
      >
        <Form.Item
          label={t('copy')}
          name='variant'
        >
          <Select defaultValue={PLANOGRAM_COPY_ALL}
            style={{ width: 200 }} >
            <Select.Option value={PLANOGRAM_COPY_ALL}>{t('copyAllPlanogram')}</Select.Option>
            <Select.Option value={PLANOGRAM_COPY_RACK_ONLY}>{t('copyOnlyRack')}</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(CopyPlanogramForm);
