// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const SkuCategoriesTitle: React.FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <div>
      <Title level={3}>{t('skuCategories')}</Title>
    </div>
  );
};

export default memo(SkuCategoriesTitle);
