// Copyright 2021 @po-polochkam authors & contributors

import { default as axiosLib, AxiosInstance } from 'axios';
import { apiPath } from './config';

export type RequestErrorType = {
  config: { url: string, _retry?: number };
  data: { error: { code: number, message: string } }
  status: number;
}

export type RequestPromise = {
  resolve: () => void;
  reject: () => void;
}

const axios: AxiosInstance = axiosLib.create({ baseURL: apiPath });

const request = ({ ...options }) => {
  const onSuccess = (response: any) => {
    return response;
  };
  
  const onError = (error: any) => {
    const {
      config,
      status,
    }: RequestErrorType = error;
    console.log('onError api response', config, status);

    return error;
  };

  return axios(options).then(onSuccess).catch(onError);
};

export default request;
