// Copyright 2021 @po-polochkam authors & contributors

import { CURRENT_PATH, CURRENT_PLANOGRAM, CURRENT_SKU_CATEGORY_ID, USER } from 'common/constants';
import { useCallback } from 'react';

interface UseUserInterface {
  clear: () => void;
}

export function useUser (): UseUserInterface {
  const clear = useCallback(() => {
    localStorage.removeItem(USER);
    localStorage.removeItem(CURRENT_PATH);
    localStorage.removeItem(CURRENT_PLANOGRAM);
    localStorage.removeItem(CURRENT_SKU_CATEGORY_ID);
  }, []);

  return {
    clear
  };
}

export default useUser;
