// Copyright 2021 @po-polochkam authors & contributors

import { useCallback, useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { SkuCategory } from 'database/entities/sku-category';
import useList from './useList';
import { getCurrentSkuCategoryId, saveCurrentSkuCategoryId } from 'common/localVars';
import { ProductCategory } from 'api/abstractions/skus';
import { getCategories } from 'api/dictionary';
import get from 'lodash/get';

interface UseSkuCategoriesInterface {
  currentSkuCategoryId: () => string;
  editForm: FormInstance;
  isFetching: boolean;
  listMode: number;
  refreshList: () => void;
  setListMode: (listMode: number) => void;
  setRefreshData: (refreshData: boolean) => void;
  skuCategories: SkuCategory[];
  skuCategoryName: (id: string) => string;
}

export function useSkuCategories (): UseSkuCategoriesInterface {
  const { editForm, isFetching, listMode, refreshData, setListMode, setRefreshData } = useList();
  const [skuCategories, setSkuCategories] = useState<SkuCategory[]>([]);

  const currentSkuCategoryId = useCallback(() => {
    return getCurrentSkuCategoryId();
  }, []);

  const refresh = useCallback(async () => {
    const skuCategories: SkuCategory[] = [];

    let page = 1;
    let finish = false;

    do {
      const data: { error?: any } = await getCategories(page);

      if (data.error) {
        finish = true;
      } else {
        const count = get(data, ['count'], 0);
        const items = get(data, ['items'], []) as Array<SkuCategory>;

        items.forEach((category: ProductCategory) => {
          skuCategories.push({ id: category.id, name: category.name });
        });
        finish = skuCategories.length >= count;

        if (!finish) { page += 1; }
      }
    } while (!finish);

    skuCategories.sort((a: SkuCategory, b: SkuCategory) => (a.name > b.name) ? 1 : -1);

    const skuCategoryId = currentSkuCategoryId();

    if ((skuCategories.length > 0) && (skuCategoryId === '')) {
      saveCurrentSkuCategoryId(skuCategories[0].id);
    }

    setSkuCategories(skuCategories);
  }, [currentSkuCategoryId]);

  const refreshList = useCallback(() => {
    if (refreshData) {
      setRefreshData(false);
      void refresh();
    }
  }, [refresh, refreshData, setRefreshData]);

  useEffect(() => {
    refreshList();
  });

  const skuCategoryName = useCallback((id: string) => {
    const sc = skuCategories.find((sc) => sc.id === id);

    if (sc) {
      return sc.name;
    } else {
      return '';
    }
  }, [skuCategories]);

  return {
    currentSkuCategoryId: currentSkuCategoryId,
    editForm,
    isFetching,
    listMode,
    refreshList,
    setListMode,
    setRefreshData,
    skuCategories,
    skuCategoryName
  };
}

export default useSkuCategories;
