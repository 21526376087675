// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import usePlanograms from 'hooks/usePlanograms';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { LIST_MODE_ADD, LIST_MODE_COPY, LIST_MODE_UPDATE, LIST_MODE_VIEW, PATH_PLANOGRAM_VIEW } from 'common/constants';
import PlanogramsTitle from './PlanogramsTitle';
import EditPlanogramForm from '../EditPlanogram';
import { useHistory } from 'react-router-dom';
import { usePlanogramList } from 'hooks/usePlanogramList';
import CopyPlanogramForm from 'components/CopyPlanogram';
import { clearCodeFilter } from 'common/localVars';

function Planograms (): ReactElement {
  const { t } = useTranslation();
  const { add,
    copy,
    copyForm,
    doCopy,
    doSave,
    editForm,
    filterForm,
    getCurrentPage,
    getFilter,
    listMode,
    planograms,
    refreshList,
    removeFilter,
    removeFilterSkuCategory,
    removeFilterState,
    removePlanogram,
    saveCurrentPage,
    saveCurrentPlanogram,
    saveFilterComment,
    saveFilterDates,
    saveFilterSkuCategory,
    saveFilterState,
    saveFirstEnter,
    setListMode,
    update } = usePlanograms();
  const { height } = useWindowDimensions();
  const history = useHistory();
  const defaultCurrent = getCurrentPage();

  const pageChange = useCallback((page: number) => {
    saveCurrentPage(page);
  }, [saveCurrentPage]);

  const parent: string | null = null;
  const enabled = true;
  const { columns } = usePlanogramList({ copy, enabled, parent, removePlanogram, update });

  useEffect(() => {
    refreshList();
  });

  return (
    <div>
      <Table
        columns={columns()}
        dataSource={planograms}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              clearCodeFilter();
              saveCurrentPlanogram(record);
              saveFirstEnter();
              history.push(PATH_PLANOGRAM_VIEW);
            }
          };
        }}
        pagination={{ defaultCurrent: defaultCurrent, hideOnSinglePage: true, onChange: pageChange, pageSize: 18 }}
        scroll={{ y: height - 410 }}
        size='small'
        style={{ marginTop: -10, maxWidth: '1500px' }}
        tableLayout='auto'

        title={() => <PlanogramsTitle
          add={add}
          form={filterForm}
          getFilter={getFilter}
          removeFilter={removeFilter}
          removeFilterSkuCategory={removeFilterSkuCategory}
          removeFilterState={removeFilterState}
          saveFilterComment={saveFilterComment}
          saveFilterDates={saveFilterDates}
          saveFilterSkuCategory={saveFilterSkuCategory}
          saveFilterState={saveFilterState}
        />}

      />
      { ((listMode === LIST_MODE_ADD) || (listMode === LIST_MODE_UPDATE)) &&
        <EditPlanogramForm
          doSave={doSave}
          editMode={listMode}
          editTitle={t('editPlangram')}
          form={editForm}
          newTitle={t('newPlangram')}
          onCancel={() => { setListMode(LIST_MODE_VIEW); }}
          visible={true}
        />
      }
      { (listMode === LIST_MODE_COPY) &&
        <CopyPlanogramForm
          doSave={doCopy}
          editMode={LIST_MODE_UPDATE}
          editTitle={t('copyPlanogram')}
          form={copyForm}
          newTitle={t('copyPlanogram')}
          onCancel={() => { setListMode(LIST_MODE_VIEW); }}
          visible={true}
        />
      }
    </div>
  );
}

export default memo(Planograms);
