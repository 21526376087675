// Copyright 2021 @po-polochkam authors & contributors
/* eslint-disable sort-keys */
import { FRAME_LINE_VERT, LINE_HEIGHT_COEFF,
  OTHER_SKU_BACKGROUND_COLOR,
  OTHER_SKU_COLOR,
  OTHER_SKU_FONT_SIZE,
  OTHER_SKU_ID,
  OTHER_SKU_PADDING,
  PLANOGRAM_COMMENT_BACKGROUND_COLOR,
  PLANOGRAM_COMMENT_BOTTOM,
  PLANOGRAM_COMMENT_COLOR,
  PLANOGRAM_COMMENT_NONE,
  PLANOGRAM_COMMENT_TOP,
  RACK_FRAME_LINE_COLOR,
  RACK_FRAME_LINE_WIDTH,
  RACK_SHELF_BACKGROUND_COLOR,
  RACK_SHELF_NUMBER_BACKGROUND_COLOR,
  RACK_SHELF_NUMBER_BORDER_COLOR,
  RACK_SHELF_NUMBER_BORDER_RADIUS,
  RACK_SHELF_NUMBER_COLOR,
  RACK_SHELF_NUMBER_FONT } from 'common/constants';
import { round } from 'common/utils';
import { Planogram, PlanogramVersion, PlanogramExp } from 'database/entities/planogram';
import { floor } from 'lodash';
import { RefObject, useCallback, useRef, useState } from 'react';
import usePlanogramBuilder from './usePlanogramBuilder';
import useWindowDimensions from './useWindowDimensions';

interface UsePlanogramPhotoProps {
  planogram: Planogram;
}

interface UsePlanogramPhotoInterface {
  canvasHeight: number;
  canvasRef: RefObject<HTMLCanvasElement>;
  canvasWidth: number;
  height: number;
  makeCanvas: () => void;
  photoScale: number;
  preview: () => void;
  previewRef: string | null;
  saveJPG: () => void;
  saveJSON: () => void;
  width: number;
}

export function usePlanogramPhoto (props: UsePlanogramPhotoProps): UsePlanogramPhotoInterface {
  const { planogram } = props;
  const [previewRef, setPreviewRef] = useState<string | null>(null);
  const { height: windowHeight } = useWindowDimensions();
  const { rotate } = usePlanogramBuilder();
  const height = windowHeight - 150;
  const photoScale = planogram.width / planogram.height;
  const sourceCommentHeight = (planogram.commentPlace === PLANOGRAM_COMMENT_NONE ? 0 : planogram.commentHeight);
  const width = round((height - sourceCommentHeight) * photoScale);
  const canvasHeight = planogram.photoHeight;
  const canvasScale = canvasHeight / height;
  const commentHeight = round(sourceCommentHeight * canvasScale);
  const rackHeight = canvasHeight - commentHeight;
  const canvasWidth = round(rackHeight * photoScale);
  const rackScale = rackHeight / planogram.height;
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const saveJPG = useCallback(() => {
    const canvas = document.getElementById('photo') as HTMLCanvasElement;

    canvas.toBlob((blob) => {
      if (blob) {
        const link = document.createElement('a');

        link.download = `${planogram.id}.jpg`;
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    'image/jpeg', planogram.photoQuality / 100);
  }, [planogram]);

  const saveJSON = useCallback(() => {
    const element = document.createElement('a');

    // eslint-disable-next-line sort-keys
    const p: PlanogramExp = {
      version: PlanogramVersion,
      id: planogram.id,
      date: planogram.date,
      numOfShelves: planogram.numOfShelves,
      skuCategoryId: planogram.skuCategoryId,
      state: planogram.state,
      comment: planogram.comment,
      width: planogram.width,
      height: planogram.height,
      workComment: planogram.workComment,
      commentPlace: planogram.commentPlace,
      commentHeight: planogram.commentHeight,
      commentFontSize: planogram.commentFontSize,
      commentBold: planogram.commentBold,
      commentPadding: planogram.commentPadding,
      photoWidth: planogram.photoWidth,
      photoHeight: planogram.photoHeight,
      photoQuality: planogram.photoQuality,
      shelves: [],
      skus: [],
      frames: [],
      parent: planogram.parent
    };

    for (let i = 0; i <= planogram.numOfShelves - 1; i++) {
      p.shelves.push({ height: planogram.shelves[i].height, thickness: planogram.shelves[i].thiсkness });
    }

    if (planogram.skus) {
      for (let i = 0; i <= planogram.skus.length - 1; i++) {
        const sku = rotate(planogram.skus[i]);

        p.skus.push(sku);
      }
    }

    if (planogram.lines) {
      const vert: number[] = [];
      const hor: number[] = [];

      for (let i = 0; i <= planogram.lines.length - 1; i++) {
        const line = (planogram.lines[i]);

        if (line.orientation === FRAME_LINE_VERT) {
          vert.push(line.shift);
        } else { hor.push(line.shift); }
      }

      if ((vert.length > 0) || (hor.length > 0)) {
        vert.push(0);
        vert.push(planogram.width);
        vert.sort((a: number, b: number): number => { return a - b; });
        hor.push(0);
        hor.push(planogram.height);
        hor.sort((a: number, b: number): number => { return a - b; });

        for (let i = 0; i <= hor.length - 2; i++) {
          for (let j = 0; j <= vert.length - 2; j++) {
            p.frames.push({ left: vert[j], top: hor[i], width: vert[j + 1] - vert[j], height: hor[i + 1] - hor[i] });
          }
        }
      }
    }

    planogram.version = PlanogramVersion;
    const textFile = new Blob([JSON.stringify(p)], { type: 'text/plain' });

    element.href = URL.createObjectURL(textFile);
    element.download = `${planogram.id}.json`;
    document.body.appendChild(element);
    element.click();
  }, [planogram, rotate]);

  const preview = useCallback(() => {
    const canvas = document.getElementById('photo') as HTMLCanvasElement;

    canvas.toBlob((blob) => {
      if (blob) {
        if (previewRef) {
          URL.revokeObjectURL(previewRef);
        }

        const link = document.createElement('a');

        link.download = `${planogram.id}.jpg`;
        setPreviewRef(URL.createObjectURL(blob));
      }
    },
    'image/jpeg', planogram.photoQuality / 100);
  }, [planogram.id, planogram.photoQuality, previewRef]);

  const printAt = useCallback((c: CanvasRenderingContext2D, text: string, x: number, y: number, lineHeight: number, fitWidth: number, font: string, currLine: number, maxLines: number) => {
    fitWidth = fitWidth || 0;

    if (fitWidth <= 0) {
      c.fillText(text, x, y);

      return;
    }

    c.font = font;
    let last = 1;

    for (let idx = 1; idx <= text.length; idx++) {
      const str = text.substr(0, idx);
      const newLine = text.charCodeAt(idx) === 10;

      if ((c.measureText(str).width >= fitWidth) || (newLine)) {
        currLine += 1;

        if (newLine) {
          last = idx;
        }

        if (currLine === maxLines) {
          c.fillText(text.substr(0, last) + '...', x, y);
        } else {
          c.fillText(text.substr(0, last), x, y);
          printAt(c, text.substr(last + 1).trim(), x, y + lineHeight, lineHeight, fitWidth, font, currLine, maxLines);
        }

        return;
      } else {
        if (' .,!?:;-'.includes(text[idx])) {
          last = idx;
        }
      }
    }

    c.fillText(text, x, y);
  }, []);

  const planogramComment = useCallback((c: CanvasRenderingContext2D, currentTop: number) => {
    c.beginPath();
    c.fillStyle = PLANOGRAM_COMMENT_BACKGROUND_COLOR;
    c.rect(0, currentTop, canvasWidth, commentHeight);
    c.fill();
    const padding = round(planogram.commentPadding * canvasScale);
    const fontSize = floor(planogram.commentFontSize * canvasScale);
    const lineHeight = floor(fontSize * LINE_HEIGHT_COEFF);

    c.beginPath();

    let font = `${fontSize}px arial`;

    if (planogram.commentBold) {
      font = 'bold ' + font;
    }

    c.font = font;

    c.fillStyle = PLANOGRAM_COMMENT_COLOR;
    c.textBaseline = 'top';
    printAt(c, planogram.workComment, padding, currentTop + padding, lineHeight, canvasWidth - padding, c.font, 0, floor(commentHeight / lineHeight));
  }, [canvasScale, canvasWidth, commentHeight, planogram, printAt]);

  const makeCanvas = useCallback(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const c = canvas.getContext('2d');

      if (c) {
        let currentTop = 0;

        if (planogram.commentPlace === PLANOGRAM_COMMENT_TOP) {
          planogramComment(c, currentTop);
          currentTop += commentHeight;
        }

        const rackTop = currentTop;

        c.beginPath();
        c.fillStyle = 'white';
        c.rect(0, rackTop, canvasWidth, rackHeight);
        c.fill();

        const padding = round((OTHER_SKU_PADDING + 2) * canvasScale);
        const fontSize = floor(OTHER_SKU_FONT_SIZE * canvasScale);
        const lineHeight = floor(fontSize * LINE_HEIGHT_COEFF);
        const lineWidth = floor(2 * canvasScale);
        const r = RACK_SHELF_NUMBER_BORDER_RADIUS;
        const x = r + lineWidth + 1;

        const fillNumbers = () => {
          currentTop = rackTop;

          for (let i = 0; i < planogram.numOfShelves; i++) {
            currentTop += round(planogram.shelves[i].height * rackScale);
            const thiсkness = round(planogram.shelves[i].thiсkness * rackScale);

            currentTop += thiсkness;

            c.beginPath();
            const y = currentTop - thiсkness - lineWidth;

            c.arc(x, y, r, 0, 2 * Math.PI);
            c.fillStyle = RACK_SHELF_NUMBER_BACKGROUND_COLOR;
            c.fill();
            c.strokeStyle = RACK_SHELF_NUMBER_BORDER_COLOR;
            c.stroke();
            c.font = RACK_SHELF_NUMBER_FONT;
            c.fillStyle = RACK_SHELF_NUMBER_COLOR;
            c.textBaseline = 'middle';
            const gap = i === 9 ? 10 : 8;

            c.fillText((i + 1).toString(), x - gap, y + 2, 20);
          }

          if (planogram.lines) {
            for (let i = 0; i < planogram.lines.length; i++) {
              const l = planogram.lines[i];

              c.beginPath();
              c.fillStyle = RACK_FRAME_LINE_COLOR;
              const shift = round(l.shift * rackScale);
              const thiсkness = round(2 * RACK_FRAME_LINE_WIDTH * rackScale);

              if (l.orientation === FRAME_LINE_VERT) {
                c.rect(shift, rackTop, thiсkness, rackHeight);
              } else {
                c.rect(0, shift, canvasWidth, thiсkness);
              }

              c.fill();
            }
          }
        };

        if (planogram.skus) {
          for (let n = 0; n < planogram.skus.length; n++) {
            const shelfSku = planogram.skus[n];

            const height = round(shelfSku.sku.height * rackScale);
            const width = round(shelfSku.sku.width * rackScale);
            const left = round(shelfSku.left * rackScale);
            const top = currentTop + round(shelfSku.top * rackScale);

            if (shelfSku.sku.id === OTHER_SKU_ID) {
              c.beginPath();
              c.fillStyle = OTHER_SKU_BACKGROUND_COLOR;
              c.rect(round(shelfSku.left * rackScale), currentTop + round(shelfSku.top * rackScale), width, height);
              c.fill();

              c.beginPath();
              c.font = `${fontSize}px Arial`;
              c.fillStyle = OTHER_SKU_COLOR;
              c.textBaseline = 'top';
              printAt(c, shelfSku.sku.name, left + padding, top + padding, lineHeight, width - padding, c.font, 0, floor((height - padding) / lineHeight));
            } else {
              const img = new Image();

              img.setAttribute('crossorigin', 'anonymous');

              img.onload = () => {
                switch (shelfSku.rotation) {
                  case 90: {
                    const l = left + height;
                    const t = top;

                    c.save();
                    c.translate(l, t);
                    c.rotate(90 * Math.PI / 180);
                    c.translate(-l, -t);
                    c.drawImage(img, l, t, width, height);
                    c.restore();
                    break;
                  }

                  case 270: {
                    const l = left;
                    const t = top + width;

                    c.save();
                    c.translate(l, t);
                    c.rotate(-90 * Math.PI / 180);
                    c.translate(-l, -t);
                    c.drawImage(img, l, t, width, height);
                    c.restore();
                    break;
                  }

                  default: {
                    c.drawImage(img, left, top, width, height);
                  }
                }

                fillNumbers();
              };

              img.src = shelfSku.sku.photo;
            }
          }
        }

        currentTop = rackTop;

        for (let i = 0; i < planogram.numOfShelves; i++) {
          currentTop += round(planogram.shelves[i].height * rackScale);
          c.beginPath();
          c.fillStyle = RACK_SHELF_BACKGROUND_COLOR;
          const thiсkness = round(planogram.shelves[i].thiсkness * rackScale);

          c.rect(0, currentTop, canvasWidth, thiсkness);
          c.fill();
          currentTop += thiсkness;
        }

        fillNumbers();

        if (planogram.commentPlace === PLANOGRAM_COMMENT_BOTTOM) {
          planogramComment(c, currentTop);
        }

        c.beginPath();
        c.strokeStyle = 'gray';
        c.lineWidth = lineWidth;
        c.rect(0, rackTop, canvasWidth, rackHeight);
        c.stroke();
      }
    }
  }, [canvasScale, canvasWidth, commentHeight, planogram.commentPlace, planogram.lines, planogram.numOfShelves, planogram.shelves, planogram.skus, planogramComment, printAt, rackHeight, rackScale]);

  return {
    canvasHeight,
    canvasRef,
    canvasWidth,
    height,
    makeCanvas,
    photoScale,
    preview,
    previewRef,
    saveJPG,
    saveJSON,
    width
  };
}

export default usePlanogramPhoto;
