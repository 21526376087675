// Copyright 2021 @po-polochkam authors & contributors
/* eslint-disable sort-keys */

import firebase from 'firebase';
import firestore from '../database/firebase';

export interface FirestoreInterface {
  firestore: firebase.firestore.Firestore | undefined;
}

export default function useFirestore (): FirestoreInterface {
  return {
    firestore
  };
}
