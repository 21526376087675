// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import SidebarMenu from 'components/SidebarMenu';
import Planograms from 'components/Planograms';
import Skus from 'components/Skus';
import SkusCategories from 'components/SkuCategories';
import { CURRENT_PATH,
  PATH_PLANOGRAMS,
  PATH_SKUS,
  PATH_SKU_CATEGORIES,
  PATH_PLANOGRAM_VIEW,
  PATH_LOGIN,
  DEFAULT_PATH } from 'common/constants';
import { getLocalString, getUser } from 'common/localVars';
import PlanogramBuilder from 'components/PlanogramBuilder';
import './styles.less';
import OnBoarding from 'containers/OnBoarding';

const { Content } = Layout;

function App (): ReactElement {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!getUser()) {
      history.push(PATH_LOGIN);
    } else {
      if (location.pathname === '/') {
        history.push(getLocalString(CURRENT_PATH, DEFAULT_PATH));
      } else {
        localStorage.setItem(CURRENT_PATH, location.pathname);
      }
    }
  }, [history, location]);

  const defaultSelectedKeys = useCallback(() => {
    const path = getLocalString(CURRENT_PATH, DEFAULT_PATH);

    return (path === PATH_PLANOGRAM_VIEW) ? PATH_PLANOGRAMS : path;
  }, []);

  return (
    <div className='App'>
      <Layout>
        <SidebarMenu defaultSelectedKeys={defaultSelectedKeys()}/>
        <Layout className='site-layout'>
          <Content
            className='site-layout-background'
            style={{
              margin: '0px 0px',
              minHeight: 280,
              padding: 24
            }}
          >
            <div>
              <Switch>
                <Route component={Planograms}
                  exact
                  path={[PATH_PLANOGRAMS]} />
                <Route component={PlanogramBuilder}
                  exact
                  path={[PATH_PLANOGRAM_VIEW]} />
                <Route component={Skus}
                  exact
                  path={[PATH_SKUS]} />
                <Route component={SkusCategories}
                  exact
                  path={[PATH_SKU_CATEGORIES]} />
                <Route component={OnBoarding}
                  exact
                  path={[PATH_LOGIN]} />
              </Switch>
            </div>
          </Content>
        </Layout>
      </Layout >
    </div >
  );
}

export default memo(App);
