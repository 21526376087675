// Copyright 2020 @po-polochkam authors & contributors

import React, { memo, ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';

import OnBoarding from 'containers/OnBoarding';
import HomePage from 'containers/HomePage';
import './styles.scss';
import { PATH_HOME, PATH_LOGIN } from 'common/constants';

function App (): ReactElement {
  return (
    <div className='App'>
      <Switch>
        <Route
          exact
          key='OnBoarding'
          path={PATH_LOGIN}
        >
          <OnBoarding />
        </Route>
        <Route
          exact
          key='HomePage'
          path={PATH_HOME}
        />
        <HomePage />
      </Switch>
    </div>
  );
}

export default memo(App);
