// Copyright 2021 @po-polochkam authors & contributors

import moment from 'moment';

export const zero = (n: number): string => {
  return `${n < 10 ? `0${n}` : `${n}`}`;
};

export const todayToString = (): string => {
  return moment().format('YYYYMMDDTHHmmss');
};

export const todayToYYYYMMDD = (): string => {
  return moment().format('YYYYMMDD');
};

export const todayToYYMMDD = (days: number): string => {
  return moment().add(days, 'days').format('DD.MM.YY');
};

export const stringDateToView = (d: string): string => {
  return moment(d).format('DD.MM.YY HH:mm:ss');
};

export const DDdMMdYYToYYYYMMDD = (d: string): string => {
  return '20' + d.substr(6, 2) + d.substr(3, 2) + d.substr(0, 2);
};
