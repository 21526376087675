// Copyright 2021 @po-polochkam authors & contributors

import React, { memo, useCallback } from 'react';
import { Space, Form, DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CloseOutlined, DashOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

export interface DateFiltersProps {
  allowClear: boolean;
  dates: [string, string];
  form: FormInstance;
  saveDates: (dates: [string, string]) => void;
}

const DateFilter: React.FC<DateFiltersProps> = (props) => {
  const { t } = useTranslation();
  const { allowClear, dates, form, saveDates } = props;
  const { RangePicker } = DatePicker;
  const dateFormat = 'DD.MM.YY';

  const _moment = useCallback((d: string): Moment | null => {
    return d !== '' ? moment(d, dateFormat) : null;
  }, []);

  return (
    <div>
      <Space style={{ marginBottom: 10, marginTop: 10 }}>
        <Form
          form={form}
          layout='inline'
          name='date_filter_form'
          size={'small'} >
          <Form.Item
            label={t('date')}
            name='dates'
          >
            <RangePicker
              allowClear={allowClear}
              allowEmpty={[true, true]}
              clearIcon={<CloseOutlined />}
              defaultValue={[_moment(dates[0]), _moment(dates[1])]}
              format='DD.MM.YY'
              onChange={(values: [Moment | null, Moment | null] | null, formatString: string[]) => {
                saveDates([formatString[0], formatString[1]]);
              }}
              separator={<DashOutlined />}
              style={{ left: 53, width: 270 }}>
            </RangePicker>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default memo(DateFilter);
