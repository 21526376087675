// Copyright 2021 @po-polochkam authors & contributors

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import reportWebVitals from './reportWebVitals';
import './i18n';
import store from './store/store';
import App from './containers/App';
import './index.css';
import './index.less';
import { QueryClientInterface } from 'common/intefaces';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

const queryClient = new QueryClient() as QueryClientInterface;

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={ruRU}>
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </HashRouter>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
