// Copyright 2021 @po-polochkam authors & contributors
/* eslint-disable sort-keys */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { ItemCallback, Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { Button, Form, Modal, Space, Tabs, Typography } from 'antd';
import { OTHER_SKU_ID,
  OTHER_SKU_FONT_SIZE,
  OTHER_SKU_PADDING,
  PATH_PLANOGRAMS,
  PLANOGRAM_TABS_RACK,
  PLANOGRAM_TABS_CONTENT,
  PLANOGRAM_TABS_LINKED,
  PLANOGRAM_TABS_SKUS,
  PLANOGRAM_TABS_WIDTH,
  RACK_ROW_HEIGHT,
  RACK_SHELF_MIN_INDEX,
  RACK_MODE_SAVE,
  LIST_MODE_UPDATE,
  PLANOGRAM_TABS_COMMENT,
  PLANOGRAM_COMMENT_TOP,
  PLANOGRAM_COMMENT_BOTTOM,
  PLANOGRAM_TABS_PHOTO,
  PLANOGRAM_TABS_NONE,
  PATH_PLANOGRAM_VIEW,
  RACK_FRAME_LINE_WIDTH,
  RACK_FRAME_LINE_COLOR,
  PLANOGRAM_TABS_FRAMES,
  FRAME_LINE_VERT,
  FRAME_LINE_HOR,
  RACK_FRAME_LINE_SELECTED_COLOR } from 'common/constants';
import { stringDateToView } from 'common/datetimes';
import useSkuCategories from 'hooks/useSkuCategories';
import usePlanogramBuilder from 'hooks/usePlanogramBuilder';
import RackForm from './RackForm';
import { Planogram, ShelfSku } from 'database/entities/planogram';
import { DB_PLANOGRAM_STATE_IN_WORK, DB_PLANOGRAM_STATE_NEW, DB_PLANOGRAM_STATE_READY } from 'database/constants';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles.scss';
import PlanogramSkus, { SkuExt } from './PlanogramSkus';
import PlanogramContent from './PlanogramContent';
import PlanogramFrames from './PlanogramFrames';
import EditOtherSkuCommentForm from './EditOtherSkuComment';
import { ContentSku } from 'types/contentSku';
import EditPlanogramComment from './EditPlanogramComment';
import PlanogramComment from './PlanogramComment';
import EditPlanogramPhoto from './EditPlanogramPhoto';
import usePlanograms from 'hooks/usePlanograms';
import LinkedPlanograms from './LinkedPlanograms';
import { saveCurrentSkuCategoryId } from 'common/localVars';
import { FrameLine } from 'types/frameLine';
import { round } from 'common/utils';
import { SkuBlock } from 'hooks/useSkus';

function PlangramBulder (): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { Title } = Typography;
  const { skuCategoryName } = useSkuCategories();
  const { getFirstEnter, readPlanogram, saveCurrentPlanogram } = usePlanograms();
  const [planogram, setPlanogram] = useState<Planogram | null>(null);
  const [planogramInfo, setPlanogramInfo] = useState<string>('');
  const [planogramState, setPlanogramState] = useState<number>(DB_PLANOGRAM_STATE_NEW);
  const [currentSku, setCurrentSku] = useState<SkuExt | null>(null);
  const [activeKey, setActiveKey] = useState<string>(PLANOGRAM_TABS_NONE);
  const [skuBorderStyle] = useState<string>('solid');
  const [skuBorderColor] = useState<string>('red');
  const [selectedSku, setSelectedSku] = useState<string>('');
  const [selectedLine, setSelectedLine] = useState<number | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [editOtherSkuCommentForm] = Form.useForm();
  const [editPlanogramCommentForm] = Form.useForm();
  const [editPlanogramPhotoForm] = Form.useForm();
  const [editPlanogramOtherSkuForm] = Form.useForm();
  const [editPlanogramFrameLineForm] = Form.useForm();
  const { addLine,
    changeOtherSku,
    changed,
    contentSkus,
    doRestorePlanogram,
    doSave,
    dragStopDown,
    dropSku,
    getCurrentPlanogram,
    isRotated,
    lines,
    maxShelfHeight,
    onSetRackHeight,
    onSetRackWidth,
    onSetShelfThickness,
    placeOnShelf,
    rack,
    rackForm,
    rackHeightPX,
    rackWidthPX,
    removeLine,
    removeSku,
    resizeStop,
    saveSkus,
    scale,
    setChanged,
    setShelvesValue,
    shelfHeightBlur,
    shelfSkuToContentSku,
    updateLine } = usePlanogramBuilder();

  const ResponsiveReactGridLayout = WidthProvider(Responsive);
  const { TabPane } = Tabs;
  const className = 'layout';

  const refresh = useCallback((p: Planogram) => {
    doRestorePlanogram(p);
    setShelvesValue(p);
  }, [doRestorePlanogram, setShelvesValue]);

  const onDragStop: ItemCallback = useCallback((layout: Layout[], oldItem: Layout, newItem: Layout) => {
    const i = dragStopDown(layout, oldItem, newItem);

    if ((i) && (i !== null)) {
      setSelectedSku(`${i}`);
    }
  }, [dragStopDown]);

  const setOtherSkuSizes = useCallback((i: number) => {
    const p = getCurrentPlanogram() as Planogram;

    editPlanogramOtherSkuForm.setFieldsValue({ otherSkuHeight: p.skus[i].sku.height, otherSkuWidth: p.skus[i].sku.width });
  }, [editPlanogramOtherSkuForm, getCurrentPlanogram]);

  const onResizeStop: ItemCallback = useCallback((layout: Layout[], oldItem: Layout, newItem: Layout) => {
    console.log('onResizeStop', oldItem, newItem);
    resizeStop(layout, oldItem, newItem);
    const i = Number(oldItem.i);

    setOtherSkuSizes(i);
  }, [resizeStop, setOtherSkuSizes]);

  const onDrop = useCallback((layout: Layout[], item: Layout) => {
    const i = dropSku(layout, item, currentSku, refresh);

    if ((i) && (i !== null)) {
      setSelectedSku(`${i}`);
    }

    setCurrentSku(null);
  }, [currentSku, dropSku, refresh]);

  const onTabChange = useCallback((newActiveKey: string) => {
    console.log('onTabChange', newActiveKey);
  }, []);

  const onTabClick = useCallback((newActiveKey: string) => {
    if (newActiveKey === PLANOGRAM_TABS_COMMENT) {
      editPlanogramCommentForm.setFieldsValue(getCurrentPlanogram() as Planogram);
    } else {
      if (newActiveKey === PLANOGRAM_TABS_PHOTO) {
        editPlanogramPhotoForm.setFieldsValue(getCurrentPlanogram() as Planogram);
      }
    }

    setActiveKey(newActiveKey);
  }, [editPlanogramCommentForm, editPlanogramPhotoForm, getCurrentPlanogram]);

  const notSaved = useCallback((): string => {
    return changed ? t('notSaved') : '';
  }, [changed, t]);

  const getActiveKey = useCallback((p: Planogram): string => {
    return p.state === DB_PLANOGRAM_STATE_NEW ? PLANOGRAM_TABS_RACK : PLANOGRAM_TABS_SKUS;
  }, []);

  useEffect(() => {
    const p = getCurrentPlanogram() as Planogram;

    setPlanogram(p);

    if ((p === null) || (!p.id)) {
      history.push(PATH_PLANOGRAMS);
    } else {
      let comment = `${t('forCategory')} "${skuCategoryName(p.skuCategoryId)}" ${t('from')} ${stringDateToView(p.date)} `;

      if (p.comment !== '') {
        comment += `(${p.comment})`;
      }

      saveCurrentSkuCategoryId(p.skuCategoryId);
      setPlanogramInfo(comment);
      setPlanogramState(p.state);
      refresh(p);
      rackForm.setFieldsValue(p);
      const firstEnter = getFirstEnter();

      if (firstEnter || (activeKey === PLANOGRAM_TABS_NONE)) {
        setActiveKey(getActiveKey(p));
      }
    }
  }, [activeKey, getActiveKey, getCurrentPlanogram, getFirstEnter, history, rackForm, refresh, skuCategoryName, t]);

  const save = useCallback(async () => {
    const p = getCurrentPlanogram() as Planogram;

    setSaving(true);

    try {
      switch (p.state) {
        case DB_PLANOGRAM_STATE_NEW: {
          await doSave(RACK_MODE_SAVE);

          return;
        }

        case DB_PLANOGRAM_STATE_IN_WORK: {
          await saveSkus();

          return;
        }
      }
    } catch (e) {
      console.log('Planogram save error', e);
    } finally {
      setChanged(false);
      setSaving(false);
    }
  }, [doSave, getCurrentPlanogram, saveSkus, setChanged]);

  const doOtherSkuComment = useCallback((sku: ContentSku) => {
    const { confirm } = Modal;
    const orderNumber = sku.orderNumber;
    const p = getCurrentPlanogram() as Planogram;

    editOtherSkuCommentForm.setFieldsValue(sku);
    const form = confirm({
      content: <EditOtherSkuCommentForm
        doSave={ async () => {
          const result = '';

          await editOtherSkuCommentForm
            .validateFields()
            .then((sku: ContentSku) => {
              sku.orderNumber = orderNumber;
              changeOtherSku(sku);
              setOtherSkuSizes(orderNumber - 1);
            });

          form.destroy();

          return result;
        }}
        editMode={LIST_MODE_UPDATE}
        editTitle={t('otherSkusBlock')}
        form={editOtherSkuCommentForm}
        maxWidth={p.width}
        newTitle={t('otherSkusBlock')}
        onCancel={() => { form.destroy(); } }
        visible={true}
      />
    });
  }, [changeOtherSku, editOtherSkuCommentForm, getCurrentPlanogram, setOtherSkuSizes, t]);

  const otherSkuComment = useCallback((i: number, shelfSku: ShelfSku) => {
    doOtherSkuComment(shelfSkuToContentSku(i, shelfSku));
  }, [doOtherSkuComment, shelfSkuToContentSku]);

  const applyPlanogramComment = useCallback(async () => {
    await editPlanogramCommentForm
      .validateFields()
      .then((newP: Planogram) => {
        const p = getCurrentPlanogram() as Planogram;

        p.commentPlace = newP.commentPlace;
        p.commentHeight = newP.commentHeight;
        p.commentFontSize = newP.commentFontSize;
        p.commentPadding = newP.commentPadding;
        p.commentBold = newP.commentBold;
        p.workComment = newP.workComment;
        refresh(p);
        setPlanogram(p);
        setChanged(true);
      });
  }, [editPlanogramCommentForm, getCurrentPlanogram, refresh, setChanged]);

  const applyPlanogramPhoto = useCallback(async () => {
    await editPlanogramPhotoForm
      .validateFields()
      .then((newP: Planogram) => {
        const p = getCurrentPlanogram() as Planogram;

        p.photoHeight = newP.photoHeight;
        p.photoWidth = newP.photoWidth;
        p.photoQuality = newP.photoQuality;
        saveCurrentPlanogram(p);
        setPlanogram(p);
        setChanged(true);
      });
  }, [editPlanogramPhotoForm, getCurrentPlanogram, saveCurrentPlanogram, setChanged]);

  const doPlaceOnShelf = useCallback((sku: SkuExt, skuBlock: SkuBlock) => {
    // Сообщение о том, что недостаточно места на полке
    const error = (reason: string) => {
      const { info } = Modal;

      info({
        content: `${t('notEnoughSpaceOnShelf')} ${t(reason)}`,
        onOk () {
          console.log('');
        },
        title: t('placeOnShelf')
      });
    };

    // Проверка, что планограмма ещё жива
    if (planogram === null) { return; }

    // Размещение блока на полке
    const result = placeOnShelf(sku, skuBlock.rotation, skuBlock.skusInWidth, skuBlock.skusInHeight, skuBlock.shelfNumber - 1, refresh);

    // Обработка результата размещеия
    switch (result) {
      case 1: {
        error(t('onHeight'));

        return;
      }

      case 2: {
        error(t('onWidth'));

        return;
      }

      default: {
        console.log('');
      }
    }
  }, [placeOnShelf, planogram, refresh, t]);

  const cursor = (item: Layout) => {
    return item.isDraggable ? 'pointer' : 'default';
  };

  const otherStyle = useCallback((item: Layout) => {
    return {
      borderColor: selectedSku === item.i ? skuBorderColor : 'transparent',
      borderStyle: skuBorderStyle,
      cursor: cursor(item)
    };
  }, [selectedSku, skuBorderColor, skuBorderStyle]);

  const placeholder = useCallback(() => {
    let skuWidth: number;
    let skuHeight: number;

    if (currentSku) {
      if (isRotated(currentSku.rotation)) {
        skuWidth = currentSku.height;
        skuHeight = currentSku.width;
      } else {
        skuWidth = currentSku.width;
        skuHeight = currentSku.height;
      }
    } else {
      skuWidth = 0;
      skuHeight = 0;
    }

    return currentSku
      ? `\
    .react-grid-layout .react-grid-item.react-grid-placeholder {\
       width: ${skuWidth / scale}px !important;\
       height: ${skuHeight / scale}px !important;\
       background-color: red !important;\
    }\
  `
      : '';
  }, [currentSku, isRotated, scale]);

  const cols = useCallback(() => {
    const gap = 2;

    return { lg: rackWidthPX + gap, md: rackWidthPX + gap, sm: rackWidthPX + gap, xs: rackWidthPX + gap, xxs: rackWidthPX + gap };
  }, [rackWidthPX]);

  const generateRack = useCallback(() => {
    if (!rack || !rack.lg.length) {
      return;
    }

    const p = getCurrentPlanogram() as Planogram;

    return (
      rack.lg.map((item: Layout, index: number) => {
        item.isDraggable = (item.isDraggable) && (activeKey !== PLANOGRAM_TABS_FRAMES);

        const i = Number(item.i);
        const shelfSku = (p.skus) && ((i >= 0) && (i < p.skus.length)) ? p.skus[i] : null;
        const sku = shelfSku ? shelfSku.sku : null;
        const url = sku ? sku.photo : null;
        const rotation = shelfSku ? shelfSku.rotation : 0;
        const isShelf = i >= RACK_SHELF_MIN_INDEX;
        const isOtherSku = sku?.id === OTHER_SKU_ID;
        let w = 0;
        let h = 0;

        if (sku) {
          if (isRotated(rotation)) {
            w = item.h;
            h = item.w;
          } else {
            w = item.w;
            h = item.h;
          }
        }

        return (
          <div
            className={`${item.i.toString()} ${isShelf ? 'shelf' : 'sku'}`}
            key={item.i}
            onClick={() => {
              if (!isShelf) {
                setSelectedSku(item.i);
              }

              if (isOtherSku) {
                setOtherSkuSizes(i);
              }
            }}
            onDoubleClick={() => {
              if ((planogramState === DB_PLANOGRAM_STATE_IN_WORK) && !isShelf && isOtherSku) {
                setOtherSkuSizes(i);
                otherSkuComment(i, p.skus[i]);
              }
            }}
            style={otherStyle(item)}
          >
            { (isShelf) &&
            <div className='text' >
              <div className='shelf-number'>
                {t('shelf')} {index + 1}
              </div>
            </div>
            }
            { (url) &&
              <div style={{ width: '100%', height: '100%', margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <img alt={''}
                  height={h}
                  src={url}
                  style={{ transform: `rotate(${rotation}deg)` }}
                  width={w}
                />
              </div>
            }
            { (isOtherSku) &&
              <div className='other-sku'
                style={{ WebkitBoxOrient: 'vertical',
                  display: '-webkit-box',
                  fontSize: `${OTHER_SKU_FONT_SIZE}px`,
                  height: `${item.h}px`,
                  overflow: 'auto',
                  padding: `${OTHER_SKU_PADDING}px` }}>
                {sku?.name}
              </div>
            }
          </div>
        );
      })
    );
  }, [activeKey, getCurrentPlanogram, isRotated, otherSkuComment, otherStyle, planogramState, rack, setOtherSkuSizes, t]);

  const blur = useCallback((index: number) => {
    shelfHeightBlur(index, rackForm.getFieldValue(`shelf${index}`) as number, refresh);
    const p = getCurrentPlanogram() as Planogram;

    setPlanogram(p);
  }, [getCurrentPlanogram, rackForm, refresh, shelfHeightBlur]);

  const defaultActiveKey = useCallback((): string => {
    const p = getCurrentPlanogram() as Planogram;

    return getActiveKey(p);
  }, [getActiveKey, getCurrentPlanogram]);

  const parentPlanogram = useCallback(async () => {
    if ((planogram) && (planogram.parent)) {
      const p = await readPlanogram(planogram.parent);

      if (p) {
        saveCurrentPlanogram(p);
        history.push(PATH_PLANOGRAM_VIEW);
        window.location.reload();
      }
    }
  }, [history, planogram, readPlanogram, saveCurrentPlanogram]);

  return (
    <div className='App'>
      <Space style={{ marginBottom: 10, marginTop: -40 }} >
        { (planogram) && (planogram.parent) &&
        <Button
          onClick={parentPlanogram}
          style={{ marginTop: -30 }}
          type='default'>
          {t('parentRack')}
        </Button>
        }
        { (planogramState !== DB_PLANOGRAM_STATE_READY) &&
        <Button loading={saving}
          onClick={save}
          style={{ marginTop: -30 }}
          type='default'>
          {t('save')}
        </Button>
        }
        <Space direction='vertical'
          style={{ marginLeft: 10, marginTop: 0 }} >
          <Space>
            <Title level={4}>{`${t('planogram')} ${planogramInfo}`}</Title>
            <Title level={5}
              type={'warning'}>
              {notSaved()}
            </Title>
          </Space>
        </Space>
      </Space>

      <div className='planogram-desktop'
        style={{ position: 'relative' }}>
        <div className='grid'>
          { (planogramState === DB_PLANOGRAM_STATE_IN_WORK) && (currentSku) && (
            <style>{placeholder()}</style>
          )}
          <div
            className='grid-block'
            style={{ height: rackHeightPX, width: rackWidthPX }}
          >
            { (planogram) && (planogram.commentPlace === PLANOGRAM_COMMENT_TOP) &&
              <div>
                <PlanogramComment planogram={planogram}
                  width={rackWidthPX} />
              </div>
            }
            <div style={{ position: 'relative' }}>
              <ResponsiveReactGridLayout
                allowOverlap={true}
                autoSize={false}
                className={className}
                cols={cols()}
                compactType={null}
                isBounded={true}
                isDroppable={true}
                layouts={rack}
                margin={[0, 0]}
                measureBeforeMount={true}
                onDragStop={onDragStop}
                onDrop={onDrop}
                onLayoutChange={() => { console.log(''); }}
                onResizeStop={onResizeStop}
                preventCollision={true}
                rowHeight={RACK_ROW_HEIGHT}
                style={{ minHeight: rackHeightPX + 2, minWidth: rackWidthPX }}
              >
                {generateRack()}
              </ResponsiveReactGridLayout>
              { (planogram) && (lines) &&
                <div>
                  { lines.map((line: FrameLine, index: number) => {
                    const y0 = line.shift / planogram.height * rackHeightPX;
                    const x0 = line.shift / planogram.width * rackWidthPX;
                    const color = (index === selectedLine) ? RACK_FRAME_LINE_SELECTED_COLOR : RACK_FRAME_LINE_COLOR;

                    return (
                      <div key={index}>
                        { (line.orientation === FRAME_LINE_VERT) &&
                          <Draggable axis={'x'}
                            bounds={{ left: 0, top: 0, right: rackWidthPX - 1, bottom: rackHeightPX - 1 }}

                            disabled={activeKey !== PLANOGRAM_TABS_FRAMES}
                            enableUserSelectHack={true}
                            key={index}
                            onMouseDown={() => {
                              if (activeKey === PLANOGRAM_TABS_FRAMES) {
                                setSelectedLine(index);
                              }
                            }}
                            onStop={(e: DraggableEvent, data: DraggableData) => {
                              line.shift = round(data.lastX / rackWidthPX * planogram.width);

                              updateLine(line);
                            }}
                            position={{ x: x0, y: 0 }}>
                            <div
                              style={{
                                cursor: (activeKey === PLANOGRAM_TABS_FRAMES) ? 'move' : 'unset',
                                background: color,
                                height: rackHeightPX,
                                width: RACK_FRAME_LINE_WIDTH,
                                position: 'absolute',
                                top: 0,
                                left: 0
                              }} />
                          </Draggable>
                        }
                        { (line.orientation === FRAME_LINE_HOR) &&
                         <Draggable axis={'y'}
                           bounds={{ left: 0, top: 0, right: rackWidthPX - 1, bottom: rackHeightPX - 1 }}
                           disabled={activeKey !== PLANOGRAM_TABS_FRAMES}
                           enableUserSelectHack={true}
                           key={index}
                           onMouseDown={() => {
                             if (activeKey === PLANOGRAM_TABS_FRAMES) {
                               setSelectedLine(index);
                             }
                           }}
                           onStop={(e: DraggableEvent, data: DraggableData) => {
                             line.shift = round(data.lastY / rackHeightPX * planogram.height);

                             updateLine(line);
                           }}
                           position={{ x: 0, y: y0 }}>
                           <div
                             style={{
                               cursor: (activeKey === PLANOGRAM_TABS_FRAMES) ? 'move' : 'unset',
                               background: color,
                               height: RACK_FRAME_LINE_WIDTH,
                               width: rackWidthPX,
                               position: 'absolute',
                               top: 0,
                               left: 0
                             }} />
                         </Draggable>
                        }
                      </div>
                    );
                  })}
                </div>
              }
            </div>
            { (planogram) && (planogram.commentPlace === PLANOGRAM_COMMENT_BOTTOM) &&
              <div>
                <PlanogramComment planogram={planogram}
                  width={rackWidthPX} />
              </div>
            }
          </div>
          <div className='table-block'
            style={{ marginLeft: 80 }}
          >
            {(planogram) &&
            <Tabs defaultActiveKey={defaultActiveKey()}
              onChange={onTabChange}
              onTabClick={onTabClick}
              size='small'
              style={{ width: PLANOGRAM_TABS_WIDTH }}
            >
              {(planogram) &&
              <TabPane key={PLANOGRAM_TABS_RACK}
                tab={t('rack')}
              > { (activeKey === PLANOGRAM_TABS_RACK) &&
                  <RackForm blur={blur}
                    doSave={doSave}
                    enabled={ planogramState === DB_PLANOGRAM_STATE_NEW}
                    enabledHeight={ planogramState !== DB_PLANOGRAM_STATE_READY}
                    form={rackForm}
                    maxShelfHeight={maxShelfHeight}
                    onSetRackHeight={onSetRackHeight}
                    onSetRackWidth={onSetRackWidth}
                    onSetShelfThickness={onSetShelfThickness}
                    rack={rack}
                    refresh={refresh}
                    scale={scale}
                    setActiveKey={setActiveKey}/>
                }
              </TabPane>
              }
              {(planogram) && (planogramState !== DB_PLANOGRAM_STATE_NEW) &&
                <>
                  <TabPane key={PLANOGRAM_TABS_SKUS}
                    tab={t('skus')}
                  >
                    {(activeKey === PLANOGRAM_TABS_SKUS) &&
                    <PlanogramSkus enabled={planogramState === DB_PLANOGRAM_STATE_IN_WORK}
                      form={editPlanogramOtherSkuForm}
                      maxOtherSkuWidth={planogram.width}
                      numOfShelves={planogram.numOfShelves}
                      placeOnShelf={doPlaceOnShelf}
                      setCurrentSku={setCurrentSku}
                      skuCategoryId={planogram.skuCategoryId} />}
                  </TabPane>
                  <TabPane key={PLANOGRAM_TABS_CONTENT}
                    tab={t('contentSkus')}
                  >
                    {(activeKey === PLANOGRAM_TABS_CONTENT) &&
                      <PlanogramContent contentSkus={contentSkus}
                        enabled={planogramState === DB_PLANOGRAM_STATE_IN_WORK}
                        otherSkuComment={doOtherSkuComment}
                        removeSku={removeSku}
                        selectedSku={selectedSku}
                        setSelectedSku={setSelectedSku} />}
                  </TabPane>
                  <TabPane key={PLANOGRAM_TABS_COMMENT}
                    tab={t('comment')}
                  >
                    {(activeKey === PLANOGRAM_TABS_COMMENT) &&
                      <EditPlanogramComment apply={applyPlanogramComment}
                        enabled={planogramState === DB_PLANOGRAM_STATE_IN_WORK}
                        form={editPlanogramCommentForm} />}
                  </TabPane>
                  <TabPane key={PLANOGRAM_TABS_FRAMES}
                    tab={t('division')}
                  >
                    {(activeKey === PLANOGRAM_TABS_FRAMES) &&
                      <PlanogramFrames addLine={addLine}
                        enabled={planogramState === DB_PLANOGRAM_STATE_IN_WORK}
                        form={editPlanogramFrameLineForm}
                        lines={lines}
                        planogram={planogram}
                        removeLine={removeLine}
                        selectedLine={selectedLine}
                        setSelectedLine={setSelectedLine}
                      />}
                  </TabPane>
                  <TabPane key={PLANOGRAM_TABS_PHOTO}
                    tab={t('photo')}
                  >
                    {(activeKey === PLANOGRAM_TABS_PHOTO) &&
                      <EditPlanogramPhoto apply={applyPlanogramPhoto}
                        enabled={planogramState === DB_PLANOGRAM_STATE_IN_WORK}
                        form={editPlanogramPhotoForm}
                        planogram={planogram}
                      />}
                  </TabPane>

                  {(!planogram.parent) &&
                    <TabPane key={PLANOGRAM_TABS_LINKED}
                      tab={t('linked_planograms')}
                    >
                      {(activeKey === PLANOGRAM_TABS_LINKED) &&
                        <LinkedPlanograms
                          enabled={planogramState === DB_PLANOGRAM_STATE_IN_WORK}
                          parent={planogram.id}
                        />}
                    </TabPane>
                  }
                </>
              }
            </Tabs>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PlangramBulder);
