// Copyright 2021 @po-polochkam authors & contributors

import { Sku } from './sku';

export const PlanogramVersion = '2';

export interface Planogram {
  version: string | null,
  id: string,
  date: string,
  numOfShelves: number,
  shelfThickness: number,
  skuCategoryId: string,
  state: number,
  comment: string,
  width: number,
  height: number,
  workComment: string,
  commentPlace: number,
  commentHeight: number,
  commentFontSize: number,
  commentBold: boolean,
  commentPadding: number,
  photoWidth: number,
  photoHeight: number,
  photoQuality: number,
  shelves: Shelf[],
  skus: ShelfSku[],
  lines: Line[],
  parent: string | null
}

export interface Shelf {
  height: number,
  thiсkness: number
}

export interface ShelfSku {
  shelf: number,
  place: number,
  top: number,
  left: number,
  rotation: number,
  sku: Sku
}

export interface Line {
  orientation: number,
  shift: number
}

export interface PlanogramExp {
  version: string | null,
  id: string,
  date: string,
  numOfShelves: number,
  skuCategoryId: string,
  state: number,
  comment: string,
  width: number,
  height: number,
  workComment: string,
  commentPlace: number,
  commentHeight: number,
  commentFontSize: number,
  commentBold: boolean,
  commentPadding: number,
  photoWidth: number,
  photoHeight: number,
  photoQuality: number,
  shelves: ShelfExp[],
  skus: ShelfSku[],
  frames: Frame[],
  parent: string | null
}

export interface Frame {
  left: number,
  top: number,
  width: number,
  height: number
}

export interface ShelfExp {
  height: number,
  thickness: number
}
