// Copyright 2022 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Brand } from 'database/entities/brand';

export interface BrandFiltersProps {
  allowClear: boolean;
  brands: Brand[];
  change: (brand: any) => void;
  form: FormInstance;
}

const BrandFilter: React.FC<BrandFiltersProps> = (props) => {
  const { t } = useTranslation();
  const { allowClear, brands, change, form } = props;

  return (
    <Form
      form={form}
      labelCol={{
        span: 7
      }}
      layout='inline'
      name='brand_filter_form'
      size={'small'} >
      <Form.Item
        label={t('brand')}
        name='brand'
        style={{ marginTop: 0 }}
      >
        <Select
          allowClear={allowClear}
          clearIcon= {<CloseOutlined />}
          onChange={change}
          showArrow={true}
          style={{ width: 200 }}>
          {brands.map((b) =>
            <Select.Option
              key={b.id}
              value={b.id}>
              {b.name}
            </Select.Option>)};
        </Select>
      </Form.Item>
    </Form>
  );
};

export default memo(BrandFilter);
