// Copyright 2022 @po-polochkam authors & contributors

const host = window.location.host;

let currentApiPath = '/api/';
if (host.includes('localhost')) {
  currentApiPath = '/api/latest/';
} else {
  if (host.includes('pre.')) {
    currentApiPath = '/api/pre/';
  } else {
    if (host.includes('latest.')) {
      currentApiPath = '/api/latest/';
    } else {
      if (host.includes('stage.')) {
        currentApiPath = '/api/stage/';
      } else {
        if (host.includes('stable.')) {
          currentApiPath = '/api/stable/';
        } else {
          if (host.includes('edu.')) {
            currentApiPath = '/api/edu/';
          } 
       }  
     }
   }
  }
}

export const apiPath = process.env.NODE_ENV === 'development' ? '/api/' : currentApiPath;
