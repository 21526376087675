// Copyright 2021 @po-polochkam authors & contributors

import { useState } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { LIST_MODE_VIEW } from 'common/constants';

interface UseListInterface {
  editForm: FormInstance;
  filterForm: FormInstance;
  isFetching: boolean;
  listMode: number;
  refreshData: boolean;
  saving: boolean;
  setIsFetching: (isFetching: boolean) => void;
  setListMode: (listMode: number) => void;
  setRefreshData: (refreshData: boolean) => void;
  setSaving: (saving: boolean) => void;
}

export function useList (): UseListInterface {
  const [listMode, setListMode] = useState<number>(LIST_MODE_VIEW);
  const [refreshData, setRefreshData] = useState(true);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [saving, setSaving] = useState(false);
  const [editForm] = Form.useForm();
  const [filterForm] = Form.useForm();

  return {
    editForm,
    filterForm,
    isFetching,
    listMode,
    refreshData,
    saving,
    setIsFetching,
    setListMode,
    setRefreshData,
    setSaving
  };
}

export default useList;
