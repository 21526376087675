// Copyright 2021 @po-polochkam authors & contributors

export const round = (n: number): number => {
  return parseFloat(n.toFixed(0));
};

export const roundM = (n: number): number => {
  let result = parseFloat(n.toFixed(0));

  if (result < n) {
    result += 1;
  }

  return result;
};
