// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Image, Modal, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ContentSku } from 'types/contentSku';
import useSkus from 'hooks/useSkus';
import { MAX_PAGE_SIZE, OTHER_SKU_ID, PLANOGRAM_TABS_WIDTH } from 'common/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { DeleteAction, EditAction } from 'components/actions';
import './styles.scss';
import ContentCodes from './ContentCodes';

export interface PlanogramContentProps {
  contentSkus: ContentSku[];
  enabled: boolean;
  otherSkuComment: (sku: ContentSku) => void;
  removeSku: (sku: ContentSku) => void;
  selectedSku: string;
  setSelectedSku: (id: string) => void;
}

const PlanogramContent: React.FC<PlanogramContentProps> = (props) => {
  const { contentSkus, enabled, otherSkuComment, removeSku, selectedSku, setSelectedSku } = props;
  const { t } = useTranslation();
  const { imageDimensions } = useSkus();
  const { height } = useWindowDimensions();
  let previewVisible = false;

  const deleteSku = (sku: ContentSku) => {
    const { confirm } = Modal;

    setSelectedSku(String(sku.orderNumber - 1));
    confirm({
      cancelText: t('no'),
      icon: <ExclamationCircleOutlined />,
      okText: t('yes'),
      okType: 'danger',
      onOk () {
        void removeSku(sku);
      },
      title: `${t('deletePlanogramSku')} "${sku.name}"?`
    });
  };

  const columns: ColumnsType<ContentSku> = [
    {
      align: 'right',
      dataIndex: 'orderNumber',
      sorter: { compare: (a: { orderNumber: number; }, b: { orderNumber: number; }) => a.orderNumber - b.orderNumber },
      title: t('orderNumber'),
      width: 60
    },
    {
      align: 'center',
      dataIndex: 'photo',
      // eslint-disable-next-line react/display-name
      render: (photo: string | undefined, sku) => {
        const d = imageDimensions(sku.width, sku.height);

        return <div>
          { (photo) &&
            <Image
              draggable={false}
              height={d.height}
              preview={{ mask: () => { console.log(''); },
                onVisibleChange: (visible) => {
                  previewVisible = visible;
                } }}
              src={photo}
              style={{ transform: `rotate(${sku.rotation}deg)` }}
              unselectable={'on'}
              width={d.width} />
          }
          { (!photo) &&
          <div style={{ backgroundColor: 'lightgrey', height: d.height, width: d.width }} />
          }
        </div>;
      },
      title: t('photo'),
      width: 60
    },
    {
      align: 'left',
      dataIndex: 'code',
      title: t('code'),
      width: 100
    },
    {
      align: 'left',
      dataIndex: 'name',
      title: t('name'),
      width: 200
    },
    {
      align: 'right',
      dataIndex: 'shelf',
      sorter: { compare: (a: { shelf: number; }, b: { shelf: number; }) => a.shelf - b.shelf, multiple: 2 },
      title: t('shelf'),
      width: 50
    },
    {
      align: 'right',
      dataIndex: 'place',
      sorter: { compare: (a: { place: number; }, b: { place: number; }) => a.place - b.place, multiple: 1 },
      title: t('place'),
      width: 50
    },
    {
      dataIndex: 'action',
      // eslint-disable-next-line react/display-name
      render: (_text, sku) => (
        <Space
          size='large'
          style={{ marginLeft: 10 }}>
          <EditAction action={ () => otherSkuComment(sku) }
            disabled={sku.id !== OTHER_SKU_ID}
          />
          <DeleteAction action={deleteSku.bind(null, sku)}
            disabled={!enabled}
          />
        </Space>
      ),
      title: t('actions'),
      width: '60px'
    }
  ];

  return (
    <div>
      <ContentCodes contentSkus={contentSkus}/>
      <Table
        columns={columns}
        dataSource={contentSkus}
        onRow={(sku) => {
          return {
            onClick: () => {
              if (!previewVisible) {
                setSelectedSku(String(sku.orderNumber - 1));
              }
            }
          };
        }}
        pagination={{ hideOnSinglePage: true, pageSize: MAX_PAGE_SIZE }}
        rowClassName={(sku: ContentSku) => String(sku.orderNumber - 1) === selectedSku ? 'selected' : ''}
        scroll={{ y: height - 260 }}
        size='small'
        style={{ marginTop: 10, maxWidth: PLANOGRAM_TABS_WIDTH, width: 800 }}
        tableLayout='fixed'
      />
    </div>
  );
};

export default memo(PlanogramContent);
