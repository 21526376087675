// Copyright 2021 @po-polochkam authors & contributors

import React, { useCallback, useMemo, useState } from 'react';
import { Planogram, PlanogramVersion } from 'database/entities/planogram';
import { CURRENT_PLANOGRAM,
  DEFAULT_PLANOGRAM_COMMENT_BOLD,
  DEFAULT_PLANOGRAM_COMMENT_FONT_SIZE,
  DEFAULT_PLANOGRAM_COMMENT_HEIGHT,
  DEFAULT_PLANOGRAM_COMMENT_PADDING,
  DEFAULT_PLANOGRAM_PHOTO_HEIGHT,
  DEFAULT_PLANOGRAM_PHOTO_QUALITY,
  DEFAULT_PLANOGRAM_PHOTO_WIDTH,
  LIST_MODE_ADD,
  LIST_MODE_COPY,
  LIST_MODE_VIEW,
  PATH_PLANOGRAM_VIEW,
  PLANOGRAM_COMMENT_NONE,
  PLANOGRAM_COPY_ALL,
  RACK_DEFAULT_HEIGHT,
  RACK_DEFAULT_NUM_OF_SHELVES,
  RACK_DEFAULT_SHELF_THICKNESS,
  RACK_DEFAULT_WIDTH } from 'common/constants';
import { useTranslation } from 'react-i18next';
import Form, { FormInstance } from 'antd/lib/form';
import { v4 as uuid } from 'uuid';
import { stringDateToView, todayToString } from 'common/datetimes';
import { DB_PLANOGRAM_STATE_IN_WORK, DB_PLANOGRAM_STATE_NEW, DB_PLANOGRAM_STATE_READY } from 'database/constants';
import { useHistory } from 'react-router-dom';
import { getCurrentSkuCategoryId } from 'common/localVars';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CopyPlanogram } from 'components/CopyPlanogram';
import { round } from 'common/utils';

interface UseEditPlanogramInterface {
  add: () => void;
  adjustPlanogram: (p: Planogram) => Planogram;
  copy: (planogram: Planogram) => void;
  copyForm: FormInstance;
  doCopy: () => Promise<string>;
  doSave: () => Promise<string>;
  getCurrentPlanogram: () => Planogram | null;
  removePlanogram: (planogram: Planogram) => void;
  saveCurrentPlanogram: (planogram: Planogram) => void;
  update: (listMode: number, record: Planogram) => void;
}

interface UseEditPlanogramProps {
  addPlanogram: (planogram: Planogram) => Promise<any>,
  deletePlanogram: (planogram: Planogram) => Promise<any>;
  editForm: FormInstance;
  listMode: number
  parent: string | null;
  setListMode: (listMode: number) => void;
  setRefreshData: (refreshData: boolean) => void;
  setSaving: (saving: boolean) => void;
  updatePlanogram: (planogram: Planogram) => Promise<any>,
}

export function useEditPlanogram (props: UseEditPlanogramProps): UseEditPlanogramInterface {
  const { t } = useTranslation();
  const history = useHistory();
  const { addPlanogram,
    deletePlanogram,
    editForm,
    listMode,
    parent,
    setListMode,
    setRefreshData,
    setSaving,
    updatePlanogram } = props;
  const [copyForm] = Form.useForm();

  const emptyPlanogram = useMemo((): Planogram => ({
    comment: '',
    commentBold: false,
    commentFontSize: DEFAULT_PLANOGRAM_COMMENT_FONT_SIZE,
    commentHeight: DEFAULT_PLANOGRAM_COMMENT_HEIGHT,
    commentPadding: DEFAULT_PLANOGRAM_COMMENT_PADDING,
    commentPlace: PLANOGRAM_COMMENT_NONE,
    date: '',
    height: RACK_DEFAULT_HEIGHT,
    id: '',
    lines: [],
    numOfShelves: RACK_DEFAULT_NUM_OF_SHELVES,
    parent: parent,
    photoHeight: DEFAULT_PLANOGRAM_PHOTO_HEIGHT,
    photoQuality: DEFAULT_PLANOGRAM_PHOTO_QUALITY,
    photoWidth: DEFAULT_PLANOGRAM_PHOTO_WIDTH,
    shelfThickness: RACK_DEFAULT_SHELF_THICKNESS,
    shelves: [],
    skuCategoryId: getCurrentSkuCategoryId(),
    skus: [],
    state: DB_PLANOGRAM_STATE_NEW,
    version: null,
    width: RACK_DEFAULT_WIDTH,
    workComment: ''
  }), [parent]);

  const [planogramOld, setPlanogramOld] = useState<Planogram>(emptyPlanogram);

  const saveCurrentPlanogram = useCallback((planogram: Planogram) => {
    localStorage.setItem(CURRENT_PLANOGRAM, JSON.stringify(planogram));
  }, []);

  const save = useCallback(async (planogram: Planogram) => {
    let result = '';

    try {
      if ((listMode === LIST_MODE_ADD) || (listMode === LIST_MODE_COPY)) {
        await addPlanogram(planogram);
      } else {
        await updatePlanogram(planogram);
      }
    } catch (e) {
      result = t('addPlanogramError');
    }

    return result;
  }, [addPlanogram, listMode, t, updatePlanogram]);

  const remove = useCallback(async (p: Planogram) => {
    let result = '';

    try {
      await deletePlanogram(p);
      setRefreshData(true);
    } catch (e) {
      result = t('deletePlanogramError');
    }

    return result;
  }, [deletePlanogram, setRefreshData, t]);

  const doSave = useCallback(async () => {
    let result = '';

    await editForm
      .validateFields()
      .then(async (values: Planogram) => {
        setSaving(true);

        try {
          const p: Planogram = { ...values };

          if (listMode === LIST_MODE_ADD) {
            p.id = uuid();
            p.date = todayToString();
            p.state = DB_PLANOGRAM_STATE_NEW;
            p.parent = parent;
          } else {
            p.id = planogramOld.id;
            p.date = planogramOld.date;
            p.parent = planogramOld.parent;
          }

          result = await save(p);

          if (result === '') {
            editForm.resetFields();
            const listModeOld = listMode;

            setListMode(LIST_MODE_VIEW);

            if (listModeOld === LIST_MODE_ADD) {
              saveCurrentPlanogram(p);
              history.push(PATH_PLANOGRAM_VIEW);

              if (p.parent !== null) {
                window.location.reload();
              }
            } else {
              setRefreshData(true);
            }
          }
        } finally {
          setSaving(false);
        }
      });

    return result;
  }, [editForm, setSaving, listMode, save, parent, planogramOld.id, planogramOld.date, planogramOld.parent, setListMode, saveCurrentPlanogram, history, setRefreshData]);

  const update = useCallback((listMode: number, p: Planogram) => {
    saveCurrentPlanogram(p);
    setPlanogramOld(p);
    setListMode(listMode);
  }, [saveCurrentPlanogram, setListMode, setPlanogramOld]);

  const add = useCallback(() => {
    update(LIST_MODE_ADD, emptyPlanogram);
  }, [emptyPlanogram, update]);

  const removePlanogram = useCallback((planogram: Planogram) => {
    const { confirm } = Modal;

    confirm({
      cancelText: t('no'),
      icon: <ExclamationCircleOutlined />,
      okText: t('yes'),
      okType: 'danger',
      onOk () {
        void remove(planogram);
      },
      title: `${t('deletePlanogram')} "${stringDateToView(planogram.date)}"?`
    });
  }, [remove, t]);

  const adjustPlanogram = useCallback((p: Planogram): Planogram => {
    if (!p.version) {
      p.version = PlanogramVersion;
    }

    if (!p.parent) {
      p.parent = null;
    }

    if (!p.numOfShelves || p.numOfShelves === 0) {
      p.numOfShelves = RACK_DEFAULT_NUM_OF_SHELVES;
    }

    if (!p.height || p.height === 0) {
      p.height = RACK_DEFAULT_HEIGHT;
    }

    if (!p.width || p.width === 0) {
      p.width = RACK_DEFAULT_WIDTH;
    }

    if (!p.workComment) {
      p.workComment = '';
    }

    if (!p.commentPlace) {
      p.commentPlace = PLANOGRAM_COMMENT_NONE;
    }

    if (!p.commentHeight) {
      p.commentHeight = DEFAULT_PLANOGRAM_COMMENT_HEIGHT;
    }

    if (!p.commentBold) {
      p.commentBold = DEFAULT_PLANOGRAM_COMMENT_BOLD;
    }

    if (!p.commentFontSize) {
      p.commentFontSize = DEFAULT_PLANOGRAM_COMMENT_FONT_SIZE;
    }

    if (!p.commentPadding) {
      p.commentPadding = DEFAULT_PLANOGRAM_COMMENT_PADDING;
    }

    if (!p.photoHeight) {
      p.photoHeight = DEFAULT_PLANOGRAM_PHOTO_HEIGHT;
    }

    if (!p.photoWidth) {
      p.photoWidth = DEFAULT_PLANOGRAM_PHOTO_WIDTH;
    }

    if (!p.photoQuality) {
      p.photoQuality = DEFAULT_PLANOGRAM_PHOTO_QUALITY;
    }

    if (!p.shelfThickness) {
      p.shelfThickness = RACK_DEFAULT_SHELF_THICKNESS;
    }

    if ((!p.shelves) || (p.numOfShelves !== p.shelves.length)) {
      p.shelves = [];
      const shelfHeight = round((p.height - p.shelfThickness * p.numOfShelves) / p.numOfShelves);

      for (let i = 0; i < p.numOfShelves - 1; i++) {
        p.shelves.push({ height: shelfHeight, thiсkness: p.shelfThickness });
      }

      p.shelves.push({ height: p.height - shelfHeight * (p.numOfShelves - 1) - p.shelfThickness * p.numOfShelves, thiсkness: p.shelfThickness });
    } else {
      let totalHeight = 0;

      for (let i = 0; i < p.numOfShelves - 1; i++) {
        totalHeight += (p.shelves[i].height + p.shelves[i].thiсkness);
      }

      p.shelves[p.numOfShelves - 1].height = p.height - totalHeight - p.shelves[p.numOfShelves - 1].thiсkness;
    }

    return p;
  }, []);

  const getCurrentPlanogram = useCallback((): Planogram | null => {
    const result = localStorage.getItem(CURRENT_PLANOGRAM);

    if (result) {
      let p = JSON.parse(result) as Planogram;

      if (!p) {
        return null;
      }

      p = adjustPlanogram(p);

      saveCurrentPlanogram(p);

      return p;
    } else {
      return null;
    }
  }, [adjustPlanogram, saveCurrentPlanogram]);

  const doCopy = useCallback(async () => {
    let result = '';

    await copyForm
      .validateFields()
      .then(async (c: CopyPlanogram) => {
        setSaving(true);

        const planogram = getCurrentPlanogram();

        if (!planogram) {
          return;
        }

        let p: Planogram;

        if (c.variant === PLANOGRAM_COPY_ALL) {
          p = { ...planogram };

          p.id = uuid();
          p.date = todayToString();

          if (p.state === DB_PLANOGRAM_STATE_READY) {
            p.state = DB_PLANOGRAM_STATE_IN_WORK;
          }
        } else {
          p = emptyPlanogram;

          p.comment = planogram.comment;
          p.date = todayToString();
          p.height = planogram.height;
          p.id = uuid();
          p.numOfShelves = planogram.numOfShelves;
          p.parent = planogram.parent;
          p.skuCategoryId = planogram.skuCategoryId;
          p.width = planogram.width;

          for (let i = 0; i < planogram.shelves.length; i++) {
            p.shelves.push(planogram.shelves[i]);
          }
        }

        result = await save(p);

        if (result === '') {
          saveCurrentPlanogram(p);
          setListMode(LIST_MODE_VIEW);
          history.push(PATH_PLANOGRAM_VIEW);

          if (planogram.parent !== null) {
            window.location.reload();
          }
        }
      });

    return result;
  }, [copyForm, emptyPlanogram, getCurrentPlanogram, history, save, saveCurrentPlanogram, setListMode, setSaving]);

  const copy = useCallback((p: Planogram) => {
    saveCurrentPlanogram(p);
    const c: CopyPlanogram = { variant: 0 };

    copyForm.setFieldsValue(c);
    setListMode(LIST_MODE_COPY);
  }, [copyForm, saveCurrentPlanogram, setListMode]);

  return {
    add,
    adjustPlanogram,
    copy,
    copyForm,
    doCopy,
    doSave,
    getCurrentPlanogram,
    removePlanogram,
    saveCurrentPlanogram,
    update
  };
}
