// Copyright 2021 @po-polochkam authors & contributors

import { DimensionsInteface } from 'common/intefaces';
import { useState, useEffect } from 'react';

function getWindowDimensions (): DimensionsInteface {
  const { innerHeight: height, innerWidth: width } = window;

  return {
    height,
    width
  };
}

export default function useWindowDimensions (): DimensionsInteface {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize () {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
