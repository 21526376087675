// Copyright 2021 @po-polochkam authors & contributors
/* eslint-disable sort-keys */

import { useCallback, useState } from 'react';
import { DB_ADD, DB_PLANOGRAMS, DB_UPDATE } from 'database/constants';
import useFirestore from 'hooks/useFirestore';
import { Planogram } from 'database/entities/planogram';
import { CollectionReference } from '@firebase/firestore-types';

export interface PlanogramDataInterface {
  addPlanogram: (planogram: Planogram) => Promise<any>,
  deletePlanogram: (planogram: Planogram) => Promise<any>,
  getAllPlanogram: () => CollectionReference | undefined,
  getPlanogram: (id: string) => Promise<Planogram | undefined>,
  updatePlanogram: (planogram: Planogram) => Promise<any>,
}

export default function usePlanogramData (): PlanogramDataInterface {
  const { firestore } = useFirestore();
  const [db] = useState<CollectionReference | undefined>(firestore?.collection(DB_PLANOGRAMS));

  const getAllPlanogram = useCallback((): CollectionReference | undefined => {
    return db;
  }, [db]);

  const getPlanogram = useCallback(async (id: string): Promise<Planogram | undefined> => {
    const querySnapshot = await db?.where('id', '==', id).limit(1).get();
    let result: Planogram | undefined;

    if (querySnapshot) {
      const planograms = querySnapshot.docs;

      if (planograms.length === 1) {
        result = planograms[0].data() as Planogram;
      }
    }

    return result;
  }, [db]);

  const deletePlanogram = useCallback(async (planogram: Planogram) => {
    const parent = planogram.parent;

    if (parent) {
      const sub = db?.doc(parent).collection(DB_PLANOGRAMS);

      if (sub) {
        const snapshot = await sub.get();

        if ((snapshot) && (snapshot.size > 0)) {
          snapshot.forEach((doc) => {
            void sub.doc(doc.id).delete();
          });
        }
      }
    }

    return db?.doc(planogram.id).delete();
  }, [db]);

  const save = useCallback(async (command: number, planogram: Planogram) => {
    if (!db) {
      return;
    }

    switch (command) {
      case DB_ADD: {
        return db.doc(planogram.id).set(planogram);
      }

      case DB_UPDATE: {
        return db.doc(planogram.id).update(planogram);
      }

      // eslint-disable-next-line no-empty
      default: { console.log(''); }
    }
  }, [db]);

  const addPlanogram = useCallback(async (planogram: Planogram) => {
    return save(DB_ADD, planogram);
  }, [save]);

  const updatePlanogram = useCallback(async (planogram: Planogram) => {
    return save(DB_UPDATE, planogram);
  }, [save]);

  return {
    addPlanogram,
    deletePlanogram,
    getAllPlanogram,
    getPlanogram,
    updatePlanogram
  };
}
