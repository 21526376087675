// Copyright 2021 @po-polochkam authors & contributors

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Image, InputNumber } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { MAX_PLANOGRAM_PHOTO_HEIGHT,
  MAX_PLANOGRAM_PHOTO_WIDTH,
  MIN_PLANOGRAM_PHOTO_HEIGHT,
  MIN_PLANOGRAM_PHOTO_WIDTH } from 'common/constants';
import { Planogram } from 'database/entities/planogram';
import { round } from 'common/utils';
import usePlanogramPhoto from 'hooks/usePlanogramPhoto';

export interface EditPlanogramPhotoProps {
  apply: () => void;
  enabled?: boolean;
  form: FormInstance;
  planogram: Planogram;
}

const EditPlanogramPhotoForm: React.FC<EditPlanogramPhotoProps> = (props) => {
  const { apply, enabled, form, planogram } = props;
  const { t } = useTranslation();
  const [visiblePreview, setVisiblePreview] = useState<boolean>(false);
  const { canvasHeight,
    canvasRef,
    canvasWidth,
    height,
    makeCanvas,
    photoScale,
    preview,
    previewRef,
    saveJPG,
    saveJSON,
    width } = usePlanogramPhoto({ planogram });

  const blurWidth = useCallback(() => {
    let w: number = form.getFieldValue('photoWidth');
    let h = round(w / photoScale);

    if (h < MIN_PLANOGRAM_PHOTO_HEIGHT) {
      h = MIN_PLANOGRAM_PHOTO_HEIGHT;
      w = round(h * photoScale);
    }

    planogram.photoWidth = w;
    planogram.photoHeight = h;
    planogram.photoQuality = form.getFieldValue('photoQuality');
    form.setFieldsValue(planogram);
  }, [form, photoScale, planogram]);

  const blurHeight = useCallback(() => {
    let h: number = form.getFieldValue('photoHeight');
    let w = round(h * photoScale);

    if (w < MIN_PLANOGRAM_PHOTO_WIDTH) {
      w = MIN_PLANOGRAM_PHOTO_WIDTH;
      h = round(w / photoScale);
    }

    planogram.photoHeight = h;
    planogram.photoWidth = w;
    planogram.photoQuality = form.getFieldValue('photoQuality');
    form.setFieldsValue(planogram);
  }, [form, photoScale, planogram]);

  useEffect(() => {
    makeCanvas();
  }, [blurHeight, makeCanvas]);

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '300px minmax(100px, max-content)' }}>
      <div>
        <Form
          form={form}
          labelCol={{
            span: 10
          }}
          layout='horizontal'
          name='edit_planogram_photo_form'
          wrapperCol={{
            span: 10
          }}
        >
          <Form.Item
            label={t('widthPX')}
            name='photoWidth'
            rules={[
              {
                max: MAX_PLANOGRAM_PHOTO_WIDTH,
                message: `${t('from')} ${MIN_PLANOGRAM_PHOTO_WIDTH} ${t('to')} ${MAX_PLANOGRAM_PHOTO_WIDTH}`,
                min: MIN_PLANOGRAM_PHOTO_WIDTH,
                required: true,
                type: 'integer'
              }
            ]}
            tooltip={`${t('photoWidthMustBe')} ${MIN_PLANOGRAM_PHOTO_WIDTH} ${t('to')} ${MAX_PLANOGRAM_PHOTO_WIDTH}`}
          >
            <InputNumber disabled={!enabled}
              onBlur={() => { blurWidth(); } }/>
          </Form.Item>
          <Form.Item
            label={t('heightPX')}
            name='photoHeight'
            rules={[
              {
                max: MAX_PLANOGRAM_PHOTO_HEIGHT,
                message: `${t('from')} ${MIN_PLANOGRAM_PHOTO_HEIGHT} ${t('to')} ${MAX_PLANOGRAM_PHOTO_HEIGHT}`,
                min: MIN_PLANOGRAM_PHOTO_HEIGHT,
                required: true,
                type: 'integer'
              }
            ]}
            tooltip={`${t('photoHeightMustBe')} ${MIN_PLANOGRAM_PHOTO_HEIGHT} ${t('to')} ${MAX_PLANOGRAM_PHOTO_HEIGHT}`}
          >
            <InputNumber disabled={!enabled}
              onBlur={() => { blurHeight(); }} />
          </Form.Item>
          <Form.Item
            label={t('quality')}
            name='photoQuality'
            rules={[
              {
                max: 100,
                message: `${t('from')} 0 ${t('to')} 100`,
                min: 0,
                required: true,
                type: 'integer'
              }
            ]}
            tooltip={t('photoQualityMustBe')}
          >
            <InputNumber disabled={!enabled} />
          </Form.Item>
          <Form.Item name='apply' >
            <Button onClick={() => {
              apply();
            }}>
              {t('apply')}
            </Button>
          </Form.Item>
          <Form.Item name='preview'>
            <Button onClick={() => {
              preview();
              setVisiblePreview(true);
            }}>
              {t('previewPhoto')}
            </Button>
          </Form.Item>
          <Form.Item name='savePhoto'>
            <Button onClick={saveJPG}>
              {t('savePhoto')}
            </Button>
          </Form.Item>
          <Form.Item name='saveJSON'>
            <Button onClick={saveJSON.bind(null)}>
              {t('saveJSON')}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div>
        <canvas
          height={canvasHeight}
          id={'photo'}
          ref={canvasRef}
          style={{ height: height, width: width }}
          width={canvasWidth} />
        { (previewRef) &&
          <Image height={height}
            preview={{ mask: false,
              onVisibleChange: () => { setVisiblePreview(false); },
              visible: visiblePreview }}
            src={previewRef}
            style={{ visibility: 'hidden' }}
            width={width}
          />
        }
      </div>
    </div>
  );
};

export default React.memo(EditPlanogramPhotoForm);
