// Copyright 2021 @po-polochkam authors & contributors

import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Layouts } from 'react-grid-layout';
import { Button, Form, InputNumber, Space, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { RACK_MAX_NUM_OF_SHELVES,
  RACK_MIN_NUM_OF_SHELVES,
  RACK_MODE_FILL_RACK,
  RACK_MAX_HEIGHT,
  RACK_MAX_WIDTH,
  RACK_MIN_HEIGHT,
  RACK_MIN_WIDTH,
  RACK_SHELF_MIN_HEIGHT,
  PLANOGRAM_TABS_SKUS,
  RACK_MAX_SHELF_THICKNESS,
  RACK_MIN_SHELF_THICKNESS } from 'common/constants';
import { Planogram } from 'database/entities/planogram';
import usePlanogramBuilder from 'hooks/usePlanogramBuilder';

interface RackFormInterface {
  blur: (index: number) => void;
  doSave: (mode: number) => Promise<void>;
  enabled: boolean;
  enabledHeight: boolean;
  form: FormInstance;
  maxShelfHeight: (layout: Layout[], index: number) => number;
  onSetRackHeight: (e: ChangeEvent<HTMLInputElement>, minHeight: number, refresh: (p: Planogram) => void) => void;
  onSetRackWidth: (e: ChangeEvent<HTMLInputElement>, refresh: (p: Planogram) => void) => void;
  onSetShelfThickness: (e: ChangeEvent<HTMLInputElement>, maxThickness: number, refresh: (p: Planogram) => void) => void;
  rack: Layouts;
  refresh: (p: Planogram) => void;
  scale: number;
  setActiveKey: (activeKey: string) => void;
}

const RackForm: React.FC<RackFormInterface> = (props) => {
  const { blur,
    doSave,
    enabled,
    enabledHeight,
    form,
    maxShelfHeight,
    onSetRackHeight,
    onSetRackWidth,
    onSetShelfThickness,
    rack,
    refresh,
    scale,
    setActiveKey } = props;

  const { t } = useTranslation();
  const { Text } = Typography;

  const { getCurrentPlanogram,
    minShelfHeight,
    restorePlanogram,
    setChanged } = usePlanogramBuilder();

  useEffect(() => {
    restorePlanogram();
  }, [restorePlanogram]);

  const fillRack = useCallback(async () => {
    await doSave(RACK_MODE_FILL_RACK);
    setChanged(false);
    setActiveKey(PLANOGRAM_TABS_SKUS);
  }, [doSave, setActiveKey, setChanged]);

  const tailLayout = {
    wrapperCol: {
      offset: 0,
      span: 24
    }
  };

  const widthHelp = enabled ? `${t('widthMustBe')} ${RACK_MIN_WIDTH} ${t('to')} ${RACK_MAX_WIDTH}` : '';
  const numOfShelvesHelp = enabled ? `${t('numOfShelvesMustBe')} ${RACK_MIN_NUM_OF_SHELVES} ${t('to')} ${RACK_MAX_NUM_OF_SHELVES}` : '';
  const p = getCurrentPlanogram();
  let minHeight: number = p ? p.numOfShelves * (p.shelfThickness + RACK_SHELF_MIN_HEIGHT) : 0;

  if (minHeight < RACK_MIN_HEIGHT) {
    minHeight = RACK_MIN_HEIGHT;
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const heightHelp = enabled ? `${t('heightMustBe')} ${minHeight} ${t('to')} ${RACK_MAX_HEIGHT}` : '';

  const maxThickness = p ? Math.min(Math.round(p.height / p.numOfShelves - RACK_SHELF_MIN_HEIGHT), RACK_MAX_SHELF_THICKNESS) : 0;

  const thicknessHelp = enabled ? `${t('shelfThicknessMustBe')} ${RACK_MIN_SHELF_THICKNESS} ${t('to')} ${maxThickness}` : '';

  return (
    <div className='rack-form'>
      <Space direction='vertical'
        style={{ marginBottom: 20, marginTop: 10 }}>
        <Text>{`${t('scale')}: в 1px ${scale.toFixed(1)} мм `}</Text>
        <Space style={{ marginTop: 10 }}>
          <Text strong={true}>{`${t('rackInfo')}`}</Text>
        </Space>
      </Space>
      <Form
        form={form}
        labelCol={{ offset: 0, span: 3 }}
        layout='horizontal'
        name='rack_form'
      >
        <Form.Item
          key='width'
          label={t('widthMM')}
          name='width'
          rules={[
            {
              max: RACK_MAX_WIDTH,
              message: widthHelp,
              min: RACK_MIN_WIDTH,
              required: enabled,
              type: 'integer'
            }
          ]}
          style={{ marginTop: -8 }}
          tooltip={widthHelp}
        >
          <InputNumber disabled={!enabled}
            onBlur={(e: ChangeEvent<HTMLInputElement>) => onSetRackWidth(e, refresh)}
            size={'small'}
          />
        </Form.Item>
        <Form.Item
          key='height'
          label={t('heightMM')}
          name='height'
          rules={[
            {
              max: RACK_MAX_HEIGHT,
              message: heightHelp,
              min: minHeight,
              required: enabled,
              type: 'integer'
            }
          ]}
          style={{ marginTop: -8 }}
          tooltip={heightHelp}
        >
          <InputNumber disabled={!enabled}
            onBlur={(e: ChangeEvent<HTMLInputElement>) => onSetRackHeight(e, minHeight, refresh)}
            size={'small'}
          />
        </Form.Item>
        <Form.Item
          key='numOfShelves'
          label={t('numOfShelves')}
          name='numOfShelves'
          rules={[
            {
              max: RACK_MAX_NUM_OF_SHELVES,
              message: numOfShelvesHelp,
              min: RACK_MIN_NUM_OF_SHELVES,
              type: 'integer'
            }
          ]}
          style={{ marginTop: -8 }}
          tooltip={numOfShelvesHelp}
        >
          <InputNumber disabled={true}
            size={'small'}
          />
        </Form.Item>
        <Form.Item
          key='shelfThickness'
          label={t('shelfThickness')}
          name='shelfThickness'
          rules={[
            {
              max: maxThickness,
              message: thicknessHelp,
              min: RACK_MIN_SHELF_THICKNESS,
              required: enabled,
              type: 'integer'
            }
          ]}
        >
          <InputNumber disabled={!enabled}
            onBlur={(e: ChangeEvent<HTMLInputElement>) => onSetShelfThickness(e, maxThickness, refresh)}
            size={'small'}
          />
        </Form.Item>
        <Space style={{ marginBottom: 20, marginTop: 0 }}>
          <Text strong={true}>{`${t('shelfHeights')}`}</Text>
        </Space>
        { rack.lg.map((item, index) => {
          if (item.i.length >= 5) {
            const maxHeight = maxShelfHeight(rack.lg, index);
            const minHeight = p ? minShelfHeight(p, index) : 0;
            const shelfHelp = enabledHeight ? `${t('heightMustBe')} ${minHeight} ${t('to')} ${maxHeight}` : '';

            return (
              <Form.Item
                key={index}
                label= {`${t('shelf')} ${index + 1}`}
                name={`shelf${index}`}
                rules={[
                  {
                    max: maxHeight,
                    message: shelfHelp,
                    min: minHeight,
                    required: enabledHeight,
                    type: 'integer'
                  }
                ]}
                style={{ marginTop: -8 }}
                tooltip={shelfHelp}
              >
                <InputNumber disabled={!enabledHeight}
                  onBlur={() => { blur(index); } }
                  size={'small'}
                />
              </Form.Item>
            );
          } else {
            return (<div></div>);
          }
        })}
        {(enabled) &&
          <Form.Item {...tailLayout }
            name='fillRack'>
            <Button onClick={fillRack} >
              {t('fillRack')}
            </Button>
          </Form.Item>
        }
      </Form>
    </div>
  );
};

export default React.memo(RackForm);
