// Copyright 2021 @po-polochkam authors & contributors

import React, { memo } from 'react';
import { Space, Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DB_PLANOGRAM_STATE_IN_WORK, DB_PLANOGRAM_STATE_NEW, DB_PLANOGRAM_STATE_READY } from 'database/constants';

export interface PlanogramStateFiltersProps {
  allowClear: boolean;
  form: FormInstance;
  removeState: () => void;
  saveState: (state: any) => void;
  state: number | undefined;
}

const PlanogramStateFilter: React.FC<PlanogramStateFiltersProps> = (props) => {
  const { t } = useTranslation();
  const { allowClear, form, removeState, saveState, state } = props;
  const { Option } = Select;

  return (
    <div>
      <Space style={{ marginBottom: 10, marginTop: 10 }}>
        <Form
          form={form}
          layout='inline'
          name='planogram_state_filter_form'
          size={'small'} >
          <Form.Item
            label={t('state')}
            name='state'
          >
            <Select
              allowClear={allowClear}
              clearIcon= {<CloseOutlined />}
              defaultValue={state}
              onClear={removeState}
              onSelect={saveState}
              placeholder={t('selectState')}
              showArrow={false}
              style={{ left: 40, width: 150 }}>
              <Option value={DB_PLANOGRAM_STATE_NEW}>{t('new')}</Option>
              <Option value={DB_PLANOGRAM_STATE_IN_WORK}>{t('inWork')}</Option>
              <Option value={DB_PLANOGRAM_STATE_READY}>{t('ready')}</Option>;
            </Select>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default memo(PlanogramStateFilter);
