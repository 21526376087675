// Copyright 2022 @po-polochkam authors & contributors

import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import { CODE_FILTER_END, CODE_FILTER_FULL, CODE_FILTER_START } from 'common/constants';
import { getCodeFilterMode } from 'common/localVars';

export interface CodeFiltersProps {
  apply: () => void;
  form: FormInstance;
  reset: () => void;
}

const CodeFilter: React.FC<CodeFiltersProps> = (props) => {
  const { t } = useTranslation();
  const { apply, form, reset } = props;
  const [modeNotSelected, setModeNotSelected] = useState(false);

  useEffect(() => {
    setModeNotSelected(!getCodeFilterMode());
  }, []);

  const changeMode = useCallback(() => {
    setModeNotSelected(!form.getFieldValue('codeMode'));
  }, [form]);

  const resetMode = useCallback(() => {
    setModeNotSelected(true);
    reset();
  }, [reset]);

  return (
    <Form
      form={form}
      labelCol={{
        span: 7
      }}
      layout='inline'

      name='code_filter_form'
      size={'small'} >
      <Form.Item
        label={t('code')}
        name='codeMode'
        style={{ marginTop: 0 }}
      >
        <Select
          allowClear={false}
          onChange={changeMode}
          placeholder={t('codeFilterMode')}
          showArrow={true}
          style={{ width: 200 }}
        >
          <Select.Option value={CODE_FILTER_FULL}>{t('codeFilterFull')}</Select.Option>
          <Select.Option value={CODE_FILTER_START}>{t('codeFilterStart')}</Select.Option>
          <Select.Option value={CODE_FILTER_END}>{t('codeFilterEnd')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name='codeValues'
        style={{ marginLeft: -10, width: 500 }}
      >
        <Input allowClear={true}
          placeholder={t('enterCodeValues') } />
      </Form.Item>
      <Button
        disabled={modeNotSelected}
        onClick={apply}
        style={{ marginLeft: -6, marginTop: 0 }}>
        {t('apply')}
      </Button>
      <Button
        onClick={resetMode}
        style={{ marginLeft: 4, marginTop: 0 }}>
        {t('reset')}
      </Button>
    </Form>
  );
};

export default memo(CodeFilter);
