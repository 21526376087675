// Copyright 2021 @po-polochkam authors & contributors

import { configureStore, Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import userReducer from './userReducer';
import skusReducer from './skusReducer';

const store = configureStore({
  reducer: {
    skus: skusReducer,
    user: userReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = (): Dispatch => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
